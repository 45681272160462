import React from 'react';
import { FormGroup, Input, Label } from "reactstrap";
import { renderTranslation } from "services";
import { useSelector } from "react-redux";

export default function DaySlots(props) {
    const store = useSelector((state) => state);
    const { slots, handleChange, error, rates, isRange, start, end, date } = props;
    const handleSlotsChange = (slot) => {
        const updatedSlots = [ ...slots ];
        const index = updatedSlots.indexOf(slot);
        if (index === -1) {
            updatedSlots.push(slot);
        } else {
            updatedSlots.splice(index, 1);
        }
        handleChange(updatedSlots);
    }

    const getRates = () => {
        if (rates && rates.dates) {
            const dates = rates.dates;

            if (isRange) {
                const formattedStartDate = `${start} 08:00:00`;
                const formattedEndDate = `${end} 08:00:00`;
                const startObj = dates[formattedStartDate] || {};
                const endObj = dates[formattedEndDate] || {};
                if (startObj.price && endObj.price) {
                    let rate = `${startObj.price} - €${endObj.price}`;
                    if (startObj.price === endObj.price) {
                        rate = startObj.price;
                    } else if (endObj.price < startObj.price) {
                        rate = `${endObj.price} - €${startObj.price}`;
                    }
                    return {
                        rate,
                        service: startObj.service_fee
                    }
                }
            } else {
                const formattedDate = `${date} 08:00:00`;

                const dateObj = dates[formattedDate] || {};
                if (dateObj.price) {
                    return {
                        rate: dateObj.price,
                        service: dateObj.service_fee
                    }
                }
            }
        }
        return {
            rate: '...',
            service: '...'
        }
    }

    const rate = getRates();
    return (
        <div>
            <FormGroup check>
                <Label check style={{ cursor: "initial" }}>
                    <div className="slot-wrapper"  onClick={() => handleSlotsChange('morning')} />
                    <Input
                        type="checkbox"
                        checked={slots.includes('morning')}
                    />
                    <span className="form-check-sign" />
                    <div className="d-flex">
                        <div className="flex-column pr-2 schedule-daytime-left slot-min-width">
                            <h4 className="text-black-50 schedule-daytime-heading">{renderTranslation(store,'appointments.morning')}</h4>
                            <p>08:00 - 12:00</p>
                        </div>
                        <div className="flex-column pl-2">
                            <h4 className="text-black-50 schedule-daytime-heading">
                                €{rate.rate}{' '}
                                {rates && rates.rateType ? rates.rateType : renderTranslation(store,'appointments.per_hour')}
                            </h4>
                            <p>+ €{rate.service} {renderTranslation(store,'appointments.service_fee')}</p>
                        </div>
                    </div>
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check style={{ cursor: "initial" }}>
                    <div className="slot-wrapper"  onClick={() => handleSlotsChange('afternoon')} />
                    <Input
                        type="checkbox"
                        checked={slots.includes('afternoon')}
                    />
                    <span className="form-check-sign" />
                    <div className="d-flex">
                        <div className="flex-column pr-2 schedule-daytime-left slot-min-width">
                            <h4 className="text-black-50 schedule-daytime-heading">{renderTranslation(store,'appointments.afternoon')}</h4>
                            <p>12:00 - 18:00</p>
                        </div>
                        <div className="flex-column pl-2">
                            <h4 className="text-black-50 schedule-daytime-heading">
                                €{rate.rate}{' '}
                                {rates && rates.rateType ? rates.rateType : renderTranslation(store,'appointments.per_hour')}
                            </h4>
                            <p>+ €{rate.service} {renderTranslation(store,'appointments.service_fee')}</p>
                        </div>
                    </div>
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check style={{ cursor: "initial" }}>
                    <div className="slot-wrapper"  onClick={() => handleSlotsChange('evening')} />
                    <Input
                        type="checkbox"
                        checked={slots.includes('evening')}
                    />
                    <span className="form-check-sign" />
                    <div className="d-flex">
                        <div className="flex-column pr-2 schedule-daytime-left slot-min-width">
                            <h4 className="text-black-50 schedule-daytime-heading">{renderTranslation(store,'appointments.evening')}</h4>
                            <p>18:00 - 21:00</p>
                        </div>
                        <div className="flex-column pl-2">
                            <h4 className="text-black-50 schedule-daytime-heading">
                                €{rate.rate}{' '}
                                {rates && rates.rateType ? rates.rateType : renderTranslation(store,'appointments.per_hour')}
                            </h4>
                            <p>+ €{rate.service} {renderTranslation(store,'appointments.service_fee')}</p>
                        </div>
                    </div>
                </Label>
            </FormGroup>
            {error ? (
                <span className="text-danger">{error}</span>
            ) : ''}
        </div>
    )
}
