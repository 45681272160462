import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

export default class SetAppSettings {

    http = "";
    constructor() {
        this.http = httpHandler(
            baseEndpoint.partnersServiceBaseEndPoint
        );
    }

    setAppSettings = () => {
        return this.http.get(apiUrls.auth.getAppSettings);
    }
}

export const setAppSettings = new SetAppSettings();