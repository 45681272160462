import React, { Component } from "react";
import { LoginAction, GetCountriesAction } from '../actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { successAlertBox } from "../../../services"
import classnames from "classnames";
import loginImg from "../../../assets/img/Zoofy-logo.png";
import { renderTranslation } from "services/renderTranslation.service";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

import i18n from "../../../i18n";



import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";

class LoginPage extends Component {


  loginForm = null;
  constructor(props) {
    super(props);
    this.state = {
      passFocus: false,
      emailFocus: false,
      countryFocus: false,
      cardHidden: true,
      loading: false,
      errorShow: false,
      countryDropdown: [],
      selectedCountryISO: ''
    };
    this.createInput();
  }

  createInput() {
    this.loginForm = new Group({
      email: new Control(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]),
      password: new Control(null, [Validators.required]),
      'operating-country': new Control(null, [Validators.required])
    });
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );

    this.getCountries();
  }

  getCountries = () => {
    this.props.getCountries().then(result => {
      this.setState({countryDropdown: result, selectedCountryISO: result[0].iso})
      this.loginForm.controls["operating-country"].setValue(result[0].iso)
      localStorage.setItem('operating-country', result[0].iso.toUpperCase())
    })
  }

  handleClick = (e) => {
    const { store } = this.props;
    if (this.loginForm.valid) {
      this.setState({ loading: true });
      this.setState({ errorShow: false });
      let payload = {
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value
      };

      this.props.loginAction(payload).then(result => {
        this.setState({ loading: false });
        if (result.success) {
          successAlertBox(renderTranslation(store, "message.login_success"))
          if(localStorage.getItem('role') === 'subuser') {
            this.props.history.push('/admin/appointments')
          }else{
            this.props.history.push('/admin/dashboard')
          }
        }
      })

    } else {
      this.setState({ errorShow: true });
    }
  };

  redirectForgotPassword = () => {
    this.props.history.push('/auth/forgot-password')
  };
  handleChange = (e) => {
    if(e.target.name === 'operating-country') {
      localStorage.setItem('operating-country', e.target.value.toUpperCase())
    }
    return (
      e.target.name === 'email' ? this.loginForm.controls["email"].setValue(e.target.value) :
        e.target.name === 'psw' ? this.loginForm.controls["password"].setValue(e.target.value) :
          e.target.name === 'operating-country' ? this.loginForm.controls["operating-country"].setValue(e.target.value) :
          () => {
          }
    )
  }

  render() {
    const { store } = this.props;
    return (
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white login-card">
                <CardHeader className="login-card-header">
                  <img
                    className="zoofy-logo-img"
                    alt="..."
                    src={loginImg}
                  />
                  <CardTitle tag="h5">{renderTranslation(store, 'auth.login_title')}</CardTitle>
                </CardHeader>
                <CardBody>
                <span className="error">
                        {(this.state.errorShow && this.loginForm.controls["email"].errors &&
                          this.loginForm.controls['email'].errors.required) ?
                          renderTranslation(store, "errors.required", { fieldName: "auth.email_text" })
                          : ""}

                          {this.state.errorShow && this.loginForm.controls["email"].errors &&
                          this.loginForm.controls["email"].errors.pattern ?
                          renderTranslation(store, "errors.format", { fieldName: "auth.email_text" })
                          : ""}
                      </span>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    
                    <Input
                      placeholder={renderTranslation(store, "auth.email_placeholder_text")}
                      type="email"
                      name="email" 
                      onFocus={(e) => this.setState({ emailFocus: true })}
                      onBlur={(e) => this.setState({ emailFocus: false })}
                      onChange={this.handleChange} required
                    />
                  </InputGroup>
              

                  <span className="error">
                      {(this.state.errorShow && this.loginForm.controls["password"].errors &&
                          this.loginForm.controls['password'].errors.required) ?
                          renderTranslation(store, "errors.required", { fieldName: "auth.password_text" })
                          : ""}
                      </span>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                       type="password" placeholder={renderTranslation(store, "auth.password_placeholder_text")}
                       name="psw"
                       onFocus={(e) => this.setState({ passFocus: true })}
                       onBlur={(e) => this.setState({ passFocus: false })}
                      onChange={this.handleChange} required
                      
                    />
                     
                  </InputGroup>

                  <span className="error">
                      {(this.state.errorShow && this.loginForm.controls["operating-country"].errors &&
                          this.loginForm.controls['operating-country'].errors.required) ?
                          renderTranslation(store, "errors.required", { fieldName: "auth.password_text" })
                          : ""}
                      </span>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.countryFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-world" />
                      </InputGroupText>
                    </InputGroupAddon>
                      <Input type="select" name="operating-country" onFocus={(e) => this.setState({ countryFocus: true })}
                        onBlur={(e) => this.setState({ countryFocus: false })}
                        onChange={this.handleChange} required>
                        {this.state.countryDropdown.map((country) => {
                          return <option>{country.iso}</option>
                        })}
                      </Input>
                  </InputGroup>
                 
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="info"
                    onClick={this.handleClick}
                    size="lg"
                  > 
                   {!this.state.loading ? renderTranslation(store, 'auth.login') : 'Loading...'}
                </Button>
                  <div className="pull-left">
                    <h6>
                    </h6>
                  </div>
                  <div className="pull-right">
                    <h6>
                      <a
                        className="link footer-link"
                        onClick={this.redirectForgotPassword}
                      >
                        {renderTranslation(store, "auth.forgot_password_text")}
                    </a>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
     
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    store: state
  }
}
const mapDispatchToProps = {
  loginAction: LoginAction,
  getCountries: GetCountriesAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
