export const CREATED = 'created';
export const PRO_ASSIGNED = 'pro_assigned';
export const CANCELLED = 'cancelled';
export const INVOICED = 'completed';
export const INVOICE_PENDING = "invoice_pending";
export const POSTPONED = "postponed";
export const ON_THE_WAY = "on-the-way";
export const INVOICE_PAID = "invoice_paid";
export const INVOICE_COMPLETED = "invoice_completed";
export const INVOICE_CREATED = "invoice_created";
export const INVOICE_EXPIRED = "invoice_expired";