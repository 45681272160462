import React, { useState } from 'react';
import {Button, Card, CardBody, CardHeader, Col, Collapse, Row} from "reactstrap";
import TextInput from "../../../shared/Input/TextInput";
import {useTranslation} from "react-i18next";
import { language } from 'i18next';
import {useDispatch, useSelector} from "react-redux";
import {SetCouponCode, ValidateCouponCodeAction, SetCouponError, SetCouponSuccess} from "../../actions";
import { renderTranslation } from "services";

export default function Coupon(props) {
    const { coupon, couponError, couponSuccess } = useSelector(state => state.createAppointment);
    const [open, setOpen] = useState(false);
    const [ localCoupon, setLocalCoupon ] = useState(coupon);
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector((state) => state);

    const update = (val) => {
        if (couponError) {
            dispatch(SetCouponError(null));
        }
        if (couponSuccess) {
            dispatch(SetCouponError(null));
            dispatch(SetCouponSuccess(null));
            dispatch(SetCouponCode(null));
        }
        setLocalCoupon(val);
    }

    const apply = () => {
        if(!localCoupon || !localCoupon.length) {
            dispatch(SetCouponError(renderTranslation(store,'appointments.coupon_required')));
        } else {
            dispatch(ValidateCouponCodeAction({code: localCoupon, locale: i18n.language}))
                .then((res => {
                    dispatch(SetCouponCode(localCoupon));
                }))
        }
    }

    return (
        <Card className={open ? 'card-plain border border-info' : 'card-plain'}>
            <CardHeader role="tab">
                <a
                    aria-expanded={open}
                    href="#pablo"
                    data-parent="#accordion"
                    data-toggle="collapse"
                    onClick={(e) => {
                        e.preventDefault();
                        setOpen(!open);
                    }}
                >
                    {renderTranslation(store,'appointments.coupon_header')}{" "}
                    <i className="tim-icons icon-minimal-down" />
                </a>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={open}>
                <CardBody>
                    <Row>
                        <Col md={8}>
                            <TextInput label={renderTranslation(store,'appointments.coupon_number')} value={coupon} handleChange={e => update(e.target.value)} />
                            {couponError ? (
                                <span className="text-danger">{couponError}</span>
                            ) : ''}
                            {couponSuccess ? (
                                <span className="text-success">{couponSuccess}</span>
                            ) : ''}
                        </Col>
                        <Col md={4}>
                            <Button className="mt-4" color={couponSuccess ? 'success': 'primary'} onClick={apply}>
                                {couponSuccess ? (
                                    <i className="fa fa-check mr-1" />
                                ) : ''}
                                {renderTranslation(store,'appointments.coupon_apply')}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    )
}
