import React from "react";
import moment from "moment";
import { range } from "moment-range";
import "./calendar.css";
import DaySlots from "./DaySlots";
import { useSelector } from "react-redux";
import { SetAssignment } from "../../modules/Appointments/actions";
import { Spinner } from "reactstrap";
export default class AppointmentCalender extends React.Component {
    weekdayshort = moment.weekdaysShort();

    state = {
        showCalendarTable: true,
        showMonthTable: false,
        dateObject: moment(),
        allmonths: moment.months(),
        showYearNav: false,
        selectedDays: {},
        ranges: {},
        editDate: null,
        editRange: null,
        error: null
    };

    lastDate = null;
    currentRange = null;

    componentDidMount() {
        this.setState({
            selectedDays: this.props.assignment.selectedDays || []
        });

         window.addEventListener("mouseup", () => {
            if (this.currentRange) {
                const ranges = { ...this.state.ranges };
                const selectedDays = { ...this.state.selectedDays };
                const currentRange = this.currentRange;
                let editRange = currentRange.id;
                let editDate = null;
                if (!currentRange.end) {
                    delete ranges[currentRange.id];
                    editDate = null;
                    editDate = currentRange.start;
                    selectedDays[currentRange.start] = {date: currentRange.start, range: null, slots: []};
                }
                this.currentRange = null;
                this.setState({
                    ranges,
                    selectedDays,
                    editRange,
                    editDate,
                    error: null,
                    editRange: currentRange.id
                });
            }
        });
    }

    daysInMonth = () => {
        return this.state.dateObject.daysInMonth();
    };
    year = () => {
        return this.state.dateObject.format("Y");
    };
    currentDay = () => {
        return this.state.dateObject.format("DD");
    };
    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        let firstDay = moment(dateObject).startOf("month").format("d");
        return firstDay;
    };
    month = () => {
        return this.state.dateObject.format("MMMM");
    };
    monthInDate = () => {
        return this.state.dateObject.format("MM");
    }
    yearInDate = () => {
        return this.state.dateObject.format("YYYY");
    }
    showMonth = (e, month) => {
        this.setState({
            showMonthTable: !this.state.showMonthTable,
            showCalendarTable: !this.state.showCalendarTable
        });
    };
    setMonth = (month) => {
        let monthNo = this.state.allmonths.indexOf(month);
        let dateObject = Object.assign({}, this.state.dateObject);
        dateObject = moment(dateObject).set("month", monthNo);
        this.setState({
            dateObject: dateObject,
            showMonthTable: !this.state.showMonthTable,
            showCalendarTable: !this.state.showCalendarTable
        });
        dateObject = moment(dateObject).set("date", 1);
        dateObject = moment(dateObject).set("month", month);
        dateObject = moment(dateObject).set("year", this.year());

        const newDate = moment(dateObject).format("YYYY") === moment().format("YYYY") && moment(dateObject).format("MM") === moment().format("MM") ?
          moment() : dateObject;

         this.props.fetchNewRates(newDate.format("YYYY-MM-DD"));
    };

    compareDates = (data) => {
        return moment(moment(this.state.dateObject).set("month", data).format("YYYY-MM-DD")).isAfter(moment().format("YYYY-MM-DD"), 'month') ||
          moment(this.state.dateObject).set("month", data).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
    }

    MonthList = (props) => {
         let months = [];
        const currentMonthIndex = this.state.dateObject.month();
        props.data.forEach((data, monthIndex) => {

            return months.push(
                <td
                    key={data}
                    className={`calendar-month ${!this.compareDates(data) && 'disabled'}`}
                    onClick={() => this.compareDates(data) && this.setMonth(data) }
                >
                    <span>{data}</span>
                </td>
            );
        });
        let rows = [];
        let cells = [];

        months.forEach((row, i) => {
            if (i % 3 !== 0 || i == 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
        });
        rows.push(cells);
        let monthlist = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });

        return (
            <table className="calendar-month">
                <thead>
                <tr>
                    <th colSpan="4">Select a Month</th>
                </tr>
                </thead>
                <tbody>{monthlist}</tbody>
            </table>
        );
    };
    showYearEditor = () => {
        this.setState({
            showYearNav: true,
            showCalendarTable: !this.state.showCalendarTable
        });
    };

    onPrev = () => {
        const curr = this.state.showMonthTable ? "year" : "month";

        !this.state.showMonthTable && this.setState({
            dateObject: this.state.dateObject.subtract(1, curr)
        }, () => {
            const newDate = moment(this.state.dateObject).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ?
              moment().format("YYYY-MM-DD") :
              `${this.yearInDate()}-${this.monthInDate()}-01`;
            this.props.fetchNewRates(newDate);
        });
    };
    onNext = () => {
        const curr = this.state.showMonthTable ? "year" : "month";

        this.setState({
            dateObject: this.state.dateObject.add(1, curr)
        }, () => {
            this.props.fetchNewRates(`${this.yearInDate()}-${this.monthInDate()}-01`);
        });
    };
    setYear = (year) => {
        let dateObject = Object.assign({}, this.state.dateObject);
        dateObject = moment(dateObject).set("year", year);
        this.setState({
            dateObject: dateObject,
            showMonthTable: !this.state.showMonthTable,
            showYearNav: !this.state.showYearNav,
            showMonthTable: !this.state.showMonthTable
        });
    };
    onYearChange = (e) => {
        this.setYear(e.target.value);
    };
    getDates(startDate, stopDate) {
        let dateArray = [];
        let currentDate = moment(startDate);
        const endDate = moment(stopDate);
        while (currentDate <= endDate) {
            dateArray.push(moment(currentDate).format("YYYY"));
            currentDate = moment(currentDate).add(1, "year");
        }
        return dateArray;
    }
    YearTable = (props) => {
        let months = [];
        let nextten = moment().set("year", props).add("year", 12).format("Y");

        let tenyear = this.getDates(props, nextten);

        tenyear.forEach((data) => {
            months.push(
                <td
                    key={data}
                    className="calendar-month"
                    onClick={(e) => {
                        this.setYear(data);
                    }}
                >
                    <span>{data}</span>
                </td>
            );
        });
        let rows = [];
        let cells = [];

        months.forEach((row, i) => {
            if (i % 3 !== 0 || i == 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
        });
        rows.push(cells);
        let yearlist = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });

        return (
            <table className="calendar-month">
                <thead>
                <tr>
                    <th colSpan="4">Select a Yeah</th>
                </tr>
                </thead>
                <tbody>{yearlist}</tbody>
            </table>
        );
    };
    handleDayRemove = (e, d) => {

        let formattedDate = moment(d, "YYYY-MM-D").format("YYYY-MM-DD");
        // if (this.validateExistingDate()) {
            const selectedDays = { ...this.state.selectedDays };
            const ranges = { ...this.state.ranges };
            const day = { ...selectedDays[formattedDate] };
            if (day.range) {
                for (let date in selectedDays) {
                    if (selectedDays[date].range === day.range) {
                        selectedDays[date].range = null;
                    }
                }
                delete ranges[day.range];
            }
            delete selectedDays[formattedDate];
            this.setState({
                selectedDays,
                ranges,
                editDate: null
            });
        // }
    };

    validateExistingDate = () => {
        this.setState({
            error: null
        });
        if (this.state.editDate) {
            const dateObj = this.state.selectedDays[moment(this.state.editDate, "YYYY-MM-D").format("YYYY-MM-DD")];
            if (!dateObj.slots.length) {
                this.setState({
                    error: 'Select atleast one day time slot to continue'
                });
                return false;
            }
        }
        if (this.state.editRange) {
            const rangeObj = this.state.ranges[moment(this.state.editRange, "YYYY-MM-D").format("YYYY-MM-DD")];
            if (rangeObj) {
                const dateObj = this.state.selectedDays[rangeObj.start];
                if (!dateObj.slots.length) {
                    this.setState({
                        error: 'Select atleast one day time slot to continue'
                    });
                    return false;
                }
            }
        }
        return true;
    }

    handleDaySelect = (e, d) => {
        // if (this.validateExistingDate()) {
            const selectedDays = { ...this.state.selectedDays };
            let formattedDate = moment(d, "YYYY-MM-D").format("YYYY-MM-DD");
            // if (!selectedDays[formattedDate]) {
            //     selectedDays[formattedDate] = {date: formattedDate, range: null, slots: []};
            //     this.setState({
            //         selectedDays,
            //         editDate: d,
            //         editRange: null
            //     });
            // } else {
                this.setState({
                    editDate: formattedDate,
                    editRange: null
                });
            // }
        // }
    };

    handleSlideOver = (e, date, day) => {
        if((!this.lastDate || this.lastDate !== date) && e.buttons == 1) {
            this.lastDate = date;
            date = moment(date, "YYYY-MM-D").format("YYYY-MM-DD");
            const ranges = { ...this.state.ranges };
            let selectedDays = { ...this.state.selectedDays };
            if (!this.currentRange) {
                const rangeId = Date.now();
                let currentRange = {id: rangeId, start: date, end: null};
                this.currentRange = currentRange;
                ranges[rangeId] = currentRange;
                selectedDays[date] = {date: date, range: rangeId, slots: []}
            } else {
                let currentRange = this.currentRange;
                const rangeId = currentRange.id;
                day = parseInt(day, 10);
                const dateParts = currentRange.start.split('-');
                const startDay = parseInt(dateParts[2], 10);
                if (day < startDay) {
                    if (!currentRange.end) {
                        currentRange.end = currentRange.start;
                    }
                    currentRange.start = date;
                    ranges[rangeId] = currentRange;
                    selectedDays[date] = {date: date, range: rangeId, slots: []}
                    selectedDays = this.selectBetweenDates(day, startDay, `${dateParts[0]}-${dateParts[1]}-`, rangeId, selectedDays);
                } else {
                    currentRange.end = date;
                    ranges[rangeId] = currentRange;
                    selectedDays = this.selectBetweenDates(startDay, day, `${dateParts[0]}-${dateParts[1]}-`, rangeId, selectedDays);
                }
                this.currentRange = currentRange;
            }
            this.setState({
                ranges,
                selectedDays,
                editDate: null
            });
        }
    };

    selectBetweenDates = (start, end, prefix, rangeId, selectedDays) => {
        for (let i = start; i <= end; i += 1) {
            const date = prefix + i;
            date = moment(date, "YYYY-MM-D").format("YYYY-MM-DD");
            selectedDays[date] = {date: date, range: rangeId, slots: []};
        }
        return selectedDays;
    }

    clear = () => {
        const { dispatch } = this.props;
        const updatedAssignment = {
            ...this.props.assignment,
            selectedDays: {},
        }
        dispatch(SetAssignment(updatedAssignment));

        this.setState({
            selectedDays: {},
            ranges: {},
            editDate: null,
            editRange: null
        });
    }

    getSelectedClass = (date) => {
        if (date === this.state.editDate) {
            return 'selected active';
        }

        date = moment(date, "YYYY-MM-D").format("YYYY-MM-DD");
        const dateObj = this.state.selectedDays[date];

        if (dateObj) {
            const rangeId = dateObj.range;
            if (rangeId && this.state.ranges[rangeId]) {
                const rangeObj = this.state.ranges[rangeId];
                if (rangeObj.start === date) {
                    return 'selected range-start';
                }
                if (rangeObj.end === date) {
                    return 'selected range-end';
                }
                return 'selected range-item';
            } else if (dateObj.slots.length) {
                return 'selected';
            }
        }
        return '';
    }

    handleDaySlotsChange = (slots) => {
        const selectedDays = this.state.selectedDays;
        const ranges = this.state.ranges;
        const day = { ...selectedDays[moment(this.state.editDate, "YYYY-MM-D").format("YYYY-MM-DD")] };

        if (selectedDays[moment(this.state.editDate, "YYYY-MM-D").format("YYYY-MM-DD")] === undefined) {
            selectedDays[moment(this.state.editDate, "YYYY-MM-D").format("YYYY-MM-DD")] = {date: this.state.editDate, range: null, slots: []};
        }

        selectedDays[moment(this.state.editDate, "YYYY-MM-D").format("YYYY-MM-DD")].slots = slots;
        if (day.range) {
            for (let date in selectedDays) {
                if (selectedDays[date].range === day.range) {
                    selectedDays[date].range = null;
                }
            }
            delete ranges[day.range];
        }
        this.props.handleChange(selectedDays);
        this.setState({
            selectedDays,
            ranges,
            error: null
        })
    }

    handleRangeSlotsChange = (slots) => {
        const rangeId = this.state.editRange;
        const selectedDays = this.state.selectedDays;
        for (let date in selectedDays) {
            if (selectedDays[date].range === rangeId) {
                selectedDays[date].slots = slots;
            }
        }
        this.props.handleChange(selectedDays);
        this.setState({
            selectedDays,
            error: null
        });
    }

    renderSlots = () => {
        if (this.state.editDate) {
            const day =
              this.state.selectedDays[moment(this.state.editDate, "YYYY-MM-D").format("YYYY-MM-DD")] ||
              { date: this.state.editDate, range: null, slots: [] };
            const slots = day.slots;
            return (
                <DaySlots
                    slots={slots}
                    handleChange={this.handleDaySlotsChange}
                    rates={this.props.rates}
                    isRange={false}
                    date={moment(this.state.editDate, "YYYY-MM-D").format("YYYY-MM-DD")}
                />
            );
        }
        if (this.state.editRange) {
            const rangeObj = this.state.ranges[this.state.editRange];
            if (rangeObj) {
                const startDate = this.state.selectedDays[moment(rangeObj.start, "YYYY-MM-D").format("YYYY-MM-DD")];
                return (
                    <DaySlots
                        slots={startDate.slots}
                        handleChange={this.handleRangeSlotsChange}
                        rates={this.props.rates}
                        isRange
                        start={moment(rangeObj.start, "YYYY-MM-D").format("YYYY-MM-DD")}
                        end={moment(rangeObj.end, "YYYY-MM-D").format("YYYY-MM-DD")}
                    />
                );
            }
        }
        return (
            <div style={{height: 236, width: '100%', textAlign: 'center', paddingTop: 30}}>
                Select a date or range to update the slots
            </div>
        )
    }

    renderSlotsHeader = () => {
        if (this.state.editDate) {
            return <p>Select slots for <strong>{moment(this.state.editDate).format('ddd MMMM Do')}</strong></p>
        }
        if (this.state.editRange) {
            const rangeObj = this.state.ranges[this.state.editRange];
            if (rangeObj) {
                return <p>Select slots for <strong>{moment(rangeObj.start).format('ddd MMMM Do')}</strong> - <strong>{moment(rangeObj.end).format('ddd MMMM Do')}</strong></p>
            }
        }
        return '';
    }

    render() {
        let weekdayshortname = this.weekdayshort.map((day) => {
            return <th key={day}>{day}</th>;
        });
        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<td className="calendar-day empty">{""}</td>);
        }
        let daysInMonth = [];
        const month = this.monthInDate();
        const year = this.yearInDate();
        const currentMonth = parseInt(moment().format('M'), 10);
        const currentYear = parseInt(moment().format('Y'), 10);

        for (let d = 1; d <= this.daysInMonth(); d++) {
            let currentDay = d == this.currentDay() ? "today" : "";
            const date = `${year}-${month}-${d}`;
            if (parseInt(year, 10) < currentYear || (parseInt(year, 10) === currentYear && parseInt(month, 10) < currentMonth) || (parseInt(year, 10) === currentYear && parseInt(month, 10) === currentMonth && d <= parseInt(this.currentDay(), 10))) {
                daysInMonth.push(
                    <td
                        key={moment().date(d).format("DD")}
                        className={`calendar-day disabled`}
                    >
                        <div>{d}</div>
                    </td>
                );
            } else {
                let selectedClass = this.getSelectedClass(date);
                daysInMonth.push(
                    <td
                        onDoubleClick={(e) => this.handleDayRemove(e, date)}
                        onClick={(e) => this.handleDaySelect(e, date)}
                        key={moment().date(d).format("DD")}
                        className={`calendar-day ${currentDay} ${selectedClass}`}
                        // onMouseOver={(e) => this.handleSlideOver(e, date, d)}
                    >
                        <div>{d}</div>
                    </td>
                );
            }
        }
        const totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                // let insertRow = cells.slice();
                rows.push(cells);
            }
        });

        const daysinmonth = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });

        return (
            <div>
                <div className="tail-datetime-calendar">
                    <div className="calendar-navi">
                        <span
                            onClick={() => {
                                this.month() !== moment().format('MMMM') && this.onPrev();
                            }}
                            className={`calendar-button button-prev ${this.month() === moment().format('MMMM') && 'disabled'}`}
                        />
                        {!this.state.showMonthTable && !this.state.showYearEditor && (
                            <span
                                onClick={(e) => {
                                    this.showMonth();
                                }}
                                className="calendar-label"
                            >
                                {this.month()}
                            </span>
                        )}
                        <span
                            className="calendar-label"
                            onClick={(e) => {
                                this.showYearEditor();
                            }}
                        >
                            {this.year()}
                        </span>
                        <span
                            onClick={(e) => {
                                this.onNext();
                            }}
                            className="calendar-button button-next"
                        />
                    </div>
                    <div className="calendar-date">
                        {this.state.showYearNav && <this.YearTable props={this.year()}/>}
                        {this.state.showMonthTable && (
                            <this.MonthList data={moment.months()}/>
                        )}
                    </div>

                    {this.state.showCalendarTable && (
                        <div className="calendar-date">
                            <table className="calendar-day">
                                <thead>
                                <tr>{weekdayshortname}</tr>
                                </thead>
                                {this.props.loading ?
                                    <tbody className="spinner-loading" >
                                        <div className="spinner-wrapper" ><Spinner color="primary" /></div>
                                    </tbody> :
                                    <tbody>{daysinmonth}</tbody>}
                            </table>
                        </div>
                    )}
                    <div className="text-right">
                        <a href="javascript:void(0)" className="mr-2" onClick={this.clear}>clear</a>
                    </div>
                </div>
                <div className="mt-3">
                    {this.renderSlotsHeader()}
                    {this.renderSlots()}
                    {this.state.error ? (
                        <span className="text-danger">{this.state.error}</span>
                    ) : <div style={{ height: 25 }} />}
                </div>
            </div>
        );
    }
}
