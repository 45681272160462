import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import "../../styles/appointments.scss";

import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Select from "react-select";
import { FetchTasksAction} from "../../actions"
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { renderTranslation } from "services";
class AppointmentReBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
    moveWidth:12,
      activeTab: "info",
      taskList: [],
      comment: "",
      editAppointmentRateShow: false,
      editAppointmentServiceRateShow: false,
      selectedEditRate: "",
      selectedEditServiceRate: "",
      editAppointmentTaskShow: false,
      selectedTask: "" ,
    };
  }

  changeTab = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  handleChange = (e) => {
      this.setState({comment:  e.target.value});
  };

  componentDidMount() {
    this.fetchAllTask();
  }

  fetchAllTask = () => {
    this.props.fetchTasksAction().then((result) => {
      if (this.props.bare_minimum_tasks.length > 0) {
        const arr = [];
        this.props.bare_minimum_tasks.map((task, index) => {
          const obj = {};
          obj.value = task.id.toString();
          obj.label = task.singular[0].toUpperCase() + task.singular.slice(1);

          arr.push(obj);
          return null;
        });
        this.setState({
          taskList: arr,
        });
      }
    });
  };


  getDropdownList = (data, label) => {
    if (data) {
      let result = [
        {
          value: "",
          label: label,
          isDisabled: true,
        },
      ];

      data.map((item) => {
        result.push(item);
        return item;
      });
      return result;
    }
  };

  showSingleCustomer = (id) => {
    this.props.history.push(`/admin/consumer-profile/${id}`);
  }
  render() {
    const { store } = this.props;
    const disablePastDt = (current) => {
      return current.isAfter(moment().subtract(1, "day"));
    };
    return (
      <div className="appointment-re-booking">
                    <h4 className="text-center">Re-Booking</h4>
        <Row>

          <Col md={6}  className="mt-5">
          <FormGroup>
                        <Label>
                        <i className="tim-icons icon-calendar-60"></i>  Time Adjust
                        </Label>
                        <Datetime
                          name="datepicker"
                          timeFormat={true}
                          placeholder="Select Date of job"
                          defaultValue={new Date().setDate(
                            new Date().getDate() + 1
                          )}
                          locale="nl"
                          isValidDate={disablePastDt}
                          dateFormat="DD/MM/YYYY"
                        />
                      </FormGroup>
       </Col>
       <Col md={6} className="mt-4">
       
       <FormGroup>
       <Label>
                        <i className="tim-icons icon-calendar-60"></i>   Adjust Job
                        </Label>
                        <div className="current-job-text">Current Job: {this.props.appointment.task.singular}</div>
                             <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="task"
                      defaultValue={
                        this.props.appointment.task.id
                      }
                      options={this.getDropdownList(
                        this.state.taskList,
                        renderTranslation(store, "forms.task_placeholder_text")
                      )}
                      placeholder={renderTranslation(store, "forms.task_placeholder_text")}
                    />

                  </FormGroup>
            
       </Col>
       </Row>
       <Row>
         <Col md={6} className="mt-3">
                   <FormGroup>
                     <Label>Fixed Price Rate</Label>
                     <Input 
                     type="text"
                     placeholder="Add price Rate"
                     />
                     </FormGroup>       
         </Col>
         <Col md={6} className="mt-3">
         <FormGroup>
                     <Label>Coupon Code</Label>
                     <Input 
                     type="text"
                     placeholder="Add coupon code"
                     />
                     </FormGroup>  
         </Col>
       </Row>
       <Button
                    block
                    className="mb-3"
                    color="info"
                    onClick={this.handleClick}
                    size="md"
                  > 
                REBOOKING
                </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bare_minimum_tasks: state.tasks_bare_minimum.tasks_bare_minimum.tasks,
    store: state
  };
};

const mapDispatchToProps = {
    fetchTasksAction: FetchTasksAction,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentReBooking)
);
