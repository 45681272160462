
import { categoriesService } from "../services";

/**
 * Fetch all categories
 * @param object payload
 * @return Promise 
 */
export const FetchCategoriesAction = (payloads) => (_dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
      categoriesService
        .fetchAllCategories(payloads.params, payloads.page)
        .then(async (resp) => {
          resolve({ success: true, data: resp });  
        })
        .catch((error) => {
          resolve({ success: false });
        });
  
    });
  };
