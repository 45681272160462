import React, { Component } from "react";
import "./styles/pagination.scss";

class Pagination extends Component {

  handlePaginate = (direction) => {
      let page = 0;
    if(direction === 'next') {
      page = this.props.meta.current_page + 1;
    
    } else if( direction === 'prev' ) {
      page = this.props.meta.current_page - 1;
    }

    this.props.onPaginate(page)
  };

  render() {
    return (
      this.props.meta &&
      <div>
        <ul className="pagination">

          <li aria-label="First"
            className={"page-item " +  (this.props.meta.current_page === 1 ? "disabled" : "page-item pagination-arrow  active") }
            name="prev"
            onClick={(e) => this.props.meta.current_page !== this.props.meta.from &&  this.handlePaginate('prev')}>
         <span aria-hidden="true" className="pagination-arrow"><i aria-hidden="true" className="tim-icons icon-double-left"></i></span>
          </li>
          <li></li>
          <li className="">
            <span role="button" className="center-text">
              {`Page ${this.props.meta.current_page ? this.props.meta.current_page : 0} of  ${this.props.meta.last_page? this.props.meta.last_page : 0}`}
            </span>
          </li>
          <li aria-label="Last" 
                      className={"page-item " + (this.props.meta.current_page === this.props.meta.last_page ? "disabled" : "page-item pagination-arrow active") }
           onClick={(e) => this.props.meta.current_page !== this.props.meta.last_page  && this.handlePaginate('next')}
            >
            <span aria-hidden="true" className="pagination-arrow"><i aria-hidden="true" className="tim-icons icon-double-right"></i></span>
          </li>
        </ul>
      </div>
    );
  }
}
export default Pagination;
