/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import {notificationMessage} from "../services"

// core components
import AdminNavbar from "../modules/shared/Navbars/AdminNavbar.jsx";
import Footer from "../modules/shared/Footer/Footer.jsx"
import Sidebar from "../modules/shared/Sidebar/Sidebar.jsx";
import {getCurrentUser, baseEndpoint} from "../services"
import routes from "routes/admin-route.js";
import { renderTranslation } from "services";


import { SetAppSettings } from '../modules/authentication/actions/SetAppSettings.js'

let ps;

const Admin = (props) => {

  const [activeColor, setActiveColor] = React.useState("blue");
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  React.useEffect(()=> {
    props.setAppSettings().then(result => {})
  }, []);

  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };

  const handleAlertNotification = (message = "", type="success") => {
    let notification = notificationMessage(message, type);
    notificationAlertRef.current.notificationAlert(notification);

  };

  const checkAuthentication = () => {
    let hasPermission = false;
    if (localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY) &&
      JSON.parse(localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY)).token) {
      return true;

    }
    return hasPermission
  };



  const getRoutes = routes => {
    const isAuth = checkAuthentication();

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return isAuth ? (

          <Route
            path={prop.layout + prop.path}
            key={key}
            render={routeProps => (
              <prop.component
                {...routeProps}
                showNotification= {handleAlertNotification}
              />
            )}
          />
        ) : ((<Redirect to="/auth/login" />));
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = routes => {
  
    const {store} = props;
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return renderTranslation( store, routes[i].translateKey);
         } 
          if(routes[i].path.indexOf('/:')) {
         const urlLocation =  window.location.href.split("/")
           if( String(urlLocation[5])  === String(routes[i].path.split("/")[1]) ||
            String(urlLocation[4])  === String(routes[i].path.split("/")[1])) {
            return renderTranslation( store, routes[i].translateKey);
           }
         }
      }
    }
    return activeRoute;
  };
  
  const handleMiniClick = () => {
   document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };

  const sidebarRoutes = routes => {
    return routes.filter((prop) => {return  prop !== null && prop.inNav === true});
 };
  
 const isAuth = checkAuthentication();
  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      {isAuth && (<Sidebar
        {...props}
        routes={sidebarRoutes(routes)}
        activeColor={activeColor}
        logo={{
          outterLink: "https://zoofy.nl/",
          text: getCurrentUser()?.name,
          imgSrc:  getCurrentUser()?.data.logo ? baseEndpoint.mediaServiceBaseEndPoint + "/" + getCurrentUser()?.data.logo : "",
        }}
        closeSidebar={closeSidebar}
      />)}
      <div className="main-panel" ref={mainPanelRef} data={activeColor}>
        

        {isAuth && (
          <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
          />
        )}
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    systemData: state.setAppSettings.settings,
    store: state
  }
}

const mapDispatchToProps = {
  setAppSettings: SetAppSettings
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Admin));

