import { LOGGED_IN_SUCCESSFULLY,  SET_USER_ROLE } from '../constants';
import { loginService } from '../services'

/**
 * 
 * @param object payload 
 */
export const LoginAction = (payload) => (dispatch, _getState) => {
  localStorage.removeItem(process.env.REACT_APP_CURRENT_USER_KEY)
  return new Promise((resolve, _reject) => {
    let result = { ...payload }
    loginService.login(result).then((response) => {
      /** Set Token Detail in Local storage  and normalize local data*/
      loginService.setCurrentUser(response);

      loginService.userDetail()
        .then((userDetail) => {

          console.log("user detail", userDetail)

          if(userDetail.role_id === 5) {
            //user is partner
            loginService.userProfileDetail(userDetail.uuid)
            .then((userProfileDetail) => {
              
              let currentUserDetail = JSON.parse(localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY));
              currentUserDetail.userDetail = {...userDetail, ...userProfileDetail };

              /** Set user detail along with token detail in Local storage */
              localStorage.setItem(process.env.REACT_APP_CURRENT_USER_KEY, JSON.stringify(currentUserDetail));
              localStorage.setItem('role', 'partner');
              dispatch({ type: SET_USER_ROLE, payload: 'partner' });
              dispatch({ type: LOGGED_IN_SUCCESSFULLY, payload: currentUserDetail });
              resolve({ success: true })
            
            })
            .catch((error) => {
              localStorage.removeItem('currentUserDetail')
              resolve({ success: false })
            })
          }

          if(userDetail.role_id === 6) {
            //user is subuser
            localStorage.setItem('role', 'subuser');
            dispatch({ type: SET_USER_ROLE, payload: 'subuser' });
            dispatch({ type: LOGGED_IN_SUCCESSFULLY, payload: userDetail });
            resolve({ success: true })
          }
          
        });

    })
      .catch((error) => {
        localStorage.removeItem('currentUserDetail')
        resolve({ success: false })
      })


  });
}