import React from "react";
import { useStore } from "react-redux";
import i18n from "../../../../i18n";
import "./Styles/status.scss";
import { renderTranslation } from "services";
import { CREATED, PRO_ASSIGNED, CANCELLED, INVOICED } from "../../../Appointments/constants/StatusConstants"

const Status = (props) => {
  const ReduxStore = useStore()

  const getStatus = (status) => {
    let store = ReduxStore.getState() 
    if (status === CREATED) {
      return (
        <div>
        <div className={`status-text ${status}`}>
          <i className="tim-icons icon-paper pr-1"></i> 
          {renderTranslation(store, 'appointments.posted')}
          </div>
      </div>
      )
    }
    if (status === PRO_ASSIGNED) {
      return (
        <div>
          <div className={`status-text ${status}`}>
            <i className="fa fa-calendar-check pr-1"></i> 
            {renderTranslation(store, 'appointments.accepted')}
            </div>
        </div>
      )
    }

   else if (status === CANCELLED) {
      return (
        <div>
          <div className={`status-text ${status}`}>
            <i className="tim-icons icon-alert-circle-exc pr-1"></i> 
            {renderTranslation(store, 'appointments.cancelled')}
            </div>
        </div>
      )
    }

    else if (status === INVOICED) {
      return (
        <div>
          <div className={`status-text ${status}`}>
            <i className="tim-icons icon-satisfied pr-1">
              </i> 
              {renderTranslation(store, 'appointments.completed')}
              </div>
        </div>

      )
    }
  }

  return (
    <div>

      {getStatus(props.status)}

    </div>
  );
}

export default Status;
