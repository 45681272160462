import React, { Component } from "react";
import { ResetNewPasswordAction } from "../../actions";
import { connect } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import classnames from "classnames";

import { ResetNewPasswordValidate } from "../../validations"
import { withTranslation } from 'react-i18next';
import i18n from "../../../../i18n";


class ResetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      loading: false,
      email: "",
      token: "",
      password: "",
      password_confirmation: "",
      errors: { password: null, password_confirmation: null }
    };
  }

  componentDidMount() {
    if (this.state.email === '' && this.props.email && this.props.email !== '') {
      this.setState({ email: this.props.email });
    }
    if (this.state.token === '' && this.props.token && this.props.token !== '') {
      this.setState({ token: this.props.token });
    }
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  handleChange = (e) => {
    return (
      e.target.name === 'password' ? this.setState({ password: e.target.value }) :
        e.target.name === 'password_confirmation' ? this.setState({ password_confirmation: e.target.value }) :
          () => { }
    )
  };



  handleClick = (e) => {

    e.preventDefault();
    let { email, token, password, password_confirmation } = this.state;
    if (ResetNewPasswordValidate(this.state).isValid) {
      this.setState({ loading: true });
      let payload = {
        email: email,
        token: token,
        password: password,
        passFocus: false,
        password_confirmation: password_confirmation
      };
      this.props.resetNewPasswordAction(payload).then(result => {
        this.setState({ loading: false });
        if (result.success) {
          this.props.submit();
        }
      })
    } else {
      this.setState({
        errors: {
          'password': ResetNewPasswordValidate(this.state).message.password,
          'password_confirmation': ResetNewPasswordValidate(this.state).message.password_confirmation
        },
      });
    }
  };

  render() {
    return (
      <div className="request-password-reset">
        <Container>
          <Col className="ml-auto mr-auto" lg="6" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>

                  <CardTitle tag="h1">{"Final step, create your new password"}</CardTitle>
                </CardHeader>
                <CardBody>
                  <span className="error">{this.state.errors.password}</span>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      value={this.state.password}
                      name="password"
                      placeholder="Enter your password"
                      className={this.state.errors.password ? 'error' : ''}
                      onChange={this.handleChange}
                      required
                      onFocus={(e) => this.setState({ passFocus: true })}
                      onBlur={(e) => this.setState({ passFocus: false })}
                    />

                  </InputGroup>



                  <span className="error">{this.state.errors.password_confirmation}</span>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      value={this.state.password_confirmation}
                      name="password_confirmation"
                      placeholder="Enter your same password again"
                      className={this.state.errors.password_confirmation ? 'error' : ''}
                      onChange={this.handleChange}
                      onFocus={(e) => this.setState({ passFocus: true })}
                      onBlur={(e) => this.setState({ passFocus: false })}
                      required
                    />

                  </InputGroup>

                </CardBody>
                <CardFooter>
            <Button
                    block
                    className="mb-3"
                    color="info"
                    onClick={this.handleClick}
                    size="lg"
                  > 
               {!this.state.loading ? "Create New Password" : "Loading..."}
                </Button>

                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    email: state.forgotPassword.email,
    token: state.forgotPassword.token
  };
};
const mapDispatchToProps = {
  resetNewPasswordAction: ResetNewPasswordAction,
};
export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetNewPassword));
