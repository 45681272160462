import { mediaService } from '../../../services'

/**
 * 
 * @param object payload 
 */
export const FetchAppointmentImagesAction = (payload) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    mediaService.filter(payload).then((response) => {
      resolve({ success: true, data: response.data })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}