import React, { useState } from 'react';
import {Button, Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row} from "reactstrap";
import TextInput from "../../../shared/Input/TextInput";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { renderTranslation } from "services";

export default function WarrantyPackage(props) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const {insuranceFee} = props;
    const store = useSelector((state) => state);

    return (
        <div>
            <FormGroup row>
                <Col sm={10}>
                    <FormGroup check className="ml-3">
                        <Label check>
                            <Input type="checkbox" onChange={props.updateWarrantyFlag} />
                            <span className="form-check-sign" />
                            {renderTranslation(store,'appointments.want_warranty')}
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            {props.warrantyFlag ? (
                <Card className={open ? 'card-plain border border-info' : 'card-plain'}>
                    <CardHeader role="tab">
                        <a
                            aria-expanded={false}
                            href="#pablo"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={(e) => {
                                e.preventDefaulrenderTranslation(store,);
                                setOpen(!open);
                            }}
                        >
                            {renderTranslation(store,'appointments.more_about_warranty')}{" "}
                            <i className="tim-icons icon-minimal-down" />
                        </a>
                    </CardHeader>
                    <Collapse role="tabpanel" isOpen={open}>
                        <CardBody className="p-4">
                            <h4>{renderTranslation(store,'appointments.warranty_header')}</h4>
                            <h4>EUR {insuranceFee}</h4>
                            <p><i className="tim-icons icon-check-2 text-success mr-1" />
                                {renderTranslation(store,'appointments.only_pay')}
                            </p>
                            <p><i className="tim-icons icon-check-2 text-success mr-1" />
                                {renderTranslation(store,'appointments.cover_damage')}
                            </p>
                            <p><i className="tim-icons icon-check-2 text-success mr-1" />
                                {renderTranslation(store,'appointments.fire_hazard')}
                            </p>
                            <p><i className="tim-icons icon-check-2 text-success mr-1" />
                                {renderTranslation(store,'appointments.work_for_free')}
                            </p>
                            <div className="mt-4">
                                <a
                                    href="https://zoofy.nl/garantie/"
                                    target="_blank"
                                >
                                    {renderTranslation(store,'appointments.view_conditions')}
                                </a>
                            </div>
                        </CardBody>
                    </Collapse>
                </Card>
            ) : ''}
        </div>
    )
}
