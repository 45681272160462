import React, { Component } from "react";

import UserCard from "../../../shared/Card/UserCard.jsx";
import { Button } from "reactstrap";
import { getCurrentUser, baseEndpoint } from "../../../../services";
import { withTranslation } from "react-i18next";

class PartnerInformationBox extends Component {
  constructor(props) {
    super(props);
  }

  showSocialLink(social) {
    if (social) {
      const win = window.open(social, "_blank");
      win.focus();
    }
  }

  render() {
    return (
      <UserCard
        avatar={
          getCurrentUser()?.data?.logo
            ? baseEndpoint.mediaServiceBaseEndPoint + getCurrentUser().data.logo
            : "https://zoofy.nl/assets/zoofy/images/zoofy.svg"
        }
        name={this.props.userDetail?.name}
        website={this.props.userDetail?.domain}
        description={<p>{this.props.userDetail?.about_company}</p>}
        socials={
          <div className="button-container">
            {/* TODO : SOCIAL BUTTONS */}
            {(this.props.userDetail?.social && 
              Array.isArray(this.props.userDetail?.social) &&
              this.props.userDetail?.social.length > 0) &&
              this.props.userDetail.social.map((social) => {
                return (
                  <Button
                    className="btn-icon btn-round"
                    color={social.type}
                    onClick={() => this.showSocialLink(social.url)}
                  >
                    <i className={`fab fa-${social.type}`} />
                  </Button>
                );
              })}
          </div>
        }
      />
    );
  }
}

export default withTranslation()(PartnerInformationBox);
