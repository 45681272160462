import {VERIFY_ADDRESS_SUCCESSFULLY} from '../constants';

const initial_state = {
  verifyAddress: {}
}
const VerifyAddressReducer = (state = initial_state, action) => {
  switch (action.type) {
    case VERIFY_ADDRESS_SUCCESSFULLY:
      return {...state, verifyAddress: {...action.payload}};
    default:
      return state;
  }
}
export default VerifyAddressReducer;