import { 
    SEND_REQUEST_PASSWORD_RESET,
    RESEND_EMAIL_TOKEN,
    VERIFICATION_OF_EMAIL,
    RESET_PASSWORD,
    ApiResponse
 } from "../constants"
import { forgotPasswordService } from "../services"

/**
 * 
 * @param object payload 
 */
export const RequestPasswordResetAction = (payload) => (dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
        let result = { ...payload }
        forgotPasswordService.forgetPassword(result.email).then((response) => {
            if (response.status === ApiResponse.status.success) {
                dispatch({ type: SEND_REQUEST_PASSWORD_RESET, payload: result });
                resolve({ success: true })
            }
     })
            .catch((error) => {
                console.log('error ', error)
                resolve({ success: false })
            })


    });
}

/**
 * 
 * @param object payload 
 */
export const ResendEmailForTokenAction = (payload) => (dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
        let requestData = { ...payload }
        forgotPasswordService.resendEmailToken(requestData).then((response) => {
            if (response.status === ApiResponse.status.success) {
                dispatch({ type: RESEND_EMAIL_TOKEN, payload:  requestData});
                resolve({ success: true })
            }
     })
            .catch((error) => {
                console.log('error ', error)
                resolve({ success: false })
            })

    });
}

/**
 * 
 * @param object payload 
 */
export const VerificationOfEmailAction = (payload) => (dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
        let requestData = { ...payload }
    
    /*
    * will be implemented later 
      forgotPasswordService.verifyEmailToken(requestData).then((response) => {
             if (response.status === ApiResponse.status.success) {
                 dispatch({ type: VERIFICATION_OF_EMAIL, payload:  requestData});
                 resolve({ success: true })
             }
      })
     */
    dispatch({ type: VERIFICATION_OF_EMAIL, payload:  requestData});
    resolve({ success: true })
            .catch((error) => {
                console.log('error ', error)
                resolve({ success: false })
            })

    });
}


/**
 * 
 * @param object payload 
 */
export const ResetNewPasswordAction = (payload) => (dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
        let requestData = { ...payload }
        forgotPasswordService.resetPassword(requestData).then((response) => {
            if (response.status === ApiResponse.status.success) {
                dispatch({ type: RESET_PASSWORD, payload:  response});
                resolve({ success: true })
            }
     })
            .catch((error) => {
                console.log('error ', error)
                resolve({ success: false })
            })

    });
}