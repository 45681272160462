import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

import PartnerOverviewProfile from "./partials/PartnerOverviewProfile.jsx";
import PartnerInformationBox from "./partials/PartnerInformationBox.jsx";
import PartnerSettings from "./partials/PartnerSettings.jsx";
import PartnerContacts from "./partials/PartnerContacts.jsx";
import "../styles/partnerProfile.scss";
import { getCurrentUser } from "../../../services"
import { withTranslation } from "react-i18next";
import { UpdatePartnersAction } from "../actions"
import { successAlertBox } from ".././../../services"
import i18n from "../../../i18n";
import { renderTranslation } from "services/renderTranslation.service.js";
import classnames from 'classnames';

class PartnerProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userDetail: getCurrentUser().data,
      activeTab: "1",
    }

    document.title = `${renderTranslation(this.props.store, 'profile.contacts.table.heading_1')} | Zoofy`
  }

  toggle = tab => {
    if(this.state.activeTab !== tab) this.setState({activeTab: tab});
  };

  handleUpdateProfile = (payload) => {
    const { store } = this.props;
    this.props.updatePartnersAction(payload, this.state.userDetail.uuid).then((resp)=> {
      if(resp.success) {
        this.setState({userDetail: resp.data });
        successAlertBox(renderTranslation(store, "message.profile_updated_message"))
      }
    })
  };

  render() {
    const { store } = this.props;
    return (
      <div className="content main-content partner-profile">
          <Row>
            <Col md="8">
            <Nav className="nav-pills-info nav-pills-icons justify-content-right" pills>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  <i className="fa fa-user" /> {renderTranslation(store, "profile.profile_overview_tab_title")}
                </NavLink>
              </NavItem>
             {/* TODO : SETTING TAB */}
              {/* <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  <i className="fa fa-cogs" /> {renderTranslation(store, "profile.profile_setting_tab_title")}
                </NavLink>
              </NavItem> */}
            
            {/* TODO : SETTING TAB */}
          <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  <i className="fa fa-address-card " /> {renderTranslation(store, "profile.profile_contact_info_tab_title")}
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <PartnerOverviewProfile 
                    userDetail={this.state.userDetail}
                    submit = {this.handleUpdateProfile}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <PartnerSettings />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <PartnerContacts />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>

          <Col md="4">
            <PartnerInformationBox userDetail={this.state.userDetail}/>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {store:state}
};

const mapDispatchToProps = {
  updatePartnersAction: UpdatePartnersAction
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PartnerProfile));
