import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "./styles/alertBox.scss"

export default function ConfirmAlertBox(props) {
    const { header, body, confirmBtnText, cancelBtnText, open, close, accept,  color, icon } = props;

    return (
        <Modal className="confirm-alert-box" isOpen={open} toggle={close}>
            {icon && (
                            <div className={color ? `text-${color} modal-icon-box` : 'modal-icon-box '}>
                            <div className="modal-icon-block">
                            <i className={icon}></i>
                            </div>
                        </div>
            )}

            <ModalHeader toggle={close}>{header}</ModalHeader>
            <ModalBody>
                {body}
            </ModalBody>
            <ModalFooter>
                <Button color={color} onClick={accept}>{confirmBtnText}</Button>{' '}
                <Button color="secondary" onClick={close}>{cancelBtnText}</Button>
            </ModalFooter>
        </Modal>
    );
}
