import { customerProfileService } from "../services";
import { mediaService } from "../../../services";
import {
  FETCH_SINGLE_CUSTOMER_DETAIL,
  UPDATE_CUSTOMER_DETAIL,
  FETCH_CUSTOMER_ADDRESS_DETAIL,
  UPDATE_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_APPOINTMENTS
} from "../constants";
/**
 *
 * @param object payload
 */
export const UpdateCustomerAction = (id, payload) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    customerProfileService
    .updateCustomerDetail(id, result)
    .then((resp) => {
      dispatch({ type: UPDATE_CUSTOMER_DETAIL, payload: resp.data });
      resolve({ success: true });
    })
    .catch((error) => {
      resolve({ success: false });
    });
  });
};

/**
 *
 * @param {object} payload
 */
export const UploadProfileImageAction = (payload) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    mediaService
    .upload(result)
    .then((resp) => {
      let response = {
        uuid: resp.data.uuid,
        url: resp.data.url,
      };
      resolve({ success: true, data: response });
    })
    .catch((error) => {
      resolve({ success: false });
    });
  });
};

export const GetProfileImageAction = (payload) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    mediaService
    .fetch(result)
    .then((resp) => resolve({ success: true, data: resp.data }))
    .catch((error) => resolve({ success: false }));
  });
};

/**
 *
 * @param {int} id
 */
export const RemoveProfileImageAction = (mediaPath) => (_dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    mediaService
    .delete(mediaPath)
    .then((resp) => {
      if (resp.status === "Success") {
        resolve({ success: true });
      }
      resolve({ success: false });
    })
    .catch((error) => {
      resolve({ success: false });
    });
  });
};

/**
 *
 * @param object payload
 */
export const FetchConsumerAction = (id) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    customerProfileService
    .fetchSingleCustomer(id)
    .then(async (resp) => {
      const data = resp.data && resp.data.length > 0 ? resp.data[0] : {};
      dispatch({ type: FETCH_SINGLE_CUSTOMER_DETAIL, payload: data });
      dispatch({ type: FETCH_CUSTOMER_ADDRESS_DETAIL, payload:  Object.keys(data).length !== 0 ? data.address : {} });
      resolve({ success: true, data: data });
    })
    .catch((error) => {
      resolve({ success: false });
    });

  });
};

/**
 *
 * @param object payload
 */
export const FetchConsumerAddressAction = (id) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    customerProfileService
    .getCustomerAddress(id)
    .then(async (resp) => {
      dispatch({ type: FETCH_CUSTOMER_ADDRESS_DETAIL, payload: resp.data[0] });
      resolve({ success: true, data: resp.data[0] });
    })
    .catch((error) => {
      resolve({ success: false });
    });

    // dispatch({ type: LOGGED_IN_SUCCESSFULLY, payload: currentUserDetail });
  });
};


/**
 *
 * @param object payload
 */
export const UpdateCustomerAddressAction = (id, payload) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    let verifyAddressPayload = {
      house_number: result.suffix,
      postal_code: result.postal_code
    }
    customerProfileService
    .verifyCustomerDetail(verifyAddressPayload)
    .then((resp) => {
      if(resp.data && resp.data.verification === "valid") {

        customerProfileService.updateCustomerAddress(id, result).then((updatedResultAddress) => {

          dispatch({ type: UPDATE_CUSTOMER_ADDRESS, payload: updatedResultAddress[0] });
          resolve({ success: true, valid:true , data: updatedResultAddress});
        })
        .catch((_error)=> {
          resolve({ success: false, valid:true });
        })

      } else {
        resolve({ success: true, valid:false });
      }
    })
    .catch((_error) => {
      resolve({ success: false, valid:false });
    });
  });
}

/**
 *
 * @param object payload
 */
export const FetchConsumerAppointmentsAction = (payload) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    customerProfileService
    .fetchCustomerAppointments(result)
    .then(async (resp) => {
      dispatch({ type: FETCH_CUSTOMER_APPOINTMENTS, payload: resp });
      resolve({ success: true, data: resp });
    })
    .catch((error) => {
      resolve({ success: false });
    });

    // dispatch({ type: LOGGED_IN_SUCCESSFULLY, payload: currentUserDetail });
  });
};
