import React, { useState } from "react";
import {
  FormGroup,
  FormControl
} from "react-bootstrap";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import FormInputs from "../../../shared/FormInputs/FormInputs.jsx";
import { getCurrentUser, baseEndpoint } from "../../../../services";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { useDispatch, useStore } from "react-redux";
import { UploadProfileImageAction, RemoveProfileImageAction } from "../../actions"
import { mediaBaseUrl } from "../../../../services"
import avatar from "../../../../assets/img/logo.svg";
import { renderTranslation } from "services/renderTranslation.service.js";
import { errorAlertBox , successAlertBox } from "../../../../services";
import { ALLOWED_FILE_TYPES } from "../../constants/UploadFIleContstats";

export default function PartnerProfile(props) {
  const { t } = useTranslation();
  const [loadImage, setLoadImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDetail } = props;
  const [user, setUser] = useState(userDetail);
  const dispatch = useDispatch();
  const ReduxStore = useStore()
  let store = ReduxStore.getState()
  let inputOpenFileRef = null;
  const [state, setState] = useState({
    logo: "",
    name: "",
    domain: "",
    city: "",
    country: "",
    facebook: "",
    twitter: "",
    about_company: "",
    parked_jobs: ""
  });

  React.useEffect(() => {
    if(user.social && user.social.length > 0 ) {
      user.social.forEach((social)=> {
        setState(prevState => ({ ...prevState, [social.type]: social.url }));
      })
    }
    console.log(user.social)
  }, [])


  const handleFIleUpload = () => {
    inputOpenFileRef.click();
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (!ALLOWED_FILE_TYPES.includes(files[0].type)) {
      errorAlertBox(
        renderTranslation(
          store,
          'upload-error.file-not-allowed'
        )
      );
    }

    if (files[0].size > process.env.REACT_APP_MAX_UPLOAD_SIZE) {
      errorAlertBox(
        renderTranslation(
          store,
          'step_3.upload-error.file-too-large'
        )
      );
      return;
    }

    if (name === 'logo') {
      let payload = {
        ref_id: user.uuid,
        file: files[0],
        type: "Profile"
      };
      setLoadImage(true);
      dispatch(UploadProfileImageAction(payload)).then((resp) => {
        if (resp?.data?.url) {
          setState(prevState => ({ ...prevState, ['logo']: resp.data.url }));
        }
        setLoadImage(false)
      })

    } else if (name === 'parked_jobs') {
      setState(prevState => ({ ...prevState, parked_jobs: !state.parked_jobs }));
    } else {
      setState(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = () => {
    setLoading(true)
    let payload = {};
    let social = [];
    Object.keys(state).forEach(function (prop) {
      if (state[prop] !== '') {
        if(prop === 'facebook' || prop === 'twitter') {
          social.push({"type":prop, "url":state[prop]})
          payload['social'] = social
          return;
        }
        payload[prop] = state[prop];
      }
    });

    console.log(payload);
    props.submit(payload)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const removeProfileImage = () => {
    if (state.logo !== '') {
      dispatch(RemoveProfileImageAction(state.logo)).then((response) => {
        setState(prevState => ({ ...prevState, ['logo']: "" }));
      })

    }
  };

  return (
    <div className="content partner-overview">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">{renderTranslation(store, "profile.profile_overview_tab_title")}</CardTitle>
        </CardHeader>
        <CardBody>
          <form>
            <Row>
              <Col md={12}>
                <div className="contact-profile-image-text"><div>
                  {loadImage && (
                    <i className="fas fa-spinner fa-pulse"></i>
                  )}

                </div>
                </div>
                <FormGroup>


                  <FormControl
                    type="File"
                    name="logo"
                    placeholder={renderTranslation(store, "auth.email_placeholder_text")}
                    onChange={handleChange}
                    inputRef={(ref) => { inputOpenFileRef = ref }}
                  />
                  <span className="error"></span>
                </FormGroup>
                <div
                  id="upload-profile-contact-image"
                  className="preview-image-box">
                  <span>

                    <img
                      onClick={handleFIleUpload}
                      src={state.logo !== "" ? `${baseEndpoint.mediaServiceBaseEndPoint}/${state.logo}` : (user.logo && user.logo !== "") ? `${baseEndpoint.mediaServiceBaseEndPoint}/${user.logo}` : avatar}
                      className="preview-image-box-img"
                      alt="logo"
                    />

                  </span>
                  {(state.logo !== '') && (<UncontrolledTooltip delay={0} target="remove-partner-logo-tooltip">
                      {renderTranslation(store, "buttons.remove_profile_image_text")}
                    </UncontrolledTooltip>)}
                    {(state.logo !== '' || (user.logo && user.logo !== "")) && (
                      <small id="remove-partner-logo-tooltip" className="remove-logo-close" onClick={removeProfileImage} >
                        <i className="fa fa-trash"></i></small>
                    )}
                </div>

              </Col>
            </Row>
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: t("forms.company_name_text"),
                  type: "text",
                  name: "name",
                  placeholder: t("forms.company_name_placeholder_text"),
                  bsClass: "form-control",
                  error: null,
                  onChange: handleChange,
                  defaultValue: user.name,

                },
                {
                  label: renderTranslation(store, "forms.website_text"),
                  type: "text",
                  name: "domain",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.website_placeholder_text"),
                  defaultValue: user.domain,
                  onChange: handleChange,
                  error: null,
                },

              ]}
            />
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.email_text"),
                  type: "text",
                  name: "email",
                  placeholder: renderTranslation(store, "forms.email_placeholder_text"),
                  bsClass: "form-control",
                  disabled: true,
                  error: null,
                  defaultValue: getCurrentUser().email,
                },

                {
                  label: `${renderTranslation(store, "forms.phone_no_text")}( ${getCurrentUser().country_code} )`,
                  type: "text",
                  name: "phone",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.phone_no_placeholder_text"),
                  disabled: true,
                  defaultValue: getCurrentUser().phone_number,
                  error: null,
                },

              ]}
            />

            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.city_text"),
                  type: "text",
                  name: "city",
                  placeholder: renderTranslation(store, "forms.city_placeholder_text"),
                  bsClass: "form-control",
                  error: null,
                  onChange: handleChange,
                  defaultValue: user.city,
                },
                {
                  label: renderTranslation(store, "forms.country_text"),
                  type: "text",
                  name: "country",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.country_placeholder_text"),
                  defaultValue: user.country,
                  onChange: handleChange,
                  error: null,
                },

              ]}
            />

            <FormInputs
              ncols={["col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.subuser_appointments_need_approval"),
                  type: "checkbox",
                  name: "parked_jobs",
                  bsClass: "input-group",
                  style: { width: 'unset' },
                  error: null,
                  onChange: handleChange
                }
              ]}
            />

            <div className="space-25"></div>


            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.facebook_text"),
                  type: "text",
                  name: "twitter",
                  placeholder: renderTranslation(store, "forms.facebook_placeholder_text"),
                  bsClass: "form-control",
                  defaultValue: state.facebook,
                  onChange: handleChange,
                  error: null,
                },
                {
                  label: renderTranslation(store, "forms.twitter_text"),
                  type: "text",
                  name: "twitter",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.twitter_placeholder_text"),
                  defaultValue: state.twitter,
                  onChange: handleChange,
                  error: null,
                },

              ]}
            />
            <div className="space-25"></div>
            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.about_company_text"),
                  name: "about_company",
                  bsClass: "form-control",
                  rows: "5",
                  componentClass: "textarea",
                  placeholder: renderTranslation(store, "forms.about_company_placeholder_text"),
                  defaultValue: user.about_company,
                  onChange: handleChange,
                  error: null,
                },
              ]}
            />


          </form>
        </CardBody>
        <CardFooter>
          <Button color="info"  type="submit" onClick={handleSubmit}>
            {renderTranslation(store, "buttons.update_profile_text")}
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}



