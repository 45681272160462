import alertify from "alertifyjs";

alertify.set("notifier", "position", "top-right");

export const successAlertBox = (message) => {
  alertify.success(message);
};

export const errorAlertBox = (message) => {
  alertify.error(message);
};

export const warnAlertBox = (message) => {
  alertify.warning(message);
};

export const notificationMessage = (message, type) => {
  let icon = "";
  icon = 
  type === "success"
  ? 'fa fa-check-circle'
  : type === "error"
  ? 'fa fa-exclamation-circle'
  : type === "warning"
  ? 'fa fa-warning'
  : type === "info"
  ? 'fa fa-info-circle'
  : "";
  
  return {
    place: "tr",
    message: message,
    type: type,
    icon: icon,
    autoDismiss: 15,
  };
};
