import { GET_COUNTRIES } from '../constants';
import { countryService } from '../services'

/**
 * 
 * @param object payload 
 */
export const GetCountriesAction = (payload) => (dispatch, _getState) => {

  return countryService.fetch();

  return new Promise((resolve, _reject) => {
    countryService.fetch().then((response) => {
      dispatch({ type: GET_COUNTRIES, payload: response });
    })
      .catch((error) => {
        resolve({ success: false })
      })
  });
}