import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

/**
 * API service for sub-user listing
 */
class SubUserService {
  http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint)
  }

  /**
   *
   * @param {array} params
   * @param {int} page
   *
   */
  fetchAllSubUsers(params = [], page = null) {
    let filterParam = "";
    if (params.length > 0) {
      [...params].forEach((param, key) => {
        filterParam += `${key === 0 ? "?" : "&"}filter[${param.key}]=${
          param.value
        }`;
      });
    }
    if (page) {
      filterParam += `${filterParam === "" ? "?" : "&"} page=${page}`;
    }
    return this.http.get(apiUrls.subUser.fetchAllSubUsers(filterParam));
  }

  createSubUser(payload) {
      return this.http.post(apiUrls.subUser.createSubUser, payload)
  }
}

export const subUserService = new SubUserService();
