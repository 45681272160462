import { feedService } from '../services'
import { FETCHED_FEED_SUCCESSFULLY } from '../constants';

/**
 * Fetch appointment listing action 
 * @param object payload 
 */
export const FetchFeedAction = (payload, page) => (dispatch, _getState) => {

  let role = localStorage.getItem('role');

  switch (role) {
    case 'partner':
      return new Promise((resolve, _reject) => {
        let result = { ...payload }
        feedService.fetchFeed(result , page).then((response) => {
         // dispatch({ type: FETCHED_FEED_SUCCESSFULLY, payload: response });
          resolve({ success: true, data: response })
        })
        .catch((error) => {
          console.log('error ', error)
          resolve({ success: false })
        })
      });
      break;
    case 'subuser':
      return new Promise((resolve, _reject) => {
        let result = { ...payload }
        feedService.fetchSubuserFeed(result , page).then((response) => {
          let array = [];

          response.map((item) => {
            item.appointment.data.created_at = item.created_at;
            array.push(item.appointment.data);
          });

          resolve({ success: true, data: array })
        })
        .catch((error) => {
          console.log('error ', error)
          resolve({ success: false })
        })
      });
      return new Promise((resolve, _reject) => {
        resolve({ success: false })
      });
      break;
    default:
  }

  
}