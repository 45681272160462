
import { consumerService } from "../services";

/**
 * Fetch all consumers
 * @param object payload
 * @return Promise 
 */
export const FetchConsumersAction = (payloads) => (_dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
        consumerService
        .fetchAllConsumers(payloads.params, payloads.page)
        .then(async (resp) => {
          resolve({ success: true, data: resp });  
        })
        .catch((error) => {
          resolve({ success: false });
        });
  
    });
  };

  /**
 * Fetch create consumer
 * @param object payload
 * @return Promise 
 */
export const CreateConsumersAction = (payloads) => (_dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
      consumerService
      .createCustomer(payloads)
      .then(async (resp) => {
        resolve({ success: true, data: resp });  
      })
      .catch((error) => {
        resolve({ success: false });
      });

  });
};
  

/**
 * Verify address of consumers
 * @param object payload
 * @return Promise 
 */
export const VerifyAddressAction =  (payload) => async (dispatch, _getState) => {

  return await new Promise( async (resolve, _reject) => {
    await consumerService.verifyAddress(payload).then((response) => {
      resolve({ success: true, data: response })
  })
    .catch((error)=> {
      resolve({ success: false })
    });
  
  });
}