import React,  { Suspense }  from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import StoreConfig from './modules/ConfigStore'

import {HashRouter, Route, Switch, Redirect} from "react-router-dom";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/sass/general.scss";

import "assets/css/pe-icon-7-stroke.css";
import "flag-icons/css/flag-icons.min.css";
import App from "./app.js";

import "assets/css/demo.css";
import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss";
import moment from "moment-timezone";


const store = StoreConfig();
moment.tz.setDefault(process.env.REACT_APP_TIME_ZONE || "Europe/Amsterdam");

ReactDOM.render(

    <Provider store={store}>
      <HashRouter>
        <Suspense fallback="Loading...">
          <App />
        </Suspense>
      </HashRouter>
    </Provider>,

  document.getElementById("root")
);
