import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

export default class ParkedJobService {

    http = "";
    constructor() {
        this.http = httpHandler(
            baseEndpoint.partnersServiceBaseEndPoint);
    }

    submitParkedJob(params) {
        return this.http.get(`${apiUrls.createAppointment.parkedJob(params.uuid, params.type)}`);
    }
}



export const parkedJobService = new ParkedJobService();