import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

/**
 * API service for consumer listing and manage consumer filtering
 */
class ConsumerService {
  http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint)
  }

  /**
   *
   * @param {array} params
   * @param {int} page
   *
   */
  fetchAllConsumers(params = [], page = null) {
    let filterParam = "";
    if (params.length > 0) {
      [...params].forEach((param, key) => {
        filterParam += `${key === 0 ? "?" : "&"}filter[${param.key}]=${
          param.value
        }`;
      });
    }
    if (page) {
      filterParam += `${filterParam === "" ? "?" : "&"} page=${page}`;
    }
    return this.http.post(apiUrls.profile.fetchAllConsumer(filterParam));
  }

  fetchAllConsumersList() {
    return this.http.post(apiUrls.profile.fetchConsumerList());
  }

  verifyAddress(params) {
    return this.http.post(apiUrls.verify.verifyAddress, params);
  }

  createCustomer(payload) {
      return this.http.post(apiUrls.customer.create, payload)
  }
}

export const consumerService = new ConsumerService();
