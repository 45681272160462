import { SET_APP_SETTINGS } from '../constants';
import { setAppSettings } from '../services'

export const SetAppSettings = (payload) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    let result = { ...payload }
    setAppSettings.setAppSettings(result).then((response) => {
      dispatch({ type: SET_APP_SETTINGS, payload: response });
      resolve({ success: true })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}