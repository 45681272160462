import React, { Component } from "react";
import { Grid, Row, Col, FormControl, FormGroup } from "react-bootstrap";

import Card from "../../modules/shared/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

class RegisterPage extends Component {
  render() {
    return (
      <Grid>
        <Row>
          <Col md={8} mdOffset={2}>
            <div className="header-text">
              <h2>Zoofy - zo gefikst</h2>
              <hr />
            </div>
          </Col>

          <Col md={8} mdOffset={2}>
            <form>
              <Card
                plain
                content={
                  <div>
                    <FormGroup>
                      <FormControl type="text" placeholder="Your First Name" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" placeholder="Your Last Name" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" placeholder="Company" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="email" placeholder="Enter Email" />
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="password" placeholder="Password" autoComplete="off"/>
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        type="password"
                        autoComplete="off"
                        placeholder="Password Confirmation"
                      />
                    </FormGroup>
                  </div>
                }
                ftTextCenter
                legend={
                  <Button wd fill neutral>
                    Create Free Account
                  </Button>
                }
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default RegisterPage;
