import React from 'react';
import {Button, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, FormGroup, Row, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { v4 as uuidv4 } from 'uuid';
import {
  CreateAppointmentAction, FetchAllConsumersListAction,
  FetchTaskDetailsAction,
  FetchTasksAction, ResetDefault, SetValidationErrors,
  TaskRateAction, UpdateDescriptionAction,
  VerifyAddressAction
} from "../actions";
import {FetchConsumerAddressAction} from "../../profile/actions";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {ControlLabel} from "react-bootstrap";
import Select from "react-select";
import SearchableSelect from "../../shared/Select/SearchableSelect";
import { map } from 'lodash';
import FormInputs from "../../shared/FormInputs/FormInputs";
import ConsumerSelection from "./partials/ConsumerSelection";
import ConsumerUpdateAlert from "./partials/ConsumerUpdateAlert";
import Coupon from "./partials/Coupon";
import WarrantyPackage from "./partials/WarrantyPackage";
import AssignmentType from "./partials/AssignmentType";
import ScheduleAppointment from "./partials/ScheduleAppointment";
import AssignmentCompactView from "./partials/AssignmentCompactView";
import DynamicFields from "./partials/DynamicFields";
import UploadFile from "./partials/UploadFile";
import TextInput from "../../shared/Input/TextInput";
import {emailIsValid, isEmpty} from "./helpers/Validations";
import moment from "moment-timezone";
import {formatScheduleOptions} from "./helpers/Assignment";
import {loginService} from "../../authentication/services";
import i18n from "../../../i18n";
import {getCurrentUser} from "../../../services";
import { dataSales } from 'variables/Variables';
import { renderTranslation } from "services";

let t = () => {
  // This is intentional to assign props to it later
};

class CreateAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showSuccess: false,
      uuid: null,
      submitted: false,
      warrantyFlag: true
    };
    t = props.t;
    document.title = `${renderTranslation(this.props.store,'appointments.create_appointment_title')} | Zoofy`
  }

  componentDidMount() {
    this.fetchAllTask();
    this.fetchAllConsumer();
    this.setUuid();
  }

  componentWillUnmount() {
    this.props.resetDefault();
  }

  fetchAllTask = () => {
    this.props.fetchTasksAction().then((result) => {
      if (this.props.bare_minimum_tasks.length > 0) {
        const arr = [];
        this.props.bare_minimum_tasks.map((task, index) => {
          const obj = {};
          obj.value = task.id.toString();
          obj.label = task.singular[0].toUpperCase() + task.singular.slice(1);

          arr.push(obj);
          return null;
        });
        this.setState({
          taskList: arr,
        });
      }
    });
  };

  fetchAllConsumer = (value = null) => {
    let payload = {
      params: value ? [{ key: "name", value: value }] : [],
      page: null,
    };

    this.props.fetchAllConsumersListAction(payload)
        .then(res => {
          this.setState({
            loading: false
          });
        });
  };

  setUuid = () => {
    let uuid = uuidv4();
    this.setState({ uuid })
  }

  hideAlert = () => {
    this.setState({
      showSuccess: false
    });

    this.props.history.push("/admin/appointments")
  }

  renderSuccessAlert = () => {
    const { store } = this.props;

    if(this.state.showSuccess) {
      return (
          <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title={renderTranslation(store,"message.appointment_created_success_title")}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
          >
            {renderTranslation(store,"message.appointment_created_success")}
          </SweetAlert>
      );
    }
    return '';
  }

  handleTaskChange = (value) => {
    this.props.fetchTaskDetailsAction(value)
  }

  handleDescriptionChange = (value) => {
    this.props.updateDescriptionAction(value);
  }

  handleConsumerChange = (value, byPass = false) => {
    if(!byPass && this.state.consumerUpdated) {
      this.setState({
        consumerUpdateAlert: true,
        newConsumerId: -1
      })
    } else if (value && this.state.consumerList[value]) {
      this.setState({
        consumer: this.state.consumerList[value],
        consumerUpdated: false,
        newConsumerId: null
      })
    } else {
      this.setState({
        consumer: null,
        consumerUpdated: false,
        newConsumerId: null
      })
    }
  }

  updateConsumerData = (consumer) => {
    this.setState({
      consumerUpdated: true,
      consumer
    })
  }

  createNewConsumer = (bypass = false) => {
    if(!bypass && this.state.consumerUpdated) {
      this.setState({
        consumerUpdateAlert: true,
        newConsumerId: -1
      })
    } else {
      this.setState({
        consumerUpdated: false,
        newConsumerId: null,
        consumer: {
          address: {}
        }
      });
    }
  }

  acceptUpdateConsumer = () => {
    if(this.state.newConsumerId === -1) {
      this.createNewConsumer(true);
    } else {
      this.handleConsumerChange(this.state.newConsumerId, true);
    }
    this.closeUpdateConsumerAlert();
  }

  closeUpdateConsumerAlert = () => {
    this.setState({
      consumerUpdateAlert: false
    })
  }

  updateCoupon = (coupon) => {
    this.setState({
      coupon
    })
  }

  applyCoupon = (coupon) => {

  }

  updateWarrantyFlag = () => {
    this.setState({
      warrantyFlag: !this.state.warrantyFlag
    })
  }

  validateAppointment = () => {
    const { store } = this.props;
    const errors = {};
    const { appointment, partners } = this.props;
    const currentDate = moment();
    if (!appointment.task.id) {
      errors.taskId = renderTranslation(store,'appointments.task_select_validation');
    }
    const consumer = appointment.consumer;
    const { address } = appointment;
    if (!consumer || !consumer.id) {
      errors.consumer = renderTranslation(store,'appointments.consumer_select_validation');
    } else {
      if (isEmpty(consumer.first_name)) {
        errors.first_name = renderTranslation(store,'appointments.first_name_validation');
      }
      if (isEmpty(consumer.last_name)) {
        errors.last_name = renderTranslation(store,'appointments.last_name_validation');
      }
      if (isEmpty(consumer.email)) {
        errors.email = renderTranslation(store,'appointments.email_validation');
      } else if (!emailIsValid(consumer.email)) {
        errors.email = renderTranslation(store,'appointments.valid_email_validation');
      }
      if (isEmpty(consumer.telephone)) {
        errors.telephone = renderTranslation(store,'appointments.telephone_validation');
      }

      if (isEmpty(address.postal_code) || isEmpty(address.suffix)) {
        errors.address = renderTranslation(store,'appointments.address_validation');
      } else if(!address || !address.lat || !address.lon) {
        errors.address = renderTranslation(store,'appointments.valid_address_validation');
      }
    }
    const assignment = appointment.assignment;
    if (assignment.selected === 'schedule') {
      // const selectedDays = { ...assignment.selectedDays };
      // const updatedSelectedDays = { ...assignment.selectedDays };
      // for (const selectedDaysKey in selectedDays) {
      //   const selectedDay = selectedDays;
      //   if (!slots.length) {
      //     delete updatedSelectedDays[selectedDaysKey];
      //   }
      // }
      // if (!Object.keys(updatedSelectedDays).length) {
      //   errors.schedule = true;
      // }
    }

    if (Object.keys(errors).length) {
      this.props.setValidationErrors(errors);
      return false;
    }
    return true;
  }

  submitAppointment = () => {
    this.setState({submitted: true})
    this.props.setValidationErrors({});
    const res = this.validateAppointment();
    if (res) {
      const { appointment } = this.props;
      const { assignment, consumer, address } = appointment;
      const assignmentRes = formatScheduleOptions(assignment);

      const data = {};
      data.task_id = parseInt(appointment.taskId);

      if(assignment.selected == "schedule") {
        let max = -1;
        assignmentRes.options.forEach((option) => {
          if(option.rate > max) {
            max = option.rate;
          }
        });
        data.rate = max;
      }else{
        data.rate = assignmentRes.rate;
      }

      const dynamicFields = appointment.dynamicAnswers.filter((dynamicField) => dynamicField.values[0]);

      data.day = `${moment().add('12', 'hours').format("YYYY-MM-DDTHH:mm:00.000")}Z`;
      data.user = {
        first_name: consumer.first_name,
        last_name: consumer.last_name,
        telephone: consumer.telephone,
        email: consumer.email
      };
      data.comment = appointment.description;
      data.address = address;
      data.dynamicFields = dynamicFields;
      data.coupon_code = (appointment.coupon) ? appointment.coupon : '';
      data.caseNumber = `partner-subuser`;
      data.job_sources_id = 5;
      data.insurance_id = this.state.warrantyFlag ? 1 : null;
      data.uuid = this.state.uuid;
       // Map options and add startTimeZulu and endTimeZulu
    data.options = assignmentRes.options.map(option => {
      const { startTime, endTime, rate } = option;

       // Parse startTime and endTime as UTC
    const parsedStartTime = moment.utc(startTime); // Treat as UTC
    const parsedEndTime = moment.utc(endTime);     // Treat as UTC

    // Add 1 hour to both times
    const startPlusOne = parsedStartTime.add(1, 'hour');
    const endPlusOne = parsedEndTime.add(1, 'hour');

    // Get the user's local timezone
    const localTimezone = moment.tz.guess();

    // Get the UTC offset for the local timezone at the time of the event
    const localOffsetHoursStart = moment.tz(startPlusOne, localTimezone).utcOffset() / 60; // Offset at start time
    const localOffsetHoursEnd = moment.tz(endPlusOne, localTimezone).utcOffset() / 60;     // Offset at end time

    // Convert back to UTC by subtracting the local offset
    const startTimeZulu = startPlusOne.subtract(localOffsetHoursStart, 'hours').utc().format("YYYY-MM-DDTHH:mm:ss.000[Z]");
    const endTimeZulu = endPlusOne.subtract(localOffsetHoursEnd, 'hours').utc().format("YYYY-MM-DDTHH:mm:ss.000[Z]");

        
      // Return the modified option with new properties
      return {
        startTime,
        endTime,
        rate,
        startTimeZulu,
        endTimeZulu
      };
    });


      let body = {
        data: data
      }

      body = JSON.stringify(body);

      this.props.createAppointmentAction(body)
          .then(res => {
            if (res.success) {
              this.setState({
                showSuccess: true
              })
            }else{
              this.setState({
                submitted: false
              })
            }
          })
    }else{
      this.setState({
        submitted: false
      })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner color="primary" />
      );
    }

    const { store } = this.props;

    return (
        <div className="content appointments">
          <Row>
            <Col md={8} sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {renderTranslation(store,"appointments.what_section_header")}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <SearchableSelect
                      label={renderTranslation(store,"forms.task_text")}
                      value={this.props.appointment.taskId}
                      handleChange={val => this.handleTaskChange(val)}
                      placeholder={renderTranslation(store,'forms.task_placeholder_text')}
                      options={this.state.taskList}
                      error={this.props.errors.taskId}
                      autoFocus
                  />
                  <DynamicFields />
                  <TextInput
                      type="textarea"
                      label={renderTranslation(store,"forms.description_text")}
                      value={this.props.appointment.description}
                      onBlur={e => this.handleDescriptionChange(e.target.value)}
                  />
                </CardBody>
              </Card>

              <ConsumerSelection
                  createNewConsumer={e =>this.createNewConsumer()}
              />

              <AssignmentType />

              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {renderTranslation(store,"appointments.additional_section_header")}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <UploadFile uuid={this.state.uuid} store={this.props.store}/>
                  <Coupon update={this.updateCoupon} coupon={this.state.coupon} apply={this.applyCoupon} />
                  <WarrantyPackage
                  insuranceFee={this.props.systemData?.insurance?.insurance_fee || ''}
                  warrantyFlag={this.state.warrantyFlag}
                  updateWarrantyFlag={this.updateWarrantyFlag} />
                </CardBody>
              </Card>
              <div className="text-center">
                <Button onClick={this.submitAppointment} color="primary" disabled={this.state.submitted}>{renderTranslation(store,"appointments.submit_appointment")}</Button>
              </div>
            </Col>
            <Col md={4}>
              <AssignmentCompactView />
            </Col>
          </Row>
          {this.renderSuccessAlert()}
          {/*<ConsumerUpdateAlert open={this.state.consumerUpdateAlert} accept={this.acceptUpdateConsumer} close={this.closeUpdateConsumerAlert} />*/}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
    systemData: state.setAppSettings.settings,
    bare_minimum_tasks: state.tasks_bare_minimum.tasks_bare_minimum.tasks,
    partners: state.partners.partners,
    verifyAddress: state.verifyAddress.verifyAddress,
    appointment: state.createAppointment,
    errors: state.createAppointment.errors
  };
};

const mapDispatchToProps = {
  fetchTasksAction: FetchTasksAction,
  fetchTaskDetailsAction: FetchTaskDetailsAction,
  verifyAddressAction: VerifyAddressAction,
  createAppointmentAction: CreateAppointmentAction,
  fetchAllConsumersListAction: FetchAllConsumersListAction,
  fetchConsumerAddressAction: FetchConsumerAddressAction,
  taskRateAction: TaskRateAction,
  updateDescriptionAction: UpdateDescriptionAction,
  setValidationErrors: SetValidationErrors,
  resetDefault: ResetDefault
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(CreateAppointment)
);
