import {SEND_REQUEST_PASSWORD_RESET, VERIFICATION_OF_EMAIL} from '../constants';

const initial_state = {
  forgotDetails: {}
}
const ForgotPasswordReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SEND_REQUEST_PASSWORD_RESET:
      return {...action.payload};
    case VERIFICATION_OF_EMAIL: 
      return {...action.payload};
    default:
      return state;
  }
}
export default ForgotPasswordReducer;