
/**
 * validate reset new password input fields
 * @param {object} state 
 */
export const ResetNewPasswordValidate = (state) => {

    let { email, token, password, password_confirmation } = state

    let passwordMessage = null;
    let confirmPasswordMessage = null;

    let isValid = true;

    if(!email || !token) {
        isValid = false;
        passwordMessage = "Email not found";
    }

    if (!password) {
        isValid = false;

        passwordMessage = "Please enter new password.";
    }
    if (!password_confirmation) {
        isValid = false;

        confirmPasswordMessage = "Please enter your valid code.";
    }

    if (password !== password_confirmation) {
        isValid = false
        confirmPasswordMessage = "Password and confirm password is not matching.";
    }

    if (typeof password !== "undefined" && password.length < 6) {
        isValid = false;
        passwordMessage = "Password Length must be greater then 6 character";
    }
    return {
        isValid: isValid,
        message: {
            password: passwordMessage,
            password_confirmation: confirmPasswordMessage
        }
    };
};