import { createAppointment } from '../services'

/**
 * 
 * @param object payload 
 */
export const VerifyAddressAction =  (payload) => async (dispatch, _getState) => {

  return await new Promise( async (resolve, _reject) => {
    await createAppointment.verifyAddress(payload).then((response) => {
      resolve({ success: true, data: response })
  })
    .catch((error)=> {
      resolve({ success: false })
    });
  
  });
}