import React, { Component } from "react";
import { connect } from 'react-redux';
import { FetchConsumerAction, FetchConsumerAppointmentsAction } from "../actions"

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

import CustomerOverviewProfile from "./partials/CustomerOverviewProfile.jsx";
import CustomerInformationBox from "./partials/CustomerInformationBox.jsx";
import CustomerContactDetail from "./partials/CustomerContactDetail.jsx";
import CustomerAppointments from "./partials/CustomerAppointments.jsx";
import "../styles/customerProfile.scss";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { renderTranslation } from "services";
import classnames from 'classnames';


class CustomerProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      isExistCustomerProfileData: false,
      isExistCustomerAddressData: false,
      isExistCustomerAppointments: false,
      customerId: 0,
      loading: false
    }
    document.title = `${renderTranslation(this.props.store, 'appointments.table.customer_text')} | Zoofy`
  }

  componentWillMount() {
    this.fetchCustomerProfile();

  }
  
  fetchCustomerProfile() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.fetchConsumerAction(this.props.match.params.id).then((result) => {
        if (result.data && result.data.id) {
          
          this.setState({customerId: result.data.customer_id})
         this.fetchCustomerAppointmentsDetail();
        }
        if (result.success) {
          this.setState({isExistCustomerProfileData: true, isExistCustomerAddressData: true});
        
  
        } else {
          this.props.history.push("/admin/consumers")
        }
      })
    } else {
      this.props.history.push("/admin/consumers")
    }
  }

  fetchCustomerAppointmentsDetail  = (page = 1) => {
    if (this.state.customerId) {
      let payload = {
        page: page,
        customer_id: this.state.customerId
      }
      this.props.fetchConsumerAppointmentsAction(payload).then((_result) => {
        this.setState({isExistCustomerAppointments: true});
      });
    }
  }

  /**
   * @param int tab 
   */
  toggle = tab => {
    this.setState({activeTab: tab});
  }
  

  render() {
    const { store } = this.props;
    return (
      <div className="content partner-profile">
          <Row>
            <Col md={8}>
            <Nav className="nav-pills-info nav-pills-icons justify-content-right" pills>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  <i className="fa fa-user" /> {renderTranslation(store, "profile.customers_profile_overview_tab_title")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  <i className="fa fa-address-card" /> {renderTranslation(store, "profile.customers_profile_address_tab_title")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  <i className="fa fa-calendar" /> {renderTranslation(store, "profile.customers_profile_appointments_tab_title")}
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                  {this.state.isExistCustomerProfileData && (<CustomerOverviewProfile  />)}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                  {(this.state.isExistCustomerAddressData) ? (<CustomerContactDetail  />) : ""}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                  {this.state.isExistCustomerAppointments ? (<CustomerAppointments {...this.props} />) : ""}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            </Col>
            <Col md={4}>
              <CustomerInformationBox />
            </Col>
          </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
 return {
   store: state
 }
}
const mapDispatchToProps = {
  fetchConsumerAction: FetchConsumerAction,
  fetchConsumerAppointmentsAction: FetchConsumerAppointmentsAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerProfile));

