import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FetchConsumersAction,
  VerifyAddressAction,
  CreateConsumersAction,
} from "../actions";
// import { Table, Grid, Row, Col } from "react-bootstrap";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import "../styles/consumer.scss";
import FormInputs from "../../shared/FormInputs/FormInputs.jsx";
import Pagination from "../../shared/Pagination/Pagination.jsx";
import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { renderTranslation } from "services";
import TextInput from "modules/shared/Input/TextInput";
import AddressIcon from "../../../assets/img/arrow-right.png";
import AddressSelection from "../../Appointments/views/partials/AddressSelection";
import { Label } from "react-bootstrap";
import { generateSessionId } from "../../../services/generateSessionId";

/**
 * - listing of all customers
 * - filter/searching of specific customer
 */
class Consumers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      data: [],
      meta: {
        page: 0,
      },
      loading: false,
      showModal: false,
      validAddress: true,
      address: null,
      addressList: [],
      addressValue: "",
      operatingCountry: true,
      sessionId: null,
    };
    this.createInput();
    document.title = `${renderTranslation(
      this.props.store,
      "appointments.table.customer_text"
    )} | Zoofy`;
  }

	createInput() {
		const operatingCountry =
			(localStorage.getItem("operating-country") &&
				localStorage.getItem("operating-country")) ||
			"";
			this.customerForm = new Group({
				firstName: new Control(null),
				lastName: new Control(null, [Validators.required]),
				email: new Control(null, [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]),
				telephone: new Control(null, [Validators.required, Validators.pattern('^(\\+\\d{1,3}[- ]?)?\\d{10}$')]),
			});
	}

  componentWillMount() {
    this.setState({ sessionId: generateSessionId() })
    this.fetchConsumers();
  }

  /**
   * Fetch all customers
   * @param {array} params
   * @param {int|null} page
   */
  fetchConsumers = (params = [], page = null) => {
    let payload = {
      params: params,
      page: page,
    };
    this.setState({ loading: true });
    this.props.fetchConsumersAction(payload).then((customers) => {
      this.setState({ loading: false });
      if (customers.success) {
        const data = customers.data.data;
        this.setState({ data });
        this.normalizeMetaTag(customers.data);
      }
    });
  };

  /**
   * Format meta tags object
   * @param {object} customers
   */
  normalizeMetaTag = (customers) => {
    const meta = { ...customers };
    delete meta.data;
    this.setState({ meta });
  };

  handleFilterChange = (e) => {
    let filters = this.state.filters;
    if (filters.findIndex((i) => i.key === e.target.name) !== -1) {
      filters[filters.findIndex((i) => i.key === e.target.name)].value =
        e.target.value;
    } else {
      filters.push({ key: e.target.name, value: e.target.value });
    }
    this.setState({ filters });
  };

  applyFilter = () => {
    if (this.state.filters.length > 0) {
      this.fetchConsumers(this.state.filters, null);
    }
  };

  /**
   * show single customer profile
   * @param {string} id
   */
  viewProfile = (id) => {
    this.props.history.push(`/admin/consumer-profile/${id}`);
  };

  /**
   * handle pagination to show next or previous page customer records
   * @param {string} direction
   */

  handlePaginate = (page) => {
    this.fetchConsumers(this.state.filters, page);
  };

  handleCreateCustomer = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    this.fetchConsumers();
  };

  errorMassage = (label, errors) => {
    const { store } = this.props;
    if (errors && this.state.errorShow) {
      console.log(errors, 'errors')
      if (errors.required) {
        const searchedTranslation = renderTranslation(store, "errors.required", {
          fieldName: label,
        });
        return searchedTranslation.includes('undefined') ? searchedTranslation.replace('undefined', label) : searchedTranslation;
      }
      if (errors.pattern) {
        return renderTranslation(store, "errors.format", { fieldName: label });
      }
    }
    return null;
  };

	handleUserInput = (event) => {
		const target = event.target;
		this.customerForm.controls[`${target.name}`].setValue(target.value);
	};

  toggleModal = () => {
    let modal = !this.state.showModal;
    this.setState({ showModal: modal });
  };

	createNewCustomer = async () => {
		const operatingCountry =
			(localStorage.getItem("operating-country") &&
				localStorage.getItem("operating-country")) ||
			"";

		if (operatingCountry && operatingCountry.toLowerCase() === "nl") {
			if (this.customerForm.valid) {
				if (this.state.address) {
					let payload = {
						first_name: this.customerForm.controls.firstName.value,
						last_name: this.customerForm.controls.lastName.value,
						telephone: this.customerForm.controls.telephone.value,
						email: this.customerForm.controls.email.value,
						postal_code: this.state.address.postal_code,
						address: this.state.address
					};
					this.props.createConsumersAction(payload).then((result) => {
						if (result.success) {
							this.handleCloseModal();
						}
					});
				}
			} else {
				this.setState({ errorShow: true });
			}
		} else {
			if (this.customerForm.valid && this.state.address && this.state.address.postal_code) {
				let payload = {
					first_name: this.customerForm.controls.firstName.value,
					last_name: this.customerForm.controls.lastName.value,
					telephone: this.customerForm.controls.telephone.value,
					postal_code: this.state.address.postal_code,
					email: this.customerForm.controls.email.value,
					address: this.state.address
				};
				this.props.createConsumersAction(payload).then((result) => {
					if (result.success) {
						this.handleCloseModal();
					}
				});
			} else {
				this.setState({ errorShow: true });
			}
		}
	};

	renderAddressValidationRes = () => {
		const { store } = this.props;
		if (this.state.address && this.state.validAddress) {
			return (
				<div className="d-flex">
					<i className="tim-icons icon-square-pin mr-2 text-success mt-1"/>
					<span
						className="text-success">{`${this.state.address.street}, ${this.state.address.city}, ${this.state.address.country}`}</span>
				</div>
			);
		}
		if (!this.state.address && !this.state.operatingCountry) {
			return (
				<div className="d-flex">
					<i className="tim-icons icon-square-pin mr-2 text-danger mt-1"/>
					<span className="text-danger">
            {renderTranslation(store, "step1.not-operating-country")}
          </span>
        </div>
      );
    }
  };

  loqateSearch = async (value) => {
    this.setState({
      addressValue: value,
    });
    if (value && value.length >= 2) {
      const myHeaders = new Headers();
      myHeaders.append("APP-ID", process.env.REACT_APP_ID);
      myHeaders.append("locale", localStorage.getItem("locale") || "nl");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const url =
        process.env.REACT_APP_GATEWAY +
        "/api/v1/address-international?search=" +
        value;
      try {
        const response = await fetch(url, requestOptions);

        // if (!response.ok){
        //     alert("ERROR!!");
        //     return;
        // }

        const searchResponse = await response.json();
        this.setState({ addressList: searchResponse.data });
        // console.log("SEARCH RESPONSE",searchResponse.data);
      } catch (error) {
        console.log("Error", error);
        alert("Something went wrong, please try again!!");
      }
    }
  };

  drillDown = async (singleAddress) => {
    const myHeaders = new Headers();
    myHeaders.append("APP-ID", process.env.REACT_APP_ID);
    myHeaders.append("locale", localStorage.getItem("locale") || "nl");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const url =
      process.env.REACT_APP_GATEWAY +
      "/api/v1/address-international?search=" +
      this.state.addressValue +
      "&container=" +
      singleAddress.id;
    try {
      const response = await fetch(url, requestOptions);

      // if (!response.ok){
      //     alert("ERROR!!");
      //     return;
      // }

      const searchResponse = await response.json();
      this.setState({ addressList: searchResponse.data });
      // console.log("SEARCH RESPONSE",searchResponse.data);
    } catch (error) {
      console.log("Error", error);
      alert("Something went wrong, please try again!!");
    }
  };

  findAddress = async (singleAddress) => {
    const myHeaders = new Headers();
    myHeaders.append("APP-ID", process.env.REACT_APP_ID);
    myHeaders.append("locale", localStorage.getItem("locale") || "nl");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const url =
      process.env.REACT_APP_GATEWAY +
      "/api/v1/address-international/" +
      singleAddress.id;
    try {
      const response = await fetch(url, requestOptions);

      // if (!response.ok){
      //     alert("ERROR!!");
      //     return;
      // }

      const addressResponse = await response.json();
      if (
        addressResponse.country === "Netherlands" ||
        addressResponse.country === "Germany" ||
        addressResponse.country === "Belgium"
      ) {
        this.setState({
          addressValue: singleAddress.AddressLine,
          addressList: [],
          address: addressResponse,
        });
      } else {
        this.setState({
          operatingCountry: false,
          addressList: [],
          address: null,
        });
      }
    } catch (error) {
      console.log("Error", error);
      alert("Something went wrong, please try again!!");
    }
  };

  renderDisabledState = () => {
    const operatingCountry =
      (localStorage.getItem("operating-country") &&
        localStorage.getItem("operating-country")) ||
      "";
    if (operatingCountry && operatingCountry.toLowerCase() === "nl") {
      return false;
    } else {
      if (!this.state.address && this.state.operatingCountry) return true;
      else if (!this.state.address && !this.state.operatingCountry) return true;
      else if (this.state.address && this.state.operatingCountry) return false;
    }
  };

  render() {
    const { store } = this.props;
    const operatingCountry =
      (localStorage.getItem("operating-country") &&
        localStorage.getItem("operating-country")) ||
      "";

    return (
      <div className="content customers main-content">
        <Row>
          <Col md="3">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  {renderTranslation(store, "customers.customer_filter_title")}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#">
                  <Row>
                    <Col md="12" sm="12">
                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: renderTranslation(
                              store,
                              "forms.customer_name_text"
                            ),
                            type: "text",
                            name: "name",
                            placeholder: renderTranslation(
                              store,
                              "forms.customer_name_placeholder_text"
                            ),
                            bsClass: "form-control",
                            error: null,
                            defaultValue: "",
                            onChange: this.handleFilterChange,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: renderTranslation(
                              store,
                              "forms.customer_email_text"
                            ),
                            type: "text",
                            name: "email",
                            placeholder: renderTranslation(
                              store,
                              "forms.customer_email_placeholder_text"
                            ),
                            bsClass: "form-control",
                            error: null,
                            defaultValue: "",
                            onChange: this.handleFilterChange,
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: renderTranslation(
                              store,
                              "forms.customer_phone_text"
                            ),
                            type: "text",
                            name: "telephone",
                            placeholder: renderTranslation(
                              store,
                              "forms.customer_phone_placeholder_text"
                            ),
                            bsClass: "form-control",
                            error: null,
                            defaultValue: "",
                            onChange: this.handleFilterChange,
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill btn-block"
                  color="info"
                  onClick={this.applyFilter}
                >
                  {this.state.loading
                    ? "Loading.."
                    : renderTranslation(store, "buttons.apply_filter")}
                </Button>
              </CardFooter>
            </Card>
          </Col>

          <Col md="9">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  {renderTranslation(store, "customers.page_title")}
                </CardTitle>
                <div className="add-button" style={{ float: "right" }}>
                  <Button
                    bsStyle="primary"
                    pullRight
                    fill
                    onClick={this.handleCreateCustomer}
                  >
                    <i class="fa fa-user-plus"></i>{" "}
                    {renderTranslation(store, "buttons.add_customer")}{" "}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        {renderTranslation(
                          store,
                          "customers.table_header.name"
                        )}
                      </th>
                      <th>
                        {" "}
                        {renderTranslation(
                          store,
                          "customers.table_header.email"
                        )}
                      </th>
                      <th>
                        {" "}
                        {renderTranslation(
                          store,
                          "customers.table_header.phone"
                        )}
                      </th>
                      <th>
                        {renderTranslation(
                          store,
                          "customers.table_header.status"
                        )}
                      </th>
                      <th>
                        {" "}
                        {renderTranslation(
                          store,
                          "customers.table_header.action"
                        )}
                      </th>
                    </tr>
                  </thead>
                  {this.state.data && this.state.data.length === 0 && (
                    <div className="no-found-table-text pt-2">
                      {renderTranslation(store, "message.no_consumer_found")}
                    </div>
                  )}
                  {(this.state.data && this.state.data.length) > 0 && (
                    <tbody>
                      {this.state.data.map((prop, key) => {
                        return (
                          <tr>
                            <td>{prop.name}</td>
                            <td>{prop.email}</td>
                            <td>{prop.telephone}</td>
                            <td>
                              {prop.active ? (
                                <span className="customers-active-status">
                                  {"Active"}
                                </span>
                              ) : (
                                <span className="customers-in-active-status">
                                  {" "}
                                  {"In-active"}
                                </span>
                              )}
                            </td>
                            <td>
                              <Button
                                bsStyle="primary"
                                round
                                onClick={(e) => this.viewProfile(prop.uuid)}
                              >
                                {renderTranslation(store, "buttons.view")}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </CardBody>
              <CardFooter>
                {this.state.meta && (
                  <Pagination
                    meta={this.state.meta}
                    onPaginate={this.handlePaginate}
                  />
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>

        {/* Start Customer modal box */}
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          onHide={this.handleCloseModal}
        >
          <ModalHeader toggle={this.toggleModal}>
            {this.state.users
              ? renderTranslation(store, "customers.update_text")
              : renderTranslation(store, "customers.create_text")}
          </ModalHeader>
          <ModalBody>
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.first_name_text"),
                  type: "text",
                  name: "firstName",
                  placeholder: renderTranslation(
                    store,
                    "forms.first_name_placeholder_text"
                  ),
                  bsClass: "form-control",
                  error: this.errorMassage(
                    renderTranslation(store, "forms.first_name_text"),
                    this.customerForm.controls["firstName"].errors
                  ),
                  defaultValue: this.customerForm.controls.firstName.value,
                  onChange: this.handleUserInput,
                },
                {
                  label: renderTranslation(store, "forms.last_name_text"),
                  type: "text",
                  name: "lastName",
                  bsClass: "form-control",
                  placeholder: renderTranslation(
                    store,
                    "forms.last_name_placeholder_text"
                  ),
                  defaultValue: this.customerForm.controls.lastName.value,
                  error: this.errorMassage(
                    renderTranslation(store, "forms.last_name_text"),
                    this.customerForm.controls["lastName"].errors
                  ),
                  onChange: this.handleUserInput,
                },
              ]}
            />

						<FormInputs
							ncols={["col-md-6", "col-md-6"]}
							proprieties={[
								{
									label: renderTranslation(store, "forms.email_text"),
									type: "email",
									name: "email",
									placeholder: renderTranslation(
										store,
										"forms.email_placeholder_text"
									),
									bsClass: "form-control",
									error: this.errorMassage(
										renderTranslation(store, "forms.email_text"),
										this.customerForm.controls["email"].errors
									),
									defaultValue: this.customerForm.controls.email.value,
									onChange: this.handleUserInput,
								},
								{
									label: renderTranslation(store, "forms.phone_no_text"),
									type: "text",
									name: "telephone",
									bsClass: "form-control",
									placeholder: renderTranslation(
										store,
										"forms.phone_no_placeholder_text"
									),
									defaultValue: this.customerForm.controls.telephone.value,
									error: this.errorMassage(
										renderTranslation(store, "forms.phone_no_text"),
										this.customerForm.controls["telephone"].errors
									),
									onChange: this.handleUserInput,
								},
							]}
						/>
						<hr/>
						<Label>
							{renderTranslation(
								store,
								"profile.customers_profile_address_tab_title"
							)}</Label>
						<AddressSelection
              sessionId={this.state.sessionId}
							handleSetAddress={(address) => this.setState((prev) => ({ ...prev, address }))}
							handleSetAddressList={(addressList) => this.setState((prev) => ({ ...prev, addressList }))}
							handleSetAddressValue={(addressValue) => this.setState((prev) => ({ ...prev, addressValue }))} />
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button
							disabled={this.renderDisabledState()}
							bsStyle="info"
							pullRight
							fill
							onClick={this.createNewCustomer}
						>
							{renderTranslation(store, "buttons.submit")}
						</Button>
					</ModalFooter>
				</Modal>
				{/* End Customer modal box */}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    appointments: state.customer.appointments,
    store: state,
  };
};

const mapDispatchToProps = {
  fetchConsumersAction: FetchConsumersAction,
  createConsumersAction: CreateConsumersAction,
  verifyAddressAction: VerifyAddressAction,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Consumers)
);
