import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

class CustomerProfileService {
 
  http = "";
  constructor() {
   
    this.http = httpHandler(
      baseEndpoint.partnersServiceBaseEndPoint);
  }

  fetchCustomers(params = null, page = null) {
    return this.http.post(
      apiUrls.profile.fetchAllConsumer(
        (params && page)
          ? `?filter[${params.key}]=${params.value}&page=${page}`
          : (page && params === null) ?  `?page=${page}` : "" 
      )
    );
 }

 fetchSingleCustomer(id) {
   return this.http.post(apiUrls.profile.fetchAllConsumer(`?filter[uuid]=${id}`));
 }

 updateCustomerDetail(id , data) {
    return this.http.put(apiUrls.profile.updateCustomer(id), data);
 }

 verifyCustomerDetail(data) {
    return this.http.post(apiUrls.verify.verifyAddress, data)
 }

 updateCustomerAddress(id , body) {
     return this.http.put(apiUrls.profile.updateCustomerAddress(id), body)
 }

 fetchCustomerAppointments( data ) {
   let urlParameters = Object.entries(data).map(e => e.join('=')).join('&');
   return this.http.get(`${apiUrls.feed.feed}?${urlParameters}`);

 }
}

export const customerProfileService = new CustomerProfileService();