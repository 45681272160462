import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import Ticker from 'react-ticker'
import AliceCarousel from 'react-alice-carousel';
import "../../styles/appointments.scss";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip
} from "reactstrap";
import * as moment from "moment-timezone";
import * as newMoment from "moment";
import { FetchTasksAction, FetchAppointmentImagesAction } from "../../actions"
import Status from "../../../shared/Appointment/Status/Status.jsx"
import { CREATED, INVOICED } from "modules/Appointments/constants/StatusConstants";
import { Button } from "react-bootstrap";
import { renderTranslation } from "services";
class AppointmentInfo extends Component {


  constructor(props) {
    super(props);
    this.state = {
      moveWidth: 12,
      activeTab: "info",
      taskList: [],
      comment: "",
      editAppointmentServiceRateShow: false,
      selectedEditRate: "",
      selectedEditServiceRate: "",
      editAppointmentTaskShow: false,
      selectedTask: "",
      hasInvoice: false,
      showOptionModal: false,
      textCopied: false,
      selectedAppointmentImages: [],
      latestEventLog: null,
      openTime: null,
      dragging: false
    };
  }
   changeTab = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  handleDragStart = (e) => {
    e.preventDefault();
    this.setState({dragging: true})
  }

  handleDragEnd = (e) => {
    setTimeout(() => {
      this.setState({dragging: false})
    }, 100);
  }

  openImage = (url) => {
    if(!this.state.dragging) {
      window.open(url, '_blank').focus();
    }
  }

  fetchAppointmentImages(appointmentId) {
    let payload = {
      "type": ["Appointment"],
      "ref_id": appointmentId
    }

    this.props.fetchAppointmentImagesAction(payload).then((resp) => {
      if (resp.success) {
        let images = resp.data.map((image) => {
          const style = { width: 150 };
          const size = { fontSize: '3rem'}
          const center = { display: 'flex', flexDirection: 'column', alignItems: 'center', width: 150 }
          if (image.extension === 'pdf') {
            return (<a href={image.url}
              style={center}
              target="_blank"
              onDragStart={this.handleDragStart}
              onDragEnd={this.handleDragEnd}
              onMouseUp={this.handleDragEnd}
            ><i style={size} className="tim-icons icon-paper"></i><br/>{image.title}</a>)
          }
          return (<img src={image.url}
                    style={style}
                    onClick={() => this.openImage(image.url)}
                    onDragStart={this.handleDragStart}
                    onDragEnd={this.handleDragEnd}
                    onMouseUp={this.handleDragEnd}
                  />
          )
        })

        this.setState({ selectedAppointmentImages: images })
      }
    })
  }

  handleChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  componentDidMount() {
    if (this.props.appointment) {
      this.fetchAppointmentImages(this.props.appointment.uuid)
      this.setWidthSetting(this.props.appointment);
      this.getOpenTimeOfJob();
    }

    this.fetchAllTask();
  }

  setWidthSetting(appointment) {
    if (appointment.dynamicFields && appointment.dynamicFields.length > 0) {
      this.setState({ moveWidth: 6 });
    }
  }

  fetchAllTask = () => {
    this.props.fetchTasksAction().then((result) => {
      if (this.props?.bare_minimum_tasks?.length > 0) {
        const arr = [];
        this.props.bare_minimum_tasks.map((task, index) => {
          const obj = {};
          obj.value = task.id.toString();
          obj.label = task.singular[0].toUpperCase() + task.singular.slice(1);

          arr.push(obj);
          return null;
        });
        this.setState({
          taskList: arr,
        });
      }
    });
  };



  handleChangeEdit = (e) => {
    switch (e.target.name) {
      case "selectedEditRate":
        this.setState({ selectedEditRate: e.target.value })
        break;
      case "selectedEditServiceRate":
        this.setState({ selectedEditServiceRate: e.target.value })
        break;

    }
  };

  getDropdownList = (data, label) => {
    if (data) {
      let result = [
        {
          value: "",
          label: label,
          isDisabled: true,
        },
      ];

      data.map((item) => {
        result.push(item);
        return item;
      });
      return result;
    }
  };

  showOption = () => {
    this.setState({ showOptionModal: true });
  }

  toggleModal = () => {
    let modal = !this.state.showOptionModal;
    this.setState({ showOptionModal: modal });
  }



  handleCloseModal = () => {
    this.setState({ showOptionModal: false });
  };

  showSingleCustomer = (id) => {
    this.props.history.push(`/admin/consumer-profile/${id}`);
  }

  copyText(text) {
    this.setState({ textCopied: true })
    setTimeout(() => {
      this.setState({ textCopied: false })
    }, 1000)
    navigator.clipboard.writeText(text)
  }
  responsive = {
    0: { items: 3 },
    1024: { items: 4 },
  }

  handleInvoice(appointmentId) {
      const win = window.open(`/#/invoice/${appointmentId}`, "_blank");
      win.focus()
  }

  getOpenTimeOfJob() {
    if (this.props.appointment.accepted_at) {
      let ms = moment(moment(this.props.appointment.accepted_at), "DD/MM/YYYY HH:mm:ss").diff(moment(moment(this.props.appointment.created_at), "DD/MM/YYYY HH:mm:ss"));
      let d = moment.duration(ms);
      let time = Math.floor(d.asHours()) + ' uur ' + moment.utc(ms).format("mm ") + "Minuten";
      this.setState({ openTime: time })
    }
  }

  getAcceptedOption() {
    if (this.props.appointment.option_id) {
      const acceptedOption = this.props.appointment.options.filter((option) => {
        return option.id === this.props.appointment.option_id;
      });
  
      if (!acceptedOption.length) {
        return null;
      }
  
      // If datetime_start_zulu doesn't exist, format the datetime in 'Europe/Amsterdam' timezone
      if (!acceptedOption[0].datetime_start_zulu) {
        return `${moment(acceptedOption[0].datetime_start, 'DD-M-YYYY HH:mm')
          .tz('Europe/Amsterdam')
          .format('dd DD MMM . HH:mm')} - ${moment(acceptedOption[0].datetime_end, 'DD-M-YYYY HH:mm')
          .tz('Europe/Amsterdam')
          .format('HH:mm')} (€${acceptedOption[0].rate})`;
      } else {
        // Convert datetime_start_zulu and datetime_end_zulu from UTC+0 to the device's local timezone
        const deviceTimezone = moment.tz.guess(); // Get device's timezone
  
        const startLocal = moment
          .tz(acceptedOption[0].datetime_start_zulu, 'YYYY-MM-DD HH:mm:ss', 'UTC') // Convert from UTC
          .tz(deviceTimezone) // Convert to local timezone
          .format('dd DD MMM . HH:mm'); // Format like before
  
        const endLocal = moment
          .tz(acceptedOption[0].datetime_end_zulu, 'YYYY-MM-DD HH:mm:ss', 'UTC') // Convert from UTC
          .tz(deviceTimezone) // Convert to local timezone
          .format('HH:mm'); // Format like before
  
        return `${startLocal} - ${endLocal} (€${acceptedOption[0].rate})`;
      }
    }
  }

  displayOption (option) {
    if(!option.datetime_start_zulu && !option.datetime_end_zulu){
        newMoment.locale(localStorage.getItem('locale'));
      let startTime = newMoment(option.datetime_start, 'DD-MM-YYYY HH:mm');
      let endTime = newMoment(option.datetime_end, 'DD-MM-YYYY HH:mm');

      startTime = startTime.add(startTime.hour() % 2 === 0 ? 0 : 1, 'hour');
      endTime = endTime.add(endTime.hour() === 20 ? 1 : (endTime.hour() % 2 === 0 ? 0 : 1), 'hour');

      return `${startTime.format('dd D MMM . HH:00')} - ${endTime.format('HH:00')}`;
    }else{
      // Get the user's local timezone
      const localTimezone = moment.tz.guess();

      // Parse start and end times as UTC and convert to local time
      let startTime = moment.utc(option.datetime_start_zulu).tz(localTimezone);
      let endTime = moment.utc(option.datetime_end_zulu).tz(localTimezone);

      // Return formatted local time
      return `${startTime.format('dd D MMM HH:00')} - ${endTime.format('HH:00')}`;
    }
    
  }

  render() {
    const { store } = this.props;
    const dateToCompare = "2022/05/18";
    const appointmentOptions =  this.props.appointment.options.map((option, index) => {
      const currentDate = newMoment(newMoment(option.created_at, 'YYYY-M-DD')).format('YYYY/MM/DD');
      const isBeforeDate = newMoment(currentDate).isBefore(dateToCompare);
      if (isBeforeDate){
        moment.tz.setDefault('UTC');
        return (<div className="appointment-option-block">
            <div><i className="tim-icons icon-calendar-60"></i> Optie {index + 1}</div>
            <div>{`${this.displayOption(option)} (€${option.rate})`}</div>
          </div>
        )
      }
      else{
        return(<div className="appointment-option-block">
        <div><i className="tim-icons icon-calendar-60"></i> Optie {index + 1}</div>
        <div>{`${this.displayOption(option)} (€${option.rate})`}</div>
      </div>
        )}
    });

    return (
      <div className="appointment-info">
        <div className="appointment-info-header">
          <div>
            {this.props.appointment.status === CREATED && (
              <div>
                <div className={this.props.appointment.scanned_in_range === 0 ? "text-info" : "text-success"}>
                  <i className="tim-icons icon-square-pin pr-1">
                  </i>
                  {this.props.appointment.scanned_in_range > 1 ? this.props.appointment.scanned_in_range + " " +renderTranslation(store, 'appointments.info.craftsmen_in_radius') : ""}
                  {this.props.appointment.scanned_in_range === 0 ? renderTranslation(store, 'appointments.info.no_craftsman_in_radius') : ""}
                  {this.props.appointment.scanned_in_range === 1 ? this.props.appointment.scanned_in_range + " " + renderTranslation(store, 'appointments.info.craftsman_in_radius')  : ""}
                </div>
              </div>
            )}

            {this.props.appointment.status === INVOICED && (
              <div>
              <div onClick={()=> this.handleInvoice(this.props.appointment.uuid)} className="btn btn-default appointment-invoice">
               <i className="tim-icons icon-notes"></i>
               <span className="pl-1">View Invoice</span>
            </div>
            {/* <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
               <div onClick={handlePrint} className="btn btn-default appointment-invoice">
               <i className="tim-icons icon-notes"></i>
               <span className="pl-1">View Invoice</span>
            </div>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
        <PrintInvoice appointment={this.props.appointment} ref={el => (this.componentRef = el)}  />

              */}
              </div>

            )}

          </div>
          <div>
            <Status status={this.props.appointment.status} />
          </div>
        </div>
        <div>

        </div>
        {/*
        TODO: NEED SOME DISCUSSION ON TICKER
          {(this.props.appointment.event_logs && this.props.appointment.event_logs.length > 0 && this.state.latestEventLog) &&
          (
            <div class="latest-notification">
            <Ticker speed="4">
            {({ index }) => (
                <>
                    <h6>

                      {
                      this.state.latestEventLog
                      }
                    </h6>

                </>
            )}
        </Ticker>
        </div>
          )} */}


        <Row>
          <Col md={8} className={"mt-3 text-center " + this.state.moveWidth === 6 ? "left-border" : " "}>
            <div>
              <h4>{renderTranslation(store, 'appointments.info.info_text')}</h4>
              <div className="item-block">
                <div> {renderTranslation(store, 'appointments.info.job_text')}</div>
                <div>{this.props.appointment.task.singular} </div>
              </div>


              <div className="item-block">
                <div> {renderTranslation(store, 'appointments.info.rate_text')}</div>
                <div>{this.props.appointment.rate}</div>
              </div>

              <div className="item-block">
                <div> {renderTranslation(store, 'appointments.info.service_jobs_text')}</div>
                <div>{this.props.appointment.service_fee} </div>
              </div>

              {this.props.appointment.insurance_id && (
                <div className="item-block">
                <div> {renderTranslation(store, 'appointments.info.warranty_package')}</div>
                <div>{this.props.appointment.insurance_fee} </div>
              </div>
              )}


              <div className="item-block">
                <div>{renderTranslation(store, 'appointments.info.description_job')}</div>
                <div>{this.props.appointment.comments && this.props.appointment.comments[0] ? this.props.appointment.comments[0].text : ""}</div>
              </div>

              <div className="item-block">
                <div> {renderTranslation(store, 'appointments.info.requested_on_text')}</div>
                <div>{moment(this.props.appointment.created_at).format('dd DD MMM . HH:mm')} <span onClick={() => this.setState({ 'showOptionModal': true })} className="btn btn-sm btn-warning view-option-btn"> {renderTranslation(store, 'buttons.view_option_text')} <i className="fa fa-eye"></i></span> </div>
              </div>
              {this.props.appointment.accepted_at && (

                <div>
                  <hr />
                  <div className="item-block">
                    <div> {renderTranslation(store, 'appointments.info.accepted_at_text')} </div>
                    <div>{moment(this.props.appointment.accepted_at).format('dd DD MMM . HH:mm')} </div>
                  </div>

                  {this.props.appointment.option_id && (
                     <div className="item-block">
                    <div> {renderTranslation(store, 'appointments.info.accepted_option_text')} </div>
                    <div>{this.getAcceptedOption()} </div>
                  </div>
                  )}



                  <div className="item-block">
                    <div> {renderTranslation(store, 'appointments.info.open_time_text')} </div>
                    <div>
                      {this.state.openTime}
                    </div>
                  </div>
                <hr/>
                </div>

              )}

            </div>
          </Col>

          <Col md={4} className="mt-3 text-left">
            <div className="customer-info-block">
              {this.state.textCopied && (<div className="text-success"><i className="tim-icons icon-check-2"></i>Text Copied</div>)}
              <h5>{renderTranslation(store, 'appointments.info.customer_info_text')} <i id="tooltip-edit-content" className="tim-icons icon-pencil edit" onClick={() => this.showSingleCustomer(this.props.appointment.customer.uuid)}></i></h5>
              <UncontrolledTooltip delay={0} target="tooltip-edit-content">
              {renderTranslation(store, 'appointments.info.edit_client_text')}
        </UncontrolledTooltip>
              <div className="item-block">
                <div className="pr-1"><i className="tim-icons icon-single-02"></i></div>
                <div>{this.props.appointment.customer.last_name}</div>
              </div>
              <div className="item-block cursor-pointer" onClick={() => this.copyText(this.props.appointment.customer.telephone)}>
                <div className="pr-1"><i className="fa fa-phone"></i></div>
                <div>{this.props.appointment.customer.telephone} </div>
              </div>
              <div className="item-block cursor-pointer" onClick={() => this.copyText(this.props.appointment.customer.email)}>
                <div className="pr-1"><i className="tim-icons icon-email-85"></i></div>
                <div className="dont-break-out">{this.props.appointment.customer.email}</div>
              </div>
              <div className="item-block" >
                <div className="pr-1"> <i className="tim-icons icon-square-pin"></i> </div>
                <div className="location-block">{` ${this.props.appointment.customer.address.street} ${this.props.appointment.customer.address.suffix}, ${this.props.appointment.customer.address.postal_code}, ${this.props.appointment.customer.address.city}`} </div>
              </div>
            </div>
          </Col>

        </Row>
        <Row>
          <Col md={12}>
            <div className="mt-2">
              <h5>{renderTranslation(store, 'appointments.more_information_text')}</h5>
              {(this.props.appointment.dynamicFields && this.props.appointment.dynamicFields.length === 0) && (
                <span>{renderTranslation(store, 'appointments.no_information_available_text')}</span>
              )}
              {
                this.props.appointment.dynamicFields.map((dynamic) => {
                  return (
                    <div className="item-block">
                      <div> {dynamic.field}</div>
                      <div>{dynamic.values.toString()}</div>
                    </div>
                  )
                })
              }
            </div>

          </Col>
        </Row>
        {(this.state.selectedAppointmentImages && this.state.selectedAppointmentImages.length > 0) &&
          (
            <Row>
              <Col>
                <AliceCarousel
                  items={this.state.selectedAppointmentImages}
                  responsive={this.responsive}
                  mouseTracking
                  autoWidth
                />
              </Col>
            </Row>
          )}


        <Modal className="appointment-info-modal-box" isOpen={this.state.showOptionModal} toggle={this.toggleModal} onHide={this.handleCloseModal}>
          <ModalHeader toggle={this.toggleModal}>

            {renderTranslation(store, 'appointments.info.job_text')} <b className="text-info">{this.props.appointment.task.singular}</b>
            <br /> {renderTranslation(store, 'appointments.info.requested_on_text').toLowerCase()} <b className="text-info">{moment(this.props.appointment.created_at).format('DD MMM . HH:mm')}</b>
            <br /> {renderTranslation(store, 'appointments.with_requested_option')}
          </ModalHeader>
          <ModalBody>

            {appointmentOptions}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bare_minimum_tasks: state.tasks_bare_minimum.tasks_bare_minimum.tasks,
    store: state
  };
};

const mapDispatchToProps = {
  fetchTasksAction: FetchTasksAction,
  fetchAppointmentImagesAction: FetchAppointmentImagesAction
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentInfo)
);
