const initialState = {
  settings: {
    categories: [],
    rateTypes: [],
    profileStatus: [],
    feeRates: [],
    jobSources: [],
    insurance: null
  }
};

const setAppSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APP_SETTINGS':
      return { ...state, settings: action.payload };
    case 'CLEAR_APP_SETTINGS':
      return { ...state, ...initialState };
    case 'GET_APP_SETTINGS':
      return state[action.type];
    default:
      return state;
  }
};

export default setAppSettings;
