import React, { Component } from "react";
import { connect } from "react-redux";
import { FetchSubUsersAction, CreateSubUsersAction } from "../actions";
import { errorAlertBox , successAlertBox } from "../../../services";

// import { Table, Grid, Row, Col } from "react-bootstrap";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import "../styles/subUser.scss";
// import Card from "../../../components/Card/Card.jsx";
// import Button from "../../../components/CustomButton/CustomButton.jsx";
import FormInputs from "../../shared/FormInputs/FormInputs.jsx";
import Pagination from "../../shared/Pagination/Pagination.jsx";
import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { renderTranslation } from "services";
/**
 * - listing of all customers
 * - filter/searching of specific customer
 */
class Consumers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      data: [],
      activeUser: null,
      meta: {
        page: 0,
      },
      loading: false,
      showModal: false,
      validAddress: true,
      responseErrors: []
    };
    this.createInput();
    document.title = `${renderTranslation(this.props.store, 'appointments.table.customer_text')} | Zoofy`
  }

	validatemail = (email) => {
		let validate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return validate.test(email);
	}

  createInput() {
    this.subUserForm = new Group({
      firstName: new Control(null),
      lastName: new Control(null, [Validators.required]),
      email: new Control(null, [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]),
      telephone: new Control(null, [Validators.required, Validators.pattern('^(\\+\\d{1,3}[- ]?)?\\d{10}$')]),
      password: new Control(null, [Validators.required, Validators.minLength(6)])
    });
  }

  componentWillMount() {
    this.fetchSubUsers();
  }

  /**
   * Fetch all customers
   * @param {array} params
   * @param {int|null} page
   */
  fetchSubUsers = (params = [], page = null) => {
    let payload = {
      params: params,
      page: page,
    };
    this.setState({ loading: true })
    this.props.fetchSubUsersAction(payload).then((customers) => {
      this.setState({ loading: false })
      if (customers.success) {
        const data = customers.data.data;
        this.setState({ data });
        this.normalizeMetaTag(customers.data);
      }
    });
  };

  /**
   * Format meta tags object
   * @param {object} customers
   */
  normalizeMetaTag = (customers) => {
    const meta = { ...customers };
    delete meta.data;
    this.setState({ meta });
  };

  handleFilterChange = (e) => {
    let filters = this.state.filters;
    if (filters.findIndex(i => i.key === e.target.name) !== -1) {
      filters[filters.findIndex(i => i.key === e.target.name)].value = e.target.value
    } else {
      filters.push({ key: e.target.name, value: e.target.value })
    }
    this.setState({ filters })
  };

  applyFilter = () => {
    if (this.state.filters.length > 0) {
      this.fetchSubUsers(this.state.filters, null);
    }
  };

  /**
   * show single customer profile
   * @param {string} id
   */
  viewSubuser = (id) => {
    this.props.history.push(`/admin/consumer-profile/${id}`)
  }

  /**
   * handle pagination to show next or previous page customer records
   * @param {string} direction
   */

  handlePaginate = (page) => {
    this.fetchSubUsers(this.state.filters, page)
  }

  handleCreateCustomer = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    this.fetchSubUsers();
  };

  errorMassage = (label, errors) => {
    const { store } = this.props;

    if (this.state.dynamicError) {
      if (errors?.minLength?.actualLength < errors?.minLength?.requiredLength && this.state.errorType === 'password' && this.state.errorShow) {
        return renderTranslation(store, "errors.minLength", { title: errors.minLength.requiredLength });
      } else {
        return null;
      }
    }

    if (errors && this.state.errorShow) {
      if (errors.required) {
        return renderTranslation(store, "errors.required", { title: label });
      }
      if (errors.pattern) {
        return renderTranslation(store, "errors.format", { title: label });
      }
      if (errors.minLength) {
        return renderTranslation(store, "errors.minLength", { title: errors.minLength.requiredLength });
      }
    }
    return null;
  };

  handleUserInput = (event) => {
    const target = event.target;
    this.subUserForm.controls[`${target.name}`].setValue(
      target.value
    );

    this.setState({ dynamicError: true, errorType: `${target.name}`});
  };

  toggleModal = () => {
    let modal = !this.state.showModal;
    this.setState({ showModal: modal });
  }

  createNewSubUser = async () => {
    if (this.subUserForm.valid) {
        let payload = {
          name: `${this.subUserForm.controls.firstName.value} ${this.subUserForm.controls.lastName.value}`,
          phone_number: this.subUserForm.controls.telephone.value,
          email: this.subUserForm.controls.email.value,
          password: this.subUserForm.controls.password.value,
          country_code: "0031",
          role_id: 6
        }

        this.props.createSubUsersAction(payload).then((result) => {
          if(result.success) {
            this.handleCloseModal();
            successAlertBox(result.data.message)
          } else {
            this.setState({ responseError: result.error });
          }
        })

    } else {
      this.setState({ errorShow: true, submit: true, dynamicError: false });
    }
  };

  render() {
    const { store } = this.props;
    return (
      <div className="content sub-users main-content">
        <Row>
          {/* <Col md="3">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{renderTranslation(store, "subuser.subuser_filter_title")}</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#">
                  <Row>
                    <Col md="12" sm="12" >
                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: renderTranslation(store, "forms.subuser_name"),
                            type: "text",
                            name: "name",
                            placeholder: renderTranslation(store,
                              "forms.subuser_search_name"
                            ),
                            bsClass: "form-control",
                            error: null,
                            defaultValue: "",
                            onChange: this.handleFilterChange
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: renderTranslation(store, "forms.subuser_email"),
                            type: "text",
                            name: "email",
                            placeholder: renderTranslation(store,
                              "forms.subuser_search_email"
                            ),
                            bsClass: "form-control",
                            error: null,
                            defaultValue: "",
                            onChange: this.handleFilterChange
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: renderTranslation(store, "forms.subuser_phone"),
                            type: "text",
                            name: "telephone",
                            placeholder: renderTranslation(store,
                              "forms.subuser_search_phone"
                            ),
                            bsClass: "form-control",
                            error: null,
                            defaultValue: "",
                            onChange: this.handleFilterChange
                          },
                        ]}
                      />

                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill btn-block"
                  color="info"
                  onClick={this.applyFilter}
                >
                  {this.state.loading ? "Loading.." : renderTranslation(store, "buttons.apply_filter")}
                </Button>

              </CardFooter>
            </Card>
          </Col> */}

          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{renderTranslation(store, "subusers.page_title")}</CardTitle>
                <div className="add-button" style={{ float: "right" }}>
                  <Button bsStyle="primary" pullRight fill   onClick={this.handleCreateCustomer}>
                    <i class="fa fa-user-plus"></i>{" "}
                    {renderTranslation(store, "buttons.add_subuser")}{" "}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th> {renderTranslation(store, "subusers.table_header.name")}</th>
                      <th> {renderTranslation(store, "subusers.table_header.email")}</th>
                      <th> {renderTranslation(store, "subusers.table_header.phone")}</th>
                      {/* <th> {renderTranslation(store, "subusers.table_header.action")}</th> */}
                    </tr>
                  </thead>
                  {(this.state.data && this.state.data.length === 0) && (
                      <div className="no-found-table-text pt-2">
                          {renderTranslation(store, "message.no_subuser_found")}
                      </div>
                  )}
                  {(this.state.data && this.state.data.length) > 0 && (
                    <tbody>

                      {this.state.data.map((prop, key) => {
                        return (
                          <tr>
                            <td>{prop.name}</td>
                            <td>{prop.email}</td>
                            <td>{prop.phone_number}</td>
                            {/* <td>
                              <Button bsStyle="primary" round onClick={(e) => this.viewSubuser(prop.uuid)}>
                                {renderTranslation(store, "buttons.view")}
                              </Button>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </CardBody>
              <CardFooter>
                {this.state.meta && <Pagination meta={this.state.meta} onPaginate={this.handlePaginate} />}
              </CardFooter>
            </Card>
          </Col>
        </Row>

        {/* Start Customer modal box */}
        <Modal isOpen={this.state.showModal} toggle={this.toggleModal} onHide={this.handleCloseModal}>
          <ModalHeader toggle={this.toggleModal}>
              {this.state.activeUser
                ? renderTranslation(store, "subuser.update_text")
                : renderTranslation(store, "subuser.create_text")}
          </ModalHeader>
          <ModalBody>
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.first_name_text"),
                  type: "text",
                  name: "firstName",
                  placeholder: renderTranslation(store, "forms.first_name_placeholder_text"),
                  bsClass: "form-control",
                  error: this.errorMassage(
                    renderTranslation(store, "forms.first_name_text"),
                    this.subUserForm.controls["firstName"]
                      .errors
                  ),
                  defaultValue: this.subUserForm.controls
                    .firstName.value,
                  onChange: this.handleUserInput,
                },
                {
                  label: renderTranslation(store, "forms.last_name_text"),
                  type: "text",
                  name: "lastName",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.last_name_placeholder_text"),
                  defaultValue: this.subUserForm.controls
                    .lastName.value,
                  error: this.errorMassage(
                    renderTranslation(store, "forms.last_name_text"),
                    this.subUserForm.controls["lastName"]
                      .errors
                  ),
                  onChange: this.handleUserInput,
                },
              ]}
            />
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.email_text"),
                  type: "email",
                  name: "email",
                  placeholder: renderTranslation(store, "forms.email_placeholder_text"),
                  bsClass: "form-control",
                  error: Array.isArray(this.state.responseError) ?
	                  this.state.responseError.map((el) => el.key === 'email' && el.value) :
	                  (this.errorMassage(renderTranslation(store, "forms.email_text"),
                    this.subUserForm.controls["email"].errors)
                  ),
                  defaultValue: this.subUserForm.controls
                    .email.value,
                  onChange: this.handleUserInput,
                },
                {
                  label: renderTranslation(store, "forms.phone_no_text"),
                  type: "text",
                  name: "telephone",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.phone_no_placeholder_text"),
                  defaultValue: this.subUserForm.controls
                    .telephone.value,
                  error: Array.isArray(this.state.responseError) ?
	                  this.state.responseError?.map((el) => el.key === 'phone_number' && el.value) :
	                  (this.errorMassage(renderTranslation(store, "forms.phone_no_text"),
                      this.subUserForm.controls["telephone"].errors)),
                  onChange: this.handleUserInput,
                },
              ]}
            />
             <FormInputs
              ncols={["col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.password_text"),
                  type: "password",
                  name: "password",
                  placeholder: renderTranslation(store, "forms.password_placeholder_text"),
                  bsClass: "form-control",
                  error: this.errorMassage(
                    renderTranslation(store, "forms.password_text"),
                    this.subUserForm.controls["password"].errors
                  ),
                  defaultValue: this.subUserForm.controls
                    .password.value,
                  onChange: this.handleUserInput,
                }
              ]}
            />
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              bsStyle="info"
              pullRight
              fill
              onClick={this.createNewSubUser}
            >
              {renderTranslation(store, "buttons.submit")}
            </Button>
          </ModalFooter>
        </Modal>
        {/* End Customer modal box */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appointments: state.customer.appointments,
    store: state
  };
};

const mapDispatchToProps = {
  fetchSubUsersAction: FetchSubUsersAction,
  createSubUsersAction: CreateSubUsersAction
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Consumers)
);
