import axios from "axios";
import qs from "qs";
import { errorAlertBox } from "../services";
import { loginService } from "../modules/authentication/services";
import i18n from "../i18n";

/**
 * parse error response
 * @param string messages
 */
const parseError = (messages) => {
  errorAlertBox(
    messages ? messages : 'Something went wrong, Please try again'
  );
  // error
  if (messages) {
    return Promise.reject(messages);
  } else {
    return Promise.reject('Something went wrong, Please try again');
  }
};

/**
 * parse response
 * @param {object|null} response
 */
const parseBody = (response) => {
  if (response.status === 200 || response.status === 201) {
    return response.data;
  } else {
    return parseError(response.data.message);
  }
};

const http = (
  baseUrl,
  token = null,
  isAppId = true,
  isMediaServiceKey = null,
  locale = 'nl'
) => {
  let instance = axios.create({
    baseURL: baseUrl,
    paramsSerializer: function (params) {
      return qs.stringify(params, { indices: false });
    },
    timeout: 360000,
  });

  /*****************************Start Request Handler of Axios (interceptors)***************************************/
  instance.interceptors.request.use(
    (config) => {
      config.headers["locale"] = i18n.language;
      if (isMediaServiceKey) {
        config.headers["SERVICE-KEY"] = process.env.REACT_APP_MEDIA_SERVICE_KEY;
      }

      if (isAppId) {
        config.headers["App-ID"] = process.env.REACT_APP_ID;
      }
      if (localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY)) {
        config.headers["Authorization"] = token
          ? `Bearer ${token}`
          : JSON.parse(
              localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY)
            ).token;
      }
      if (localStorage.getItem('operating-country')) {
        config.headers["operating-country"] = localStorage.getItem('operating-country');
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /*****************************End Request Handler of Axios (interceptors)***************************************/

	/*****************************Start Response Handler of Axios (interceptors)***********************************/
	instance.interceptors.response.use(
		(response) => {
			return parseBody(response);
		},
		async (error) => {

			if (error.hasOwnProperty('response')) {
			if (error.response.hasOwnProperty('data') &&
				error.response.hasOwnProperty('errors') &&
				error.response.data.errors.length > 0) {
				return errorAlertBox(error.response.data.errors[0].error)
			}

			if (error.response.status === 401) {
				const isRefreshProcessRunning = await loginService.getRefreshTokenProcess();
				if (isRefreshProcessRunning) {
					loginService.logout();
					loginService.setRefreshTokenProcess(false);
					return;
				} else {
					await loginService.refreshToken();
					loginService.setRefreshTokenProcess(true);
					window.location.reload();
				}
			}


				let errorMessage =
					(error.response.data && error.response.data.message)
						? error.response.data.message
						: (error.response.data && error.response.data.error)
							? error.response.data.error
							: 'Something went wrong, Please try again';

				if (error.response.hasOwnProperty('data') &&
					error.response.data.hasOwnProperty('data') &&
					error.response.data.data.hasOwnProperty('message')) {
					errorAlertBox(error.response.data.data.message);
					return Promise.reject(error.response.data.data.message);
				}

					if (error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('data') && error.response.data.data.length > 0) {
						error?.response?.data?.message && errorAlertBox(error.response.data.message);

						error.response.data.data.map( async (error, index) => {
						errorAlertBox(error.value);
					})
					return Promise.reject(error.response.data.data);
				}

				if (error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('error') && error.response.data.error) {
					if (typeof error.response.data.error === 'string') return errorAlertBox(error.response.data.error);
					if (error.response.data.error.hasOwnProperty('message')) return errorAlertBox(error.response.data.error.message);

					for (const [key, value] of Object.entries(error.response.data.error)) {
						errorAlertBox(value[0])
					}
					return Promise.reject(error.response.data.error);
				}
					return parseError(errorMessage);
			} else {
				return Promise.reject(error);
			}
		}
	);
	/*****************************End Response Handler of Axios (interceptors)***********************************/

  return instance;
};

export const httpHandler = http;
