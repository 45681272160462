import React, { Component } from "react";
import { Container } from "reactstrap";
import CopyRightText from "../Footer/CopyRightText.jsx"

class Footer extends Component {
  render() {
    return (

      <footer className={"footer" + (this.props.default ? " footer-default" : "")}>
        <Container fluid={this.props.fluid ? true : false}>
          <div className="copyright">
            <CopyRightText />
          </div>

        </Container>
      </footer>

    );
  }
}
export default
  Footer;
