import React, { Component } from "react";

import UserCard from "../../../shared/Card/UserCard.jsx";
import avatar from "assets/img/default.png";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { mediaBaseUrl } from "../../../../services";
import i18n from "../../../../i18n";
import { renderTranslation } from "services/renderTranslation.service.js";
/**
 * show single customer detail as a user box
 *  - showing images of customer
 *  - showing name of customer
 * - showing phone of customer
 * - showing country of customer
 * - showing total appointments of customer 
 */
class CustomerInformationBox extends Component {
  render() {
    const { store } = this.props;
    if (this.props.profileOverview) {
      return (
        <UserCard
          className="profile-information-box"
          bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
          avatar={
            this.props.profileOverview.avatar_path &&
            this.props.profileOverview.avatar_path !== ""
              ? mediaBaseUrl + this.props.profileOverview.avatar_path
              : avatar
          }
          name={
            this.props.profileOverview.first_name !== "" &&
            this.props.profileOverview.last_name !== ""
              ? this.props.profileOverview.first_name +
                " " +
                this.props.profileOverview.last_name
              : this.props.profileOverview.name
          }
          username={
            this.props.addressDetail ? (
              <div>
                <i className="fa fa-flag"></i>
                {this.props.addressDetail.country}
              </div>
            ) : (
              ""
            )
          }
          description={
            <div className="profile-information-box-info padding-top-25">
              <div>
                <i className="fa fa-envelope"></i>{" "}
                {this.props.profileOverview
                  ? this.props.profileOverview.email
                  : ""}
              </div>
              <div>
                <i className="fa fa-phone"></i>{" "}
                {this.props.profileOverview
                  ? this.props.profileOverview.telephone
                  : ""}
              </div>
            </div>
          }
          socials={
            <div className="total-appointments-box">{`${renderTranslation(store, 
              "profile.customers_profile_appointments.total_appointments"
            )} : ${
              this.props.appointments && this.props.appointments.meta
                ? this.props.appointments.meta.total
                : 0
            }`}</div>
          }
        />
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    store: state
  } 
};

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomerInformationBox)
);
