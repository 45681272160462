import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardSubtitle,
    CardTitle,
    Col,
    FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Nav,
    NavItem, NavLink,
    Row
} from "reactstrap";
import {ControlLabel} from "react-bootstrap";
import SearchableSelect from "../../../shared/Select/SearchableSelect";
import i18n from "../../../../i18n";
import {SetAssignment, SetValidationErrors, TaskRateAction} from "../../actions";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { cloneDeep } from 'lodash';
import {useTranslation} from "react-i18next";
import {connect, useDispatch, useSelector} from "react-redux";
import {getScheduledRate, getUrgentRate} from "../helpers/Assignment";
import AppointmentCalendar from "../../../../components/AppointmentCalendar";
import { renderTranslation } from "services";

function AssignmentType(props) {
    const { address, errors, consumer, assignment, task, taskId } = useSelector(state => state.createAppointment);
    const [open, setOpen] = useState(!!(errors.schedule));
    const [loadingDates, setLoadingDates] = useState(false);
    const [localErrors, setErrors] = useState({});
    const dispatch = useDispatch();
    const store = useSelector((state) => state);

    useEffect(() => {
        taskId && fetchRates();
    }, [address, taskId]);

    useEffect(() => {
        setOpen(!!(errors.schedule));
        submitScheduleAppointment();
    }, [errors.schedule]);

    const formatRateDates = (dates) => {
        let newDates = {};
        if (assignment.rates) {
            newDates = assignment.rates.dates;
        }
        dates && dates.length > 0 && dates.map((date, index) => {
            newDates[date.date] = date;
        })
        return newDates;
    }

    const fetchRates = () => {
        if (address && address.lat && address.lon) {
            const payload = {
                task_id: taskId,
                lat: address.lat,
                lng: address.lon,
                chosenDate: moment().format("YYYY-MM-DD"),
                hour: moment().format("HH"),
            };
            setLoadingDates(true);
            dispatch(TaskRateAction(payload))
                .then(res => {
                    const data = {
                        ...res.data,
                        'dates': formatRateDates(res?.data?.dates || '')
                    }
                    const updatedAssignment = {
                        ...assignment,
                        rates: data
                    }
                    dispatch(SetAssignment(updatedAssignment));
                    setLoadingDates(false);
                })
        }
    }

    const openScheduleAppointment = () => {
        setOpen(true);
    }

    const closeScheduleAppointment = () => {
        if (!validateScheduleAppointment()) {
            const updatedAssignment = {
                ...assignment,
                selected: 'urgent'
            }
            dispatch(SetAssignment(updatedAssignment));
        }
        setOpen(false);
    }

    const submitScheduleAppointment = () => {
        setErrors({});
        const res = validateScheduleAppointment();
        if (res) {
            setOpen(false);
        }
    }

    const selectSchedule = () => {
        const updatedErrors = cloneDeep(errors);
        delete updatedErrors['consumer'];
        delete updatedErrors['address'];
        delete updatedErrors['taskId'];

        dispatch(SetValidationErrors(updatedErrors));

        if  (!address || !address.lat || !address.lon || !task.id) {
            if (!task.id) {
                updatedErrors.taskId = renderTranslation(store,'appointments.task_select_validation');
            }
            if (!consumer || !consumer.id) {
                updatedErrors.consumer = renderTranslation(store,'appointments.consumer_validation');
            } else {
                updatedErrors.address = renderTranslation(store,'appointments.valid_address_validation');
            }
            dispatch(SetValidationErrors(updatedErrors));
        } else {
            const updatedAssignment = {
                ...assignment,
                selected: 'schedule'
            }
            dispatch(SetAssignment(updatedAssignment));
            setOpen(true);
        }
    }

    const dispatchAssignment = (assignment) => {
        dispatch(SetAssignment(assignment));
    }

    const fetchRatesOnDate = (newDate) => {
        return new Promise((resolve, reject) => {
            const payload = {
                task_id: taskId,
                lat: address.lat,
                lng: address.lon,
                chosenDate: newDate,
                hour: moment().format("HH"),
            };
            setLoadingDates(true);
            dispatch(TaskRateAction(payload))
                .then(res => {
                    if(res && res.data && res.data.dates) {
                        const data = {
                            ...res.data,
                            'dates': formatRateDates(res.data.dates)
                        }
                        resolve(data);
                    }
                    setLoadingDates(false);
                })
        })
    }

    const handleFutureAppointmentDate = (newDate) => {
        fetchRatesOnDate(newDate)
            .then(res => {
                const updatedAssignment = {
                    ...assignment,
                    rates: res,
                    date: newDate
                }
                dispatchAssignment(updatedAssignment);
            })
    }

    const disablePastDt = (current) => {
        return current.isAfter(moment());
    };

    const changeSchedule = (value) => {
        const updatedAssignment = {
            ...assignment,
            schedule: value
        }
        if(value === 'week') {
            fetchRatesOnDate(moment().format('YYYY-MM-DD'))
                .then(res => {
                    updatedAssignment.rates = res;
                    dispatchAssignment(updatedAssignment);
                })
        } else {
            dispatchAssignment(updatedAssignment);
        }
    }

    const handleSlotsChange = (selectedDays) => {
        const updatedAssignment = {
            ...assignment,
            selectedDays
        }
        dispatch(SetAssignment(updatedAssignment));
    }

    const validateScheduleAppointment = () => {
        return assignment.selectedDays && Object.keys(assignment.selectedDays).length;
    }

    const ScheduleAppointment = () => {
        const active = (assignment.selected === 'schedule');
        const scheduledRate = getScheduledRate(assignment);

        return (
            <div onClick={selectSchedule} className={active ? "bg-gradient-info p-3 mb-2 assignment-type-card" : "border border-info p-3 mb-2 assignment-type-card"}>
                <h4 className={active ? "text-white" : ""}><i className="tim-icons icon-calendar-60 mr-2" /> {renderTranslation(store,'appointments.available_days')}</h4>
                <p className={active ? "text-white" : ""}>{renderTranslation(store,'appointments.schedule_assigmnment_description')}</p>
                <div className="d-flex">
                    <p className={active ? "text-white mt-2" : "mt-2"}>{renderTranslation(store,'appointments.from_label')}</p>
                    <h3 className={active ? "text-white font-weight-bold mb-0 mx-2" : "font-weight-bold mb-0 mx-2"}>€{scheduledRate.rate}</h3>
                    <p className={active ? "text-white mt-2" : "mt-2"}>+ €{scheduledRate.service} {renderTranslation(store,'appointments.service_fee')}</p>
                    {active ? (
                        <i className="tim-icons icon-check-2 font-weight-bold ml-auto mt-2 text-success" style={{ fontSize: 30 }} />
                    ) : ''}
                </div>
            </div>
        )
    }

    const selectUrgent = () => {
        const updatedAssignment = {
            ...assignment,
            schedule: 'urgent'
        }
        dispatch(SetAssignment(updatedAssignment));
    }

    const UrgentAppointment = () => {
        if (!task.prepaid || !task.prepaid.prepaid) {
            const active = (assignment.selected === 'urgent');
            const urgentRates = getUrgentRate(assignment);

            return (
                <div onClick={selectUrgent} className={active ? "bg-gradient-info p-3 mb-2 assignment-type-card" : "border border-info p-3 mb-2 assignment-type-card"}>
                    <h4 className={active ? "text-white" : ""}><i className="tim-icons icon-spaceship mr-2" /> {renderTranslation(store,'appointments.urgent_assignment_title')}</h4>
                    <p className={active ? "text-white" : ""}>{renderTranslation(store,'appointments.urgent_assignment_description')}</p>
                    <div className="d-flex">
                        <h3 className={active ? "text-white font-weight-bold mb-0 mr-2" : "font-weight-bold mb-0 mr-2"}>€{urgentRates.rate}</h3>
                        <p className={active ? "text-white mt-2" : "mt-2"}>+ €{urgentRates.service} {renderTranslation(store,'appointments.service_fee')}</p>
                        {active ? (
                            <i className="tim-icons icon-check-2 font-weight-bold ml-auto mt-2 text-success" style={{ fontSize: 30 }} />
                        ) : ''}
                    </div>
                </div>
            )
        }
        return '';
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h4">
                    {renderTranslation(store,'appointments.when_section_header')}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <Nav className="nav-pills-info flex justify-content-around flex-wrap flex-sm-nowrap" pills>
                            {UrgentAppointment()}
                            {ScheduleAppointment()}
                        </Nav>
                    </Col>
                </Row>
            </CardBody>
            <Modal isOpen={open} toggle={closeScheduleAppointment}>
                <ModalHeader toggle={closeScheduleAppointment}>{renderTranslation(store,'appointments.options')}</ModalHeader>
                <ModalBody>
                    <div className="p-3">
                        <FormGroup>
                            <Label>{renderTranslation(store,'appointments.select_date')}</Label>
                            <AppointmentCalendar dispatch={dispatch} loading={loadingDates} assignment={assignment} rates={assignment.rates || {}} handleChange={handleSlotsChange} fetchNewRates={fetchRatesOnDate} />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" style={{ position: 'absolute', right: 30, bottom: 15 }} onClick={closeScheduleAppointment}>{renderTranslation(store,'appointments.confirm')}</Button>
                </ModalFooter>
            </Modal>
        </Card>
    )
}

export default AssignmentType;
