import React, { Component } from "react";
import { RequestPasswordResetAction } from "../actions";
import { connect } from "react-redux";
import "../styles/auth.scss";
import RequestPasswordReset from "./partials/RequestPasswordReset.jsx";
import EmailConfirmation from "./partials/EmailConfirmation.jsx";
import ResetNewPassword from "./partials/ResetNewPassword.jsx";
import { withTranslation } from 'react-i18next';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetRequestPassword: true,
      emailConfirmation: false,
      resetNewPassword: false,
      loading: false,
      email: "",
      token: "",
      password: "",
      password_confirmation: ""
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  
  handleChange = (e) => {
    return e.target.name === "email"
      ? this.setState({ email: e.target.value })
      : () => {};
  };

  /**
   * 
   * @param {object} e 
   */
  handleClick = (e) => {
    e.preventDefault();
    let { email } = this.state;
    if(this.validate()) {
      let payload = {email: email };
      this.props.requestPasswordResetAction(payload).then(result => {
        if (result.success) {
          this.setState({loading: false});
          this.props.history.push('#dashboard');
        }
      })

    }
};
    /**
     * 
     * @param {email} email 
     */
    onSubmitPasswordRequest = (email) => {
    this.setState({email: email, emailConfirmation: true, resetRequestPassword: false});
    };

    /**
     * 
     * @param {object} payloads 
     */
    onSubmitEmailConfirmation = (payloads) => {
      this.setState({email: payloads.email,
        token: payloads.token,
        emailConfirmation: false,
        resetRequestPassword: false,
        resetNewPassword: true
      });
      };
  
    redirectLogin = () => {
        this.props.history.push('/auth/login');
    };

  render() {
    const handleClickNavigate = () =>  this.props.history.push('/auth/login');
     
      if(this.state.resetRequestPassword ) {
    return (
      <div className="content">

        <RequestPasswordReset cancel={this.redirectLogin} submit={this.onSubmitPasswordRequest} />
      </div>
    );
    } 
    if(this.state.emailConfirmation && this.state.email !== '') {
        return (
            <div className="content">
                <EmailConfirmation email={this.state.email} submit={this.onSubmitEmailConfirmation} />
            </div>
          );
    }

    if(this.state.resetNewPassword && this.state.email !== '' && this.state.token !== '' ) {
      return (
          <div className="content">
              <ResetNewPassword submit={handleClickNavigate} />
          </div>
        );
  }

  }
}

const mapStateToProps = (state) => {
  return {
    forgotDetails: state.forgotPassword.forgotDetails,
  };
};
const mapDispatchToProps = {
  requestPasswordResetAction: RequestPasswordResetAction,
};
export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword));
