import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import "../../styles/appointments.scss";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip
} from "reactstrap";
import classnames from "classnames";
import { renderTranslation } from "services/renderTranslation.service";
import { getCurrentUser } from "../../../../services"
import { CreateCommentAction, FetchCommentAction } from "../../actions"
class AppointmentNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "info",
      comment: "",
      loading: false,
      error: null,
      selectedAppointment: null,
      commentsList: [],
      loadComments: false,
      isCollapse: false
    };
  }

  componentDidMount() {
    if (this.props.appointment) {

      this.setState({ loadComments: true, selectedAppointment: this.props.appointment })
      
      if(this.props.appointment.professional_id)
      this.setState({isCollapse: true })

      this.fetchComments(this.props.appointment.uuid);
    }
  }

  changeTab = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  handleChange = (e) => {
    this.setState({ comment: e.target.value });
  };
  saveComment = () => {
    if (this.props.appointment && this.state.comment != "") {
      this.setState({ loading: true })

      let payload = {
        text: `${getCurrentUser().name}: ${this.state.comment}`,
        type: "appointment",
        user: "partner",
        appointment_id: this.props.appointment.uuid,
      }

      this.props.createCommentAction(payload).then((resp) => {
        if (resp.success) {

          this.setState({ loading: false, comment: "" })
          this.setState({ loadComments: false });
          this.fetchComments(this.props.appointment.uuid);
        }
      })

    } else {
      this.setState({ error: "Please fill description of note" })
    }
  };

  fetchComments(appointmentId) {
    this.props.fetchCommentAction(appointmentId).then((resp) => {
      this.setState({ loadComments: false });
      if (resp.success) {
        this.setState({ commentsList: resp.data.data })
      }
    });
  }

  normalizeComment(comment) {
    const { store } = this.props;
    let text = comment.text;
    if (comment.user === 'partner' && text.includes(':')) {
      return {
        user: text.split(':')[0],
        text: text.replace(`${text.split(':')[0]}:`, '')
      }
    } else if (comment.user === 'customer') {
      return {
        user: `${this.props.appointment.customer.name} (${renderTranslation(store, 'appointments.notes.customer')})`,
        text: text
      }

    } else {
      return {
        user: comment.user,
        text: text
      }
    }
  }

  render() {
    const { store } = this.props;
    return (

      <div className="appointment-notes">
        <Row>
          <Col md={12}>
            <h5>{renderTranslation(store, 'appointments.notes.notes_text')}</h5>
            {this.state.loadComments && (
              <div className="loader">
                <i className="fas fa-spinner fa-pulse"></i>
              </div>
            )}
            {(this.state.commentsList && this.state.commentsList.length > 0) && (
              <Card className="card-timeline card-plain">
                <CardBody className={`style-4  ${this.state.isCollapse ? "height-collapse": "height-expand"}` }>
                  <ul className="timeline timeline-simple">
                    {this.state.commentsList.map((comment) => {
                      return (
                        <li className="timeline-inverted">

                          <div className="timeline-panel">
                            <div className="timeline-heading">
                              <Badge color="success">{this.normalizeComment(comment).user} </Badge>
                            </div>
                            <div className="timeline-body">
                              <p>
                                {this.normalizeComment(comment).text}
                              </p>
                            </div>
                            <h6>
                              <i className="ti-time" />
                              {comment.created_at}
                            </h6>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </CardBody>
              </Card>
            )}

          </Col>
        </Row>
        <Row  className="add-note">
          <Col md={12}>
            <InputGroup>
              <div className="error">{this.state.error}</div>
              <Input
                name="comment"
                cols="80"
                value={this.state.comment}
                placeholder={renderTranslation(store, 'appointments.notes.comment_description_placeholder')}
                rows="1"
                onChange={this.handleChange}
                type="textarea"
              />
              <InputGroupAddon id="save-button-tooltip" className="cursor-pointer" addonType="append" onClick={this.saveComment}>
                <InputGroupText><i className="tim-icons icon-send"></i></InputGroupText>
              </InputGroupAddon>
              <UncontrolledTooltip delay={0} target="save-button-tooltip">
              {renderTranslation(store, 'appointments.notes.save')}
              </UncontrolledTooltip>
            </InputGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state
  };
};

const mapDispatchToProps = {
  createCommentAction: CreateCommentAction,
  fetchCommentAction: FetchCommentAction
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentNotes)
);
