import { appointmentDetailService } from '../services'


/**
 * Create new ComMENT of partner
 * @param object payload 
 */
export const CreateCommentAction = (payload) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    appointmentDetailService.createComments(payload).then((response) => {
      resolve({ success: true, data: response })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}

/**
 * Fetch comments of partner
 * @param object payload 
 */
export const FetchCommentAction = (appointmentId) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    appointmentDetailService.fetchComments(appointmentId).then((response) => {
      resolve({ success: true, data: response })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}

export const FetchCancelReasonAction = (payload) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    appointmentDetailService.fetchCancelReason().then((response) => {
      resolve({ success: true, data: response.data })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}

export const AppointmentComplaintsAction = (payload , page = 1) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    appointmentDetailService.fetchComplaints(payload, page).then((response) => {
      resolve({ success: true, data: response })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}

export const AppointmentCancellationAction = (appointmentId) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    appointmentDetailService.cancelAppointment(appointmentId).then((response) => {
      resolve({ success: true })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}