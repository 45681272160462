//export * from "./FetchTranslationsAction";

export const setTranslations = (data) => ({
    type: 'SET_TRANSLATIONS',
    payload: data
  });
  
  export const setActiveLocale = (data) => ({
    type: 'SET_ACTIVE_LOCALE',
    payload: data
  });
  
  export const loadingTranslations = (data) => ({
    type: 'LOADING_TRANSLATIONS',
    payload: data
  });
  
  export const renderTranslation = (store, data) => ({
    type: 'RENDER_TRANSLATION',
    store,
    payload: data
  });
  