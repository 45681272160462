import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import {getCurrentUser} from "../../../../services"
import "../../styles/dashboard.scss";
import i18n from "../../../../i18n"
import {renderTranslation} from "../../../../services/";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,

    Row,
    Col,
  } from "reactstrap";

import { chartsOverview } from "variables/charts.js";
  import moment from "moment-timezone";
  import Select from "react-select";
  import {FetchStatsAction} from "../../actions"
  const mapData = {
    AU: 760,
    BR: 550,
    CA: 120,
    DE: 1300,
    FR: 540,
    GB: 690,
    GE: 200,
    IN: 200,
    RO: 600,
    RU: 300,
    US: 2920,
  };

class JobsStatsOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bigChartData: 'posted',
            year: moment().year(),
            chartsData: [],
            posted_jobs: null,
            planned_jobs:null,
            completed_jobs:null,
            current_data: null,
            stats_chart: null,
        }


    }

    componentDidMount() {
      this.setState({posted_jobs: this.props.stats.posted_jobs})
      this.setState({planned_jobs: this.props.stats.planned_jobs})
      this.setState({completed_jobs: this.props.stats.completed_jobs})
      this.setState({current_data: {labels:this.props.stats.posted_jobs.labels,data: this.props.stats.posted_jobs.data }})

      setTimeout(()=> this.setBigChartData(this.state.bigChartData))
    }

    /**
     * Get Years for stats
    */
     getYears = () => {

      if(getCurrentUser()) {
        const currentYear = moment([moment().year(), 0]);
        const userCreatedYear = moment([moment(getCurrentUser().data.created_at).year(), 0]);
        const lengthArray = currentYear.diff(userCreatedYear, 'years') + 2;


        const year = new Date().getFullYear();

        return Array.from({length: lengthArray}, (v, i) => { return { value: (year - lengthArray + i + 1), label: (year - lengthArray + i + 1) } });
      }else{
        return
      }

    }

    /**
     * @param canvas
     * @param {object} currentData
     */
    getChartsData(canvas, currentData) {
    const labels = currentData.labels;
    const data = currentData.data;
    let ctx = canvas.getContext("2d");
    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: labels,
      datasets: data.map((set)=> {
          return {
            label: this.getDataSetLabel(),
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: set.data,
          };
      }),
    };
 }

    getDataSetLabel() {
      const { store } = this.props;
      let tabName = 'dashboard.'+this.state.bigChartData
      return renderTranslation(store, 'dashboard.total_stats', { tab:tabName })
    }

    setBigChartData($name) {
      this.setState({ bigChartData: $name });

      let labels = [];
      let currentData = [];
      if($name === 'posted') {
        labels = this.state.posted_jobs.labels;
        currentData = this.state.posted_jobs.data;

      }
      if($name === 'completed') {
        labels = this.state.completed_jobs.labels;
        currentData = this.state.completed_jobs.data;

      }
      if($name === 'planned') {
        labels =  this.state.planned_jobs.labels;
        currentData = this.state.planned_jobs.data;
      }
      this.setState({current_data: {labels:labels,data: currentData }})

      this.setState({stats_chart: (<Line
        data={(e)=> this.getChartsData(e, this.state.current_data)}
        redraw={true}
        options={chartsOverview.options}
      />)});
    }

    getPartnerStatsByYear(year) {
      let params = {
        year: year
      }
     this.props.fetchStatsAction(params).then((result) => {
       console.log(result, 'result')
        if(result.data) {
        const stats = result.data.data;
        this.setState({posted_jobs: stats.posted_jobs})
        this.setState({planned_jobs: stats.planned_jobs})
        this.setState({completed_jobs: stats.completed_jobs})
        setTimeout(()=> this.setBigChartData(this.state.bigChartData))
      }
    });
    }

    changeStatsByYear(year)  {
        this.setState({year: year.value})
        this.getPartnerStatsByYear(year.value);
    }

    render() {
      const { store } = this.props;

      return (
            <div className="job-stats">
                <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category"> {renderTranslation(store, "appointments.appointments")} ({this.state.year}) </h5>
                    <CardTitle tag="h2">{renderTranslation(store, 'dashboard.overview')} </CardTitle>
                    </Col>

                  <Col sm="6">

                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          defaultValue={this.state.year}
                          options={this.getYears()}
                          onChange={(e)=> this.changeStatsByYear(e)}
                          placeholder={this.state.year}

                        />
                      <Button
                        color="info"
                        id="0"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: this.state.bigChartData === "posted",
                        })}
                        onClick={() => this.setBigChartData("posted")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        {renderTranslation(store, "dashboard.posted")}
                  </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: this.state.bigChartData === "planned",
                        })}
                        onClick={() => this.setBigChartData("planned")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        {renderTranslation(store, "dashboard.planned")}
                  </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: this.state.bigChartData === "completed",
                        })}
                        onClick={() => this.setBigChartData("completed")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        {renderTranslation(store, "dashboard.completed")}
                  </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {this.state.stats_chart}

                </div>
              </CardBody>
            </Card>
            </div>
        );
    }

}

const mapDispatchToProps = {
    fetchStatsAction: FetchStatsAction,
};

const mapStateToProps = (state) => {
  return {
    store: state
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(JobsStatsOverview)
);
