import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

export default class StatsService {

    http = "";
    constructor() {
        this.http = httpHandler(
            baseEndpoint.partnersServiceBaseEndPoint);
    }

    /**
     * @param {object} params 
    **/
    fetchStats(params) {
        let urlParameters = Object.entries(params).map(e => e.join('=')).join('&');

        return this.http.get(`${apiUrls.dashboard.getPartnerStats(urlParameters)}`);
    }
}



export const statsService = new StatsService();