import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import "../../styles/appointments.scss";
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import moment from "moment-timezone";
import { renderTranslation } from "services";
class AppointmentNotification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      event_logs: []
    };
  }

  componentDidMount() {
    if (this.props.appointment && this.props.appointment.event_logs && this.props.appointment.event_logs.length > 0) {
      this.setState({ event_logs: this.props.appointment.event_logs });
    }
  }

  getNotificationHeader(type) {
    const { store } = this.props;
    let text = `appointments.notification.${type}`
    return {
      status: renderTranslation(store, text)
    }
  }
  render() {
    const { store } = this.props;
    return (
      <div className="appointment-notification" >
        <Row>
          <Col md={12}>

            <h4 className="notification-heading">{renderTranslation(store, 'appointments.notification.notifications_text')}</h4>

            {(this.state.event_logs.length === 0) && (
              <div className="no-found-pro-text">{renderTranslation(store, 'appointments.notification.no_notification_available_text')}</div>
            )}
            {(this.state.event_logs.length !== 0) && (

              <Card className="card-timeline card-plain">
                <CardBody className="style-4 notification-block">
                  <ul className="timeline timeline-simple">
                    {this.state.event_logs.map((log) => {
                      return (
                        <li className="timeline-inverted">

                          <div className="timeline-panel">
                            <div className="timeline-heading">
                              <h6>{this.getNotificationHeader(log.type).status}</h6>
                            </div>
                            <div className="timeline-body">

                              {log.additional && (
                                <p className="additional-reason">
                                  {JSON.parse(log.additional).reason.description}
                                </p>
                              )}
                            </div>
                            <div className="text-right">
                              <h6>
                                <i className="ti-time" />
                                {moment(log.created_at).format('DD MMM  YYYY. hh:mm A')}
                              </h6>
                            </div>
                          </div>
                        </li>
                      );
                    })}

                  </ul>
                </CardBody>
              </Card>
            )}
          </Col>

        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state
  };
};

const mapDispatchToProps = {

};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentNotification)
);
