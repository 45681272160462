import {httpHandler, apiUrls, baseEndpoint } from "../../../services"
class ForgotPasswordService {

http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint);
  }

    /**
     * Forgot password request
     * @param {string} email 
     */
    forgetPassword(email) {
       return this.http.post(apiUrls.auth.forgotPassword , {
            email: email
        })
    }

    /**
     * resend Email for token(forgot password request)
     * @param {object} params 
     */
    resendEmailToken(params) {
        return this.http.post(apiUrls.auth.forgotPassword , params)
    }

    /**
     * Verify Email token
     * @param {object} params  
     */
    verifyEmailToken(params) {
        return this.http.post(apiUrls.auth.verifyEmail , params)
    }

    /**
     * Reset password(Add new password)
     * @param {object} params 
     */
    resetPassword(params) {
        return this.http.post(apiUrls.auth.resetPassword , params)
    }
    
   
}

export const forgotPasswordService = new ForgotPasswordService();