import React, { Component } from "react";
import { connect } from 'react-redux';
import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";

import {
  FormGroup,
  FormControl,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import FormInputs from "../../../shared/FormInputs/FormInputs.jsx";
import { errorAlertBox , successAlertBox } from "../../../../services";

import avatar from "assets/img/default.png";
import {
  UpdateCustomerAction,
  FetchConsumerAction,
  UploadProfileImageAction,
  RemoveProfileImageAction,
  GetProfileImageAction
} from "../../actions"
import { mediaBaseUrl } from "../../../../services"
import i18n from "../../../../i18n";
import { renderTranslation } from "services/renderTranslation.service.js";
import { ALLOWED_FILE_TYPES } from "../../constants/UploadFIleContstats";

class CustomerOverviewProfile extends Component {
  customerDetailForm = null;
  inputOpenFileRef = null;
  constructor(props) {

    super(props);
    this.state = {
      file: null,
      image: {},
      errorShow: null,
      loading: false,
      dataExist: false,
      customerId: 0,
      customerUuid: 0,
      mediaId: 0,
    };

  }

  componentWillMount() {
    this.createInputs();
  }


  componentDidMount() {
    if (this.props.profileDetails && Object.keys(this.props.profileDetails).length !== 0) {
      this.fetchCustomerDetail(this.props.profileDetails);
      this.getAppointmentImages(this.props.profileDetails);
    }
  }

  getAppointmentImages = async (profileDetails) => {
      const body = {
        type: ['Profile'],
        ref_id: profileDetails.uuid,
      };
    const res = await this.props.getProfileImageAction(body);

    if (res.data.length) {
      const image = res.data[res.data.length -1 ];
      this.customerDetailForm.controls["avatar_path"].setValue(image.path)
      this.setState({ image })
    }
  };

  fetchCustomerDetail = (profileDetails) => {
    this.setState({ customerId: profileDetails.id, customerUuid: profileDetails.uuid })
    this.customerDetailForm.controls["first_name"].setValue(profileDetails.first_name)
    this.customerDetailForm.controls["last_name"].setValue(profileDetails.last_name)
    this.customerDetailForm.controls["username"].setValue(profileDetails.name)
    this.customerDetailForm.controls["email"].setValue(profileDetails.email)
    this.customerDetailForm.controls["phone"].setValue(profileDetails.telephone)
    this.setState({ dataExist: true });
  };

  createInputs = () => {
    this.customerDetailForm = new Group({
      avatar_path: new Control(null),
      first_name: new Control(null ),
      last_name: new Control(null),
      username: new Control(null, [Validators.required]),
      email: new Control(null, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      phone: new Control(null, [Validators.required]),

    });
  };

  handleFIleUpload = () => {
    this.inputOpenFileRef.click();
  };

  removeProfileImage = () => {
    this.props.removeProfileImageAction(this.state.image.uuid).then((response) => {
      this.customerDetailForm.controls["avatar_path"].setValue("");
      this.setState({ file: null, image: {} });
      this.getAppointmentImages(this.props.profileDetails);
    })

  };

  handleChange = (e) => {
    const { store } = this.props;
    const { name, value, files } = e.target;

    switch (e.target.name) {
      case "avatar_path":

        if (!ALLOWED_FILE_TYPES.includes(files[0].type)) {
          errorAlertBox(
            renderTranslation(
              store,
              'upload-error.file-not-allowed'
            )
          );
        }

        if (files[0].size > process.env.REACT_APP_MAX_UPLOAD_SIZE) {
          errorAlertBox(
            renderTranslation(
              store,
              'step_3.upload-error.file-too-large'
            )
          );
          return;
        }

        let payload = {
          ref_id: this.state.customerUuid,
          file: files[0],
          type: "Profile"
        };
        this.props.uploadProfileImageAction(payload)
          .then((resp) => {
            if (resp.success) {
              this.customerDetailForm.controls["avatar_path"].setValue(resp.data.url)
              this.setState({ file: resp.data.url, image: resp.data });
            }
          })

        break;
      default:
        this.customerDetailForm.controls[e.target.name].setValue(value)
      // code block
    }
  };

  handleSubmit = () => {
    const { store } = this.props;
    if (this.customerDetailForm.valid) {
      let payload = {
        first_name: this.customerDetailForm.controls.first_name.value,
        last_name: this.customerDetailForm.controls.last_name.value,
        name: this.customerDetailForm.controls.username.value,
        email: this.customerDetailForm.controls.email.value,
        telephone: this.customerDetailForm.controls.phone.value,
      }
      if(this.customerDetailForm.controls.avatar_path.value) {
        payload.avatar_path = this.customerDetailForm.controls.avatar_path.value
      }

      this.setState({ loading: true });
      this.setState({ errorShow: false });
      this.props.updateCustomerAction(this.state.customerUuid, payload).then(result => {
        this.setState({ loading: false });
        if (result.success) {
          successAlertBox(renderTranslation(store, "message.customer_profile_update"))
        }
      })

    } else {
      this.setState({ errorShow: true });
    }

  };

  errorMassage = (label, errors) => {
    const { store } = this.props;
    if (errors && this.state.errorShow) {
      if (errors.required) {
        return renderTranslation(store, "errors.required", { fieldName: label })
      }
      if (errors.pattern) {
        return renderTranslation(store, "errors.format", { fieldName: label })
      }
    }
    return null;
  };

  render() {
    const { store } = this.props;
    const profileLogo = <Tooltip id="profile_logo">{renderTranslation(store, "profile.profile_logo_tooltip_title")}</Tooltip>;
    const removeLogo = <Tooltip >{renderTranslation(store, "buttons.remove_profile_image_text")}</Tooltip>
    if (this.state.dataExist) {
      return (

        <div className="content">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{renderTranslation(store, "profile.customers_profile_overview_tab_title")}</CardTitle>
            </CardHeader>
            <CardBody>
              <Form action="#">
              <Row>
                <Col md={12} sm={12} className="padding-0">

                  <div className="preview-image-box" >
                    <span>
                    {(this.customerDetailForm.controls.avatar_path.value) &&
                      <UncontrolledTooltip delay={0} target="remove-image-tooltip">
                        {renderTranslation(store, "buttons.remove_profile_image_text")}
                      </UncontrolledTooltip>
										}
                      {(this.customerDetailForm.controls.avatar_path.value) ?
                        (<OverlayTrigger placement="bottom" overlay={removeLogo}>
                          <small id="remove-image-tooltip" className="remove-image" onClick={this.removeProfileImage} >
                            <i className="tim-icons icon-simple-remove"></i></small>

                        </OverlayTrigger>) : ""}
                      <OverlayTrigger placement="bottom" overlay={profileLogo}>
                        <img
                          onClick={this.handleFIleUpload}
                          src={this.state.image.url || avatar}
                          className="preview-image-box-img"
                          alt="logo"
                        />
                      </OverlayTrigger>
                    </span>
                  </div>

                  <span className="error"></span>

                  <FormGroup>
                    <FormControl
                      type="File"
                      name="avatar_path"
                      onChange={this.handleChange}
                      required
                      id="file"
                      inputRef={(ref) => { this.inputOpenFileRef = ref }}
                      style={{ display: "none" }}
                    />

                  </FormGroup>
                </Col>
              </Row>
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.first_name_text"),
                  type: "text",
                  name: "first_name",
                  placeholder: renderTranslation(store, "forms.first_name_placeholder_text"),
                  bsClass: "form-control",
                  error: this.errorMassage(renderTranslation(store, "forms.first_name_text"), this.customerDetailForm.controls['first_name'].errors),
                  defaultValue: this.customerDetailForm.controls.first_name.value,
                  onChange: this.handleChange,
                },
                {
                  label: renderTranslation(store, "forms.last_name_text"),
                  type: "text",
                  name: "last_name",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.last_name_placeholder_text"),
                  defaultValue: this.customerDetailForm.controls.last_name.value,
                  error: this.errorMassage(renderTranslation(store, "forms.last_name_text"), this.customerDetailForm.controls['last_name'].errors),
                  onChange: this.handleChange,
                },

              ]}
            />
            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.user_name_text"),
                  type: "text",
                  name: "username",
                  placeholder: renderTranslation(store, "forms.user_name_placeholder_text"),
                  bsClass: "form-control",
                  error: this.errorMassage(renderTranslation(store, "forms.user_name_text"), this.customerDetailForm.controls['username'].errors),
                  defaultValue: this.customerDetailForm.controls.username.value,
                  onChange: this.handleChange,
                },
              ]}
            />
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              proprieties={[
                {
                  label: renderTranslation(store, "forms.email_text"),
                  type: "text",
                  name: "email",
                  placeholder: renderTranslation(store, "forms.email_placeholder_text"),
                  bsClass: "form-control",
                  error: this.errorMassage(renderTranslation(store, "forms.email_text"), this.customerDetailForm.controls['email'].errors),
                  defaultValue: this.customerDetailForm.controls.email.value,
                  onChange: this.handleChange,
                },
                {
                  label: renderTranslation(store, "forms.phone_no_text"),
                  type: "text",
                  name: "phone",
                  bsClass: "form-control",
                  placeholder: renderTranslation(store, "forms.phone_no_placeholder_text"),
                  defaultValue: this.customerDetailForm.controls.phone.value,
                  error: this.errorMassage(renderTranslation(store, "forms.phone_no_text"), this.customerDetailForm.controls['phone'].errors),
                  onChange: this.handleChange,
                },

              ]}
            />
            <div className="space-25"></div>
            <div className="clearfix" />


              </Form>
            </CardBody>
            <CardFooter>
              <Button bsStyle="primary" pullRight fill onClick={this.handleSubmit}>
                {renderTranslation(store, "buttons.update_profile_text")}
              </Button>
            </CardFooter>
          </Card>
        </div>
      );
    } else {
      return (<div></div>)
    }
  }
}
const mapStateToProps = (state) => {
  return {
    profileDetails: state.customer.profileOverview,
    store: state
  }
}

const mapDispatchToProps = {
  updateCustomerAction: UpdateCustomerAction,
  fetchConsumerAction: FetchConsumerAction,
  uploadProfileImageAction: UploadProfileImageAction,
  getProfileImageAction: GetProfileImageAction,
  removeProfileImageAction: RemoveProfileImageAction
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOverviewProfile);
