import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

export default class FeedService {

    http = "";
    constructor() {
        this.http = httpHandler(
            baseEndpoint.partnersServiceBaseEndPoint);

    }

    /**
     * Reset password(Add new password)
     * @param {object} params 
    **/
    fetchFeed(params, page) {
        let urlParameters = Object.entries(params).map(e => e.join('=')).join('&');

        return this.http.get(`${apiUrls.feed.feed}?page=${page}&${urlParameters}`);
    }


    fetchSubuserFeed(params, page) {
        let urlParameters = Object.entries(params).map(e => e.join('=')).join('&');

        return this.http.get(`${apiUrls.feed.subuserFeed}`);
        // return this.http.get(`${apiUrls.feed.subuserFeed}?page=${page}&${urlParameters}`);
    }
}



export const feedService = new FeedService();