import React from 'react';
import i18n from "../../../i18n";
import Select from "react-select";
import { map } from 'lodash'
import {FormGroup, Label} from "reactstrap";

export default function SearchableSelect(props) {
    const { value, handleChange, options, placeholder, labelProp, valueProp, autoFocus, label, required, error } = props;
    let selectOption = null;

    const labelKey = (labelProp) ? labelProp : 'label';
    const valueKey = (valueProp) ? valueProp : 'value';

    const formattedOptions = map(options, option => {
        const formattedOption = {label: option[labelKey], value: option[valueKey]};
        if (value && option[valueKey] === value) {
            selectOption = formattedOption;
        }
        return formattedOption;
    });

    return (
        <FormGroup>
            <Label>{label} {required ? '*' : ''}</Label>
            <Select
                className="react-select info"
                classNamePrefix="react-select"
                autoFocus={!!autoFocus}
                onChange={selected => handleChange(selected ? selected.value : null)}
                options={formattedOptions}
                placeholder={props.placeholder}
                defaultMenuIsOpen={!!autoFocus}
                value={selectOption}
                isSearchable
                isClearable
            />
            {error ? (
                <span className="text-danger">{error}</span>
            ) : ''}
        </FormGroup>
    );
}