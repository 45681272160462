import {LOGGED_IN_SUCCESSFULLY, SET_USER_ROLE} from '../constants';

const initial_state = {
  userDetails: {},
  role: ''
}
const LoginReducer = (state = initial_state, action) => {
  switch (action.type) {
    case LOGGED_IN_SUCCESSFULLY:
      return {...state, userDetails: {...action.payload}};
    case SET_USER_ROLE:
      return {...state, role: action.payload};
    default:
      return state;
  }
}
export default LoginReducer;