import React, { useState, useCallback } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { apiUrls, renderTranslation } from "../../../../services";
import { useDropzone } from 'react-dropzone';

// Images
import uploadImage from '../../../../assets/img/upload.png';
import closeIcon from '../../../../assets/img/close-icon.png';
import pdfIcon from '../../../../assets/img/pdf-icon.png';

export default function UploadFile(props) {
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const { store } = props;

	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpeg'],
			'application/pdf': ['.pdf'],
			'image/heic': ['.heic'],
			'image/heif': ['.heif'],
			// 'image/jpg': ['.jpg'],
			// 'video/mp4': ['.mp4'],
			// 'video/x-m4v': ['.m4v'],
		},

		onDrop: useCallback(
			async (acceptedFiles, fileRejections) => {
				const tempUploadedFiles = [...uploadedFiles];

				const acceptedFilesWithSrc = acceptedFiles.map(file => {
					return Object.assign(file, {
						src: URL.createObjectURL(file),
					});
				});

				// // Handle sending each uploaded file to Media Service API
				const sendFileToMediaService = async (file) => {
					const myHeaders = new Headers();
					myHeaders.append("SERVICE-KEY", process.env.REACT_APP_MEDIA_SERVICE_KEY);
					myHeaders.append("APP-ID", process.env.REACT_APP_ID);

					const formData = new FormData();
					formData.append("type", "Appointment");
					formData.append('file', file);
					formData.append("ref_id", props.uuid);

					var requestOptions = {
						method: 'POST',
						headers: myHeaders,
						body: formData,
						redirect: 'follow'
					};

					const url = process.env.REACT_APP_MEDIA + '/api/v1/media/upload';
					try {
						const response = await fetch(url, requestOptions);

						if (!response.ok) {
							alert("ERROR Uploading the file, please try again!!");
							return;
						}

						const uploadedFile = await response.json();
						return uploadedFile.data;
					} catch (error) {
						console.log("Error", error);
						// alert("Something went wrong, please try again!!");
					}
				};

				acceptedFilesWithSrc.map(async (singleFile, index) => {
					const data = await sendFileToMediaService(singleFile);
					if (data) {
						tempUploadedFiles.push(data);
						setUploadedFiles(tempUploadedFiles);
					}
				});

				// fileRejections.forEach(file => {
				// 	file.errors.forEach(err => {
				// 		if (err.code === 'file-too-large') {
				// 			setErrorMessage(
				// 				renderTranslation(
				// 					activeLocale,
				// 					translations,
				// 					'step_3.upload-error.file-too-large'
				// 				)
				// 			);
				// 		} else if (err.code === 'file-invalid-type') {
				// 			setErrorMessage(
				// 				renderTranslation(
				// 					activeLocale,
				// 					translations,
				// 					'step_3.upload-error.file-invalid'
				// 				)
				// 			);
				// 		}
				// 	});
				// });
			},
			[uploadedFiles]
		),
		// maxSize: process.env.REACT_APP_MAX_UPLOAD_SIZE,
	});

	const handleRemoveFile = async file => {
		const myHeaders = new Headers();
		myHeaders.append("SERVICE-KEY", process.env.REACT_APP_MEDIA_SERVICE_KEY);
		myHeaders.append("APP-ID", process.env.REACT_APP_ID);

		const formData = new FormData();
		formData.append("type", "Appointment");
		formData.append('file', file);
		formData.append("ref_id", props.uuid);

		var requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
			redirect: 'follow'
		};

		const url = process.env.REACT_APP_MEDIA + `/api/v1/media/${file.uuid}`;
		const response = await fetch(url, requestOptions);

		if (!response.ok) {
			console.log("ERROR Deleting the file", response);
			return;
		}

		const deleteResponse = await response.json();
		if (deleteResponse?.status.toLowerCase() === 'success') {
			const filterFiles = uploadedFiles.filter(
				singleFile => singleFile.uuid !== file.uuid
			);
			setUploadedFiles(filterFiles);
		}
		// try {
		// 	const deleteResponse = await backendCall(
		// 		`/api/v1/media/${file.uuid}`,
		// 		'DELETE',
		// 		null,
		// 		'media',
		// 		{
		// 			'SERVICE-KEY': import.meta.env.VITE_APP_MEDIA_SERVICE_KEY,
		// 			'Content-type': 'multipart/form-data',
		// 		}
		// 	);
		// 	if (deleteResponse?.status.toLowerCase() === 'success') {
		// 		const filterFiles = formData.info.uploads.filter(
		// 			singleFile => singleFile.uuid !== file.uuid
		// 		);
		// 		setFormData({
		// 			...formData,
		// 			info: {
		// 				...formData.info,
		// 				uploads: filterFiles,
		// 			},
		// 		});
		// 	}
		// } catch (error) {
		// 	alert(error);
		// }
	};


	return (
		<>
			<Card className={'card-plain border border-info'}
			      style={{
				      padding: '3rem 18px',
			      }}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						rowGap: '5px',
						cursor: 'pointer'
					}}
					{...getRootProps({
						className:
							'upload-image-field__container flex flex--column flex--center',
					})}
				>
					<img
						width={45}
						height={45}
						className="upload-image-field__icon"
						src={uploadImage}
						alt="Upload one or more Images"
					/>
					<span>
                        {renderTranslation(store, 'step_three.upload_photo')}
					</span>
					<span className="max-upload-size">
                        {renderTranslation(store, 'step_three.upload_photo_max_size')}
						: {(+process.env.REACT_APP_MAX_UPLOAD_SIZE / (1024 * 1024)).toFixed(2)}MB
					</span>
				</div>

				{/* Hidden Upload File Input  */}
				<input
					{...getInputProps()} // Needed for Dropzone
					id="upload-image"
					className="upload-image-field__input"
					name="upload-image"
				/>
			</Card>

			<Card className='card-plain'>
				{/* Uploaded Images Container */}
				{uploadedFiles && uploadedFiles.length > 0 && (
					<div className="upload-image-field__images-grid m-top--small"
					     style={{
						     display: "flex",
						     flexWrap: "wrap",
						     gap: "1rem",
						     alignItems: "start"
					     }}
					>
						{uploadedFiles.map((singleFile, index) => {
							if (
								singleFile.extension.includes('png') ||
								singleFile.extension.includes('jpg') ||
								singleFile.extension.includes('jpeg') ||
								singleFile.extension.toLowerCase().includes('heic') ||
								singleFile.extension.toLowerCase().includes('heif')
							) {
								// If user uploads image...
								return (
									<div
										key={index}
										className="upload-image-field__file-container"
										style={{
											position: "relative",
											width: "120px",
											borderRadius: "6px",
											overflow: "hidden",
											height: "120px",
										}}
									>
										<img
											className="uploaded-image"
											style={{
												width: "100%",
												height: "100%",
												objectFit: "cover"
											}}
											src={singleFile.url}
											// onLoad={() => {
											// 	URL.revokeObjectURL(singleFile.url);
											// }}
										/>
										<img
											className="close-icon"
											width={22}
											height={22}
											style={{
												cursor: "pointer",
												right: "3%",
												top: "2%",
												position: "absolute"
											}}
											src={closeIcon}
											onClick={() =>
												handleRemoveFile(singleFile)
											}
											alt="Remove this Image"
										/>
									</div>
								);
							} else if (singleFile.extension.includes('pdf')) {
								// If user uploads pdf...
								return (
									<div
										key={index}
										className="flex flex--column flex--no-gap upload-image-field__file-container upload-image-field__file-container--pdf"
										style={{
											display: 'flex',
											flexDirection: 'column',
											height: 'auto',
											width: '100px',
											position: 'relative',
											borderRadius: '6px',
											overflow: 'hidden',
										}}
									>
										<img
											style={{ position: 'static' }}
											width={70}
											className="uploaded-pdf"
											src={pdfIcon}
										/>
										<span className="upload-image-field__pdf-text"
										      style={{
											      color: "rgba(51, 51, 51, 0.75)",
											      fontSize: "0.93rem",
											      marginTop: "5px",
										      }}
										>
										{singleFile.title}
									</span>
										<img
											className="close-icon close-icon--pdf"
											style={{
												right: "25%",
												top: "2%",
												position: "absolute",
												cursor: 'pointer'
											}}
											width={22}
											height={22}
											src={closeIcon}
											onClick={() =>
												handleRemoveFile(singleFile)
											}
											alt="Remove this Image"
										/>
									</div>
								);
							}
							//else if (singleFile.type.includes('video')) {
							// 	// If user uploads video...
							// 	return (
							// 		<div
							// 			key={index}
							// 			className="flex upload-image-field__file-container upload-image-field__file-container--video"
							// 		>
							// 			<video width="360" height="200" controls>
							// 				<source
							// 					src={singleFile.src}
							// 					type={singleFile.type}
							// 				/>
							// 				<source
							// 					src={singleFile.src}
							// 					type={singleFile.type}
							// 				/>
							// 				Your browser does not support the video
							// 				tag.
							// 			</video>
							// 			<img
							// 				className="close-icon close-icon--video"
							// 				width={22}
							// 				height={22}
							// 				src={closeIcon}
							// 				onClick={() =>
							// 					handleRemoveFile(singleFile)
							// 				}
							// 				alt="Remove this Image"
							// 			/>
							// 			<img
							// 				className="video-arrow-icon"
							// 				width={40}
							// 				height={40}
							// 				src="/video-arrow.png"
							// 			/>
							// 		</div>
							// 	);
							// }
						})}
					</div>
				)}
			</Card>

		</>
	)
}
