import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  ControlLabel
} from "react-bootstrap";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";

import { withTranslation } from "react-i18next";
import { CREATED, PRO_ASSIGNED, CANCELLED, INVOICED, INVOICE_PENDING, POSTPONED, ON_THE_WAY, INVOICE_PAID, INVOICE_COMPLETED, INVOICE_CREATED, INVOICE_EXPIRED } from "../constants/StatusConstants"

import Pagination from "../../shared/Pagination/Pagination.jsx";
import "../styles/appointments.scss";
import { FetchFeedAction } from "../actions";
import Datetime from "react-datetime";
import avatar from "assets/img/default.png";
import location from "assets/img/location.png";
import FormInputs from "../../shared/FormInputs/FormInputs.jsx";
import moment from "moment-timezone";
import ShowAppointment from "./ShowAppointment.jsx";
import i18n from "../../../i18n";
import { renderTranslation } from "services/renderTranslation.service";

import { DIRECT_APPOINTMENT, FUTURE_APPOINTMENT } from "../constants";
import { errorAlertBox , successAlertBox } from "../../../services";
/**
 *   Class AppointmentFeed
 * - Appointments listing
 * - searching of Appointments
 */
class AppointmentFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appointments: [],
      appointmentsLoading: false,
      liveMeta: null,
      upcomingMeta: null,
      selectedAppointmentDetail: null,
      selectedAppointmentAddress: null,
      selectedCustomerDetail: null,
      showModal: false,
      showAppointment:false,
      loading: false,
      refreshLoading: false,
      filters: {
        type: null,
        id: null,
        customer_name: null,
        from_date: null,
        to_date: null,
      },
      viewAppointmentDisabledButton: false
    };
    document.title = 'Afspraken | Zoofy'
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
    this.getSingleAppointment(this.props.match.params.id)
  }
   this.getAppointments();
  }

  /**
   * Fetch Appointments listing
   * @param {object} params
   * @param {int} page
   */
  getAppointments = (params = { per_page: 8 }, page = 1) => {
    this.setState({appointmentsLoading: true});
    this.props.fetchFeedAction(params, page).then((result) => {
      this.setState({ loading: false });
      this.setState({ refreshLoading: false });
      console.log("feed", result.data)
      if(result.data) {
        this.transformAppointmentFeedIntoTableData(
          result.data,
          params?.type ?? null
        );
      }
      this.setState({appointmentsLoading: false});
    });
  };

  /**
   * @param string appointmentId 
   */
  getSingleAppointment(appointmentId) {
    this.props.fetchFeedAction({uuid: appointmentId}).then((result) => {
      if(result.success && result.data && result.data.data.length > 0) {
          this.showSingleAppointment(result.data.data[0])
      }
    });
  }

  /**
   * Refresh Appointments listing
   * 
   */
  refreshAppointments = () => {
    this.setState({ refreshLoading: true });
    this.getAppointments();
  };

  /**
   * Normalization of Appointments listing
   * @param {object} feed
   * @param {string} type
   */
  transformAppointmentFeedIntoTableData = (feed, type = null) => {
    if (Object.keys(feed).length === 0 && feed.constructor === Object) {
      errorAlertBox("Error fetching feed", "error")
      return;
    }
    let appointments = feed.data;
    if(appointments) {
      //standard feed
      this.setState({ appointments: appointments, liveMeta: feed.meta, viewAppointmentDisabledButton: false });
    }else{
      //pending/parked appointment feed
      let parkedFeed = feed.map((appointment)=> {
        if(appointment.address) {
          appointment.customer_address = {city: `${appointment.address.postal_code} ${appointment.address.suffix}`};
        }

        if(appointment.user) {
          appointment.customer = {name: `${appointment.user.first_name} ${appointment.user.last_name}`}
        }

        if(appointment.task_id) {
          appointment.task = {singular: appointment.task_id}
        }

        appointment.id = 'N/A';

        appointment.deadline_at = 'N/A';
        appointment.created_at = 'N/A';
        appointment.status = 'N/A';
        appointment.scanned_in_range = 'N/A';
      })
      this.setState({ appointments: feed, liveMeta: feed.meta, viewAppointmentDisabledButton: true });
    }
  };

  editAppointment = () => {
    
  };

  /**
   * handle pagination to show next or previous page customer records
   * @param {int} page
   */
  handlePaginate = (page) => {
    this.getUpcomingAppointment(page);
  };

  /**
   * handle pagination to show next or previous page customer records
   * @param {int} page
   */
  handleLivePaginate = (page) => {
    this.getAppointments({ per_page: 8 } , page);
  };

  /**
   *  convert date into special string.
   * @param {string|Date} requestedDate
   */
  appointmentDeadline = (requestedDate) => {
    const { store } = this.props;
    const now = moment(new Date()); //todays date
    const givenDate = moment(requestedDate); // another date
    const day = now.diff(givenDate, "days") % 30;
    const month = now.diff(givenDate, "month") % 12;
    const year = now.diff(givenDate, "year");
    let status = "";


      if(day < 0 ) {
        return "Expired";
      }
    if (month !== 0 && day !== 0) {
      status = `${month} ${month === 1 ? "month" : "months"} and ${day}  ${day === 1 ? "day" : "days"
        } ago`;
    } else if (month === 0 && day !== 0) {
      status = `${day}  ${day === 1 ? "day" : "days"} ago`;
    } else if (month !== 0 && day === 0) {
      status = `${month} ${month === 1 ? "month" : "months"}`;
    }

    if (year !== 0) {
      status = `${year} ${year === 1 ? "year" : "years"} ${status}`;
    }
    return status !== "" ? status: renderTranslation(store, 'appointments.now_text');
  };

  /**
   * Close modal of selected customer profile or address
   * that relate with specific appointment
   */
  handleClose = () => {
    this.setState({ showModal: false });
  };

  /**
   * Show selected Customer profile detail of selected Appointment in modal box
   * @param {object} customer
   */
  showCustomerDetail = (customer) => {
    if(this.state.viewAppointmentDisabledButton) {
      return;
    }
    
    this.setState({
      selectedAppointmentAddress: null,
      selectedCustomerDetail: customer,
      showModal: true,
    });
  };

  /**
   * Show selected Address detail of selected Appointment in modal box
   * @param {object} address
   */
  showAppointmentAddressDetail = (address) => {
    if(this.state.viewAppointmentDisabledButton) {
      return;
    }
    
    this.setState({
      selectedAppointmentAddress: address,
      selectedCustomerDetail: null,
      showModal: true,
    });
  };

  /**
   * show single customer profile
   * @param {string} id
   */
  viewProfile = (id) => {
    this.props.history.push(`/admin/consumer-profile/${id}`);
  };

  handleFilterChange = (e) => {
    const filters = this.state.filters;
    filters[e.target.name] = e.target.value;
    this.setState({ filters });
  };

  /**
   * Select  'From' date For filter specific appointment
   * @param {string} value
   */

  handleFromDate = (value) => {
    const filters = this.state.filters;
    filters.from_date = value.format("YYYY-MM-DD hh:mm:ss");
    this.setState({ filters });
    window.scrollTo(0, 200);
  };

  /**
   * Select  'To' date For filter specific appointment
   * @param {string} value
   */
  handleToDate = (value) => {
    const filters = this.state.filters;
    filters.to_date = value.format("YYYY-MM-DD  hh:mm:ss");
    this.setState({ filters });
  };

  /**
   * Select Type for filter specific  Appointment's type
   * @param {object} event
   */
  filterType = (event) => {
    const filters = this.state.filters;
    filters.type = event.target.value;
    this.setState({ filters });
  };

  /**
   * Apply Filter for appointments
   * @return Void
   */

  applyFilter = () => {
    this.setState({ loading: true });
    let filters = this.state.filters;
    filters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != null)
    );
    this.getAppointments(filters);
  };

  toggleModal = () => {
    let modal = !this.state.showModal;
    this.setState({ showModal: modal });
  }

  /**
   * 
   * @param {object} appointment 
   */
  showSingleAppointment = (appointment) => {
    this.setState({ selectedAppointmentDetail: appointment});
    this.setState({ showAppointment: true})
  };

  /**
   * toggle Appointment detail (open or close)
   */
  toggleAppointmentDetailModal = () => {
    let modal = !this.state.showAppointment;
    this.setState({ showAppointment: modal });
  }

  /**
   * Close modal of selected Appointment detail
   */
  handleAppointmentDetailClose = () => {
    
    this.setState({ showAppointment: false });
    this.props.history.push("/admin/appointments")
  };

  refreshSingleAppointment = () => {
    let payload = {
      uuid : this.state.selectedAppointmentDetail.uuid
    }
    this.props.fetchFeedAction(payload, 1).then((result) => {
      this.setState({selectedAppointmentDetail: result.data[0]});
    });
  };

  /**
   * GET Translation
   */
  getStatusTranslation(status) {
    const { store } = this.props;
    if (status)
    status = status.toLowerCase().replace(' ', "_");

    if (status == CREATED) {
      return renderTranslation(store, 'appointments.posted')
    }

    if (status == PRO_ASSIGNED) {
      return renderTranslation(store, 'appointments.accepted');
    }

   else if (status == CANCELLED) {
      return renderTranslation(store, 'appointments.cancelled');
    }

    else if (status == INVOICE_PENDING) {
        return renderTranslation(store, 'appointments.invoicePending');
    }

    else if (status == POSTPONED) {
        return renderTranslation(store, 'appointments.postponed');
    }

    else if (status == ON_THE_WAY) {
        return renderTranslation(store, 'appointments.onTheWay');
    }

    else if (status == INVOICE_PAID) {
        return renderTranslation(store, 'appointments.invoicePaid');
    }

    else if (status == INVOICE_EXPIRED) {
      return renderTranslation(store, 'appointments.invoiceCancelled');
    }

    else if (status == INVOICE_COMPLETED) {
        return renderTranslation(store, 'appointments.invoiceCompleted');
    }

    else if (status == INVOICE_CREATED) {
        return renderTranslation(store, 'appointments.invoiceCreated');
    }

    else if (status == INVOICED) { 
      return renderTranslation(store, 'appointments.completed');
    }
  }

  render() {
    const { filters } = this.state;
    const { store } = this.props;
    return (
      <div className="content appointments">
     
        <Row>
          <Col md={3}>
          <Row>
          <Col md={12}>
          
            <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {renderTranslation(store, "appointments.search_appointments")}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: renderTranslation(store, "forms.appointment_id_text"),
                          name: "id",
                          error: null,
                          placeholder: renderTranslation(store, 
                            "forms.appointment_id_placeholder_text"
                          ),
                          defaultValue: "",
                          onChange: this.handleFilterChange,
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: renderTranslation(store, "forms.appointment_customer_name_text"),
                          type: "text",
                          name: "customer_name",
                          placeholder: renderTranslation(store, 
                            "forms.appointment_customer_name_placeholder_text"
                          ),
                          bsClass: "form-control",
                          error: null,
                          defaultValue: "",
                          onChange: this.handleFilterChange,
                        },
                      ]}
                    />
                    <FormGroup>
                      <ControlLabel>
                        {renderTranslation(store, "forms.appointment_from_text")}
                      </ControlLabel>

                      <Datetime
                        name="datepicker"
                        timeFormat={false}
                        defaultValue={new Date().setDate(
                          new Date().getDate() + 1
                        )}
                        locale="nl"
                        dateFormat="DD/MM/YYYY"
                        onChange={this.handleFromDate}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        {renderTranslation(store, "forms.appointment_to_text")}
                      </ControlLabel>

                      <Datetime
                        name="datepicker"
                        timeFormat={false}
                        defaultValue={new Date().setDate(
                          new Date().getDate() + 1
                        )}
                        locale="nl"
                        dateFormat="DD/MM/YYYY"
                        onChange={this.handleToDate}
                      />
                    </FormGroup>

                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <Input
                          type="radio"
                          defaultValue="direct"
                          name="type"
                          onChange={this.filterType}
                          defaultChecked={filters.type === DIRECT_APPOINTMENT}
                        />
                        <span className="form-check-sign" />
                        {renderTranslation(store, "forms.appointment_direct_text")}
                      </Label>
                    </FormGroup>

                    <FormGroup check className="form-check-radio">
                      <Label check>
                        <Input
                          type="radio"
                          defaultValue="future"
                          name="type"
                          onChange={this.filterType}
                          defaultChecked={filters.type === FUTURE_APPOINTMENT}
                        />
                        <span className="form-check-sign" />
                        {renderTranslation(store, "forms.appointment_future_text")}
                      </Label>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill btn-block"
                    color="info"
                    onClick={this.applyFilter}
                    type="submit"
                  >
                    {this.state.loading
                      ? "Loading.."
                      : renderTranslation(store, "buttons.apply_filter")}
                  </Button>
                </CardFooter>
              </Card>

          </Col>
        </Row>
           
          </Col>
          <Col md={9}>

         

            <Row>
              {/* live appointment  Start*/}
              <Col md={12}>
              <Card>
              <CardBody>
              <Button
              className="float-right btn-create-app"
              bsStyle="primary"
              pullRight
              fill
              onClick={() => {
                this.props.history.push("/admin/create-appointment");
              }}
            >
              <i className="tim-icons icon-calendar-60"></i>{" "}
              {renderTranslation(store, "buttons.create_appointment_text")}{""}
            </Button>
              </CardBody>
            </Card>
                <Card style={ this.state.appointmentsLoading ? { height : "50vh" } : null}>
                  <CardHeader>
                    <CardTitle tag="h4">{renderTranslation(store, "appointments.appointments")}</CardTitle>
                    <Button className="btn-icon btn-simple" color="facebook" id="tooltip707467505"  onClick={this.refreshAppointments} >
                      <i className="tim-icons icon-refresh-02" />
                    </Button>
                  </CardHeader>
                  {
                    this.state.appointmentsLoading ?
                    <div style={{height: "100%", alignSelf: "center", display: "flex", alignItems: "center"}}>
                      <Spinner variant="primary" />
                    </div>
                    :
                    <>
                                      <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> {renderTranslation(store, "appointments.table.id_text")}</th>
                          <th> {renderTranslation(store, "appointments.table.job_text")}</th>
                          <th>{renderTranslation(store, "appointments.table.place_text")}</th>
                          <th> {renderTranslation(store, "appointments.table.customer_text")}</th>
                          <th>
                            {renderTranslation(store, "appointments.table.requested_on_text")}
                          </th>
                          <th> {renderTranslation(store, "appointments.table.status_text")}</th>
                          <th> {renderTranslation(store, "customers.table_header.action")}</th>
                        </tr>
                      </thead>
                      {(this.state.appointments && this.state.appointments.length === 0) && (
                            <div className="no-found-table-text pt-2">
                                {renderTranslation(store, "message.no_appointment_found")}
                            </div>
                        )}

                      {(this.state.appointments && this.state.appointments.length > 0) && (
                        <tbody>
                          {this.state.appointments.map((prop) => {
                            // console.log("prop appointm", prop)
                            return (
                              <tr>
                                <td>

                                     {prop.id}

                                  </td>
                                <td>{prop.task.singular}</td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    this.showAppointmentAddressDetail(
                                      prop.customer_address
                                    )
                                  }
                                >
                                  {prop.customer_address.city}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() =>
                                    this.showCustomerDetail(prop.customer)
                                  }
                                >
                                  {prop.customer.name}
                                </td>
                                <td>
                                  {`${moment(prop.created_at).format("DD-MM-YYYY HH:MM")}`}
                                  <br />{" "}
                                  <span className="appointments-deadline-text">{`(Deadline ${this.appointmentDeadline(
                                    prop.deadline_at
                                      ? prop.deadline_at
                                      : prop.created_at
                                  )})`}</span>
                                </td>
                                <td>
                                  <div>
                                      <span class={`badge ${prop.status ? prop.status.toLowerCase().replace(' ', "_") : ''}`}>
                                      {this.getStatusTranslation(prop.status)} 
                                      </span> 
                                </div>
                                  {`${prop.scanned_in_range} craftsmen in radius`}
                                  
                                  </td>
                                <td>
                                  <Button bsStyle="primary" round onClick={() => this.showSingleAppointment(prop)} disabled={this.state.viewAppointmentDisabledButton}>
                                    {renderTranslation(store, "buttons.view")}
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </Table>
                  </CardBody>
                  <CardFooter>
                    {this.state.liveMeta && (
                      <Pagination
                        meta={this.state.liveMeta}
                        onPaginate={this.handleLivePaginate}
                      />)}
                  </CardFooter>
                  </>
                  }
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal isOpen={this.state.showModal} toggle={this.toggleModal} onHide={this.handleClose}>
          <ModalHeader toggle={this.toggleModal} >
            {this.state.selectedAppointmentAddress
              ? renderTranslation(store, "appointments.appointment_address_detail")
              : ""}
            {this.state.selectedCustomerDetail
              ? renderTranslation(store, "appointments.appointment_customer_detail")
              : ""}

          </ModalHeader>
          <ModalBody>
            {this.state.selectedCustomerDetail && (
              <div>
                <Row>
                  <Col md={4}>
                    <img
                      src={avatar}
                      className="appointments-customer-profile-image"
                      alt={this.state.selectedCustomerDetail.name}
                    />


                    <Button
                      color="default"
                      onClick={() =>
                        this.viewProfile(this.state.selectedCustomerDetail.uuid)
                      }
                      size="sm"
                    >
                      View Customer
                        </Button>

                  </Col>
                  <Col md={8}>
                    <div>
                      <div className="appointments-customer-name">
                        {this.state.selectedCustomerDetail.name
                          .charAt(0)
                          .toUpperCase() +
                          this.state.selectedCustomerDetail.name.slice(1)}
                      </div>
                      <div>
                        <div>
                          <i className="fa fa-envelope"></i>{" "}
                          {this.state.selectedCustomerDetail.email}
                        </div>
                        <div>
                          <i className="fa fa-phone"></i>{" "}
                          {this.state.selectedCustomerDetail.telephone}
                        </div>
                      </div>

                      <div>
                        <div>
                          {" "}
                          <i className="fa fa-home"></i>{" "}
                          {`${this.state.selectedCustomerDetail.address.suffix}, ${this.state.selectedCustomerDetail.address.street}`}{" "}
                        </div>
                        <div>
                          <i className="fa fa-map-marker"></i>{" "}
                          {`${this.state.selectedCustomerDetail.address.city}, ${this.state.selectedCustomerDetail.address.country}  (${this.state.selectedCustomerDetail.address.postal_code})`}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

              </div>
            )}

            {this.state.selectedAppointmentAddress && (
              <div>
                <Row>
                  <Col md={3}>
                    <img
                      src={location}
                      className="appointments-customer-profile-image"
                      alt="location-icon"
                    />
                  </Col>
                  <Col md={6}>
                    <div>
                      {" "}
                      <b>House No:</b>
                      {`  ${this.state.selectedAppointmentAddress.suffix}`}
                    </div>
                    <div>
                      {" "}
                      <b>Street:</b>
                      {` ${this.state.selectedAppointmentAddress.street}`}
                    </div>
                    <div>
                      <b>City: </b>
                      {` ${this.state.selectedAppointmentAddress.city}`}
                    </div>
                    <div>
                      <b>Country: </b>
                      {` ${this.state.selectedAppointmentAddress.country}`}
                    </div>
                    <div>
                      <b>Postal Code: </b>
                      {` ${this.state.selectedAppointmentAddress.postal_code}`}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleClose}>
              {renderTranslation(store, "buttons.close")}
            </Button>
            {this.state.selectedCustomerDetail && (
              <Button
                onClick={() =>
                  this.viewProfile(this.state.selectedCustomerDetail.uuid)
                }
              >
                View Customer
              </Button>
            )}
          </ModalFooter>
        </Modal>


        {/* Show Single Appointment detail start */}
            <ShowAppointment {...this.props}
             showModal={this.state.showAppointment}
             toggleModal={this.toggleAppointmentDetailModal}
             handleClose={this.handleAppointmentDetailClose}
             appointment={this.state.selectedAppointmentDetail} 
             refreshAppointment = {this.refreshAppointments}

             />

        {/* Show Single Appointment detail end */}
        <div  className={"bg-overlay " + (this.state.showModal?"show": (this.state.showAppointment?"show":"hide") )}></div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointments: state.feed.feed,
    store: state
  };
};

const mapDispatchToProps = {
  fetchFeedAction: FetchFeedAction,
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentFeed)
);
