import {statsService} from "../services"

/**
 * Fetch Stats of Partner action 
 * @param object payload 
 */
export const FetchStatsAction = (payload) => (dispatch, _getState) => {

    return new Promise((resolve, _reject) => {
      let result = { ...payload }
      statsService.fetchStats(result).then((response) => {
        resolve({ success: true, data: response })
      })
      .catch((error) => {
        console.log('error ', error)
        resolve({ success: false })
      })
    });
  }