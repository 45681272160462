import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

/**
 * API service for partner's contacts
 */
class PartnerContactsService {
  http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint)
  }

  /**
   * 
   * @param {object} payload 
   */
  create(payload) {
      return this.http.post(apiUrls.partnerContacts.create, payload)
  }

  /**
   * @param {object} payload 
   * @param {string} id  //uuid of partner's contact 
   */
  update(payload, id) {
    return this.http.put(apiUrls.partnerContacts.update(id), payload)
  }

  /**
   * @param {string} id  //uuid of partner's contact 
  */
  delete(id) {
    return this.http.delete(apiUrls.partnerContacts.destroy(id))
  }

  /**
   * Fetch all partner's contact
  */
  fetch() {
    return this.http.get(apiUrls.partnerContacts.fetchAll)
  }

  fetchSignal(id) {
    return this.http.get(apiUrls.partnerContacts.fetchSingle(id))
  }
}

export const partnerContactsService = new PartnerContactsService();
