import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import "../../styles/appointments.scss";
import {
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import moment from "moment-timezone";
import { renderTranslation, baseEndpoint } from "services";
import user from "../../../../assets/img/default.png";
class AppointmentProfessionalsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProfessional: null,
      openAllReviews: false,
    };
  }

  componentDidMount() {
    if (this.props.appointment && this.props.appointment.professional_id) {
      this.setState({
        selectedProfessional: this.props.appointment.professional,
      });
    }
  }

  render() {
    const { store } = this.props;
    const acceptedAt =
      (this.props.appointment?.accepted_at &&
        moment(this.props.appointment.accepted_at).format("DD MMM . hh:mm")) ||
      "";
    return (
      <div className="appointment-professional-detail">
        <Row>
          <Col md={12}>
            <h4 className="pro-heading">
              {renderTranslation(
                store,
                "appointments.professional.professional_text"
              )}
            </h4>
            {!this.state.selectedProfessional && (
              <div className="no-found-pro-text">
                <i className="fa fa-ban"></i>{" "}
                {renderTranslation(
                  store,
                  "appointments.professional.no_pro_text"
                )}
              </div>
            )}
            {this.state.selectedProfessional && (
              <Card className="card-testimonial">
                <CardHeader className="card-header-avatar">
                  <img
                    alt="..."
                    className="img img-raised"
                    style={{ width: "inherit", height: "inherit" }}
                    src={user}
                  />
                </CardHeader>
                <CardBody style={{ marginTop: 0 }}>
                  {acceptedAt && (
                    <p className="card-description">
                      {renderTranslation(
                        store,
                        "appointments.professional.accepted_at_job_text"
                      )}
                      &nbsp;{acceptedAt}
                    </p>
                  )}

                  <CardTitle tag="h4">
                    {this.state.selectedProfessional.name}
                  </CardTitle>
                  {/* <p className="category"><i className="tim-icons icon-email-85"></i> {this.state.selectedProfessional.email}</p>
                  <p className="category"><i className="tim-icons icon-chat-33"></i> {this.state.selectedProfessional.telephone}</p> */}
                  {this.state.selectedProfessional?.created_at && (
                    <p className="category">
                      {renderTranslation(store, "tvActiveSince")}:&nbsp;
                      {moment(
                        this.state.selectedProfessional.created_at
                      ).format("YYYY")}
                    </p>
                  )}
                  
                    <p className="category">
                      <i style={{ color: "#f8e71c" }} class="fas fa-star"></i>
                      {this.state.selectedProfessional.review_rating && this.state.selectedProfessional.review_rating >= 3 ? (
                        <span style={{ marginLeft: "5px" }}>
                          {this.state.selectedProfessional.review_rating}/5
                        </span>
                       ) : null}
                      {this.state.selectedProfessional.completed_jobs &&
                      this.state.selectedProfessional.completed_jobs >= 10 ? (
                        <span style={{ marginLeft: "5px" }}>{`(${
                          this.state.selectedProfessional.completed_jobs
                        } ${renderTranslation(
                          store,
                          "tvProfileCompletedJobsTitle"
                        )})`}</span>
                      ) : (
                        <span style={{ marginLeft: "5px" }}>
                          {`(${renderTranslation(store, "tvRisingStar")})`}
                        </span>
                      )}
                    </p>
                 
                  {this.state.selectedProfessional?.reviews_content &&
                  this.state.selectedProfessional.reviews_content?.length >
                    0 ? (
                    <button
                      onClick={() => this.setState({ openAllReviews: true })}
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72)",
                        backgroundColor: "#ff6491",
                        border: "none",
                        borderRadius: "14px",
                        padding: "5px 10px",
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      {renderTranslation(store, "btnAllReviews")}
                    </button>
                  ) : null}
                </CardBody>
              </Card>
            )}
          </Col>

          <Modal
            className="appointment-info-modal-box"
            isOpen={this.state.openAllReviews}
            toggle={() => {
              this.setState({ openAllReviews: false });
            }}
            onHide={() => {
              this.setState({ openAllReviews: false });
            }}
          >
            <ModalHeader
              toggle={() => {
                this.setState({ openAllReviews: false });
              }}
            >
              {renderTranslation(store, "tvTopReviews")}
            </ModalHeader>
            <ModalBody>
              {this.state.selectedProfessional?.reviews_content &&
              this.state.selectedProfessional.reviews_content?.length > 0 ? (
                this.state.selectedProfessional.reviews_content.map(
                  (review, index) => {
                    const scores = new Array(review.score).fill();
                    return (
                      <div
                        key={index}
                        style={{
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          boxShadow: "0px 3px 6px #eaeaea",
                          border: "1px solid #BEBEBE",
                          borderRadius: "14px",
                          padding: "10px 20px",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <span>
                            {scores.map((val) => {
                              return (
                                <i
                                  key={val}
                                  style={{ color: "#f8e71c" }}
                                  class="fas fa-star"
                                ></i>
                              );
                            })}
                          </span>
                          <span
                            style={{ marginLeft: "5px", fontWeight: "bold" }}
                          >
                            {review.score}
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            {moment(review.created_at).format("MMM DD, YYYY")}
                          </span>
                        </div>
                        <p>{review.review}</p>
                      </div>
                    );
                  }
                )
              ) : (
                <p>{renderTranslation(store, "tvNoReviewsFound")}</p>
              )}
            </ModalBody>
          </Modal>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentProfessionalsDetail)
);
