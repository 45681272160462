import {
  FETCH_SINGLE_CUSTOMER_DETAIL,
  UPDATE_CUSTOMER_DETAIL,
  FETCH_CUSTOMER_ADDRESS_DETAIL,
  UPDATE_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_APPOINTMENTS
} from "../constants";

const initial_state = {
  customer: {},
};
const CustomerProfileReducer = (state = initial_state, action) => {
  switch (action.type) {
    case FETCH_SINGLE_CUSTOMER_DETAIL:
      return { profileOverview: { ...action.payload } };

    case UPDATE_CUSTOMER_DETAIL:
      return {...state, profileOverview: { ...action.payload } };

    case FETCH_CUSTOMER_ADDRESS_DETAIL:
      return {...state, addressDetail: { ...action.payload } };
      
    case UPDATE_CUSTOMER_ADDRESS: 
    return {...state, addressDetail: { ...action.payload } };
    case FETCH_CUSTOMER_APPOINTMENTS: 
    return {...state, appointments: { ...action.payload } };
    
    default:
      return state;
  }
};
export default CustomerProfileReducer;
