import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import "../styles/appointments.scss";
import AppointmentNotes from "./partials/AppointmentNotes.jsx";
import AppointmentReBooking from "./partials/AppointmentReBooking.jsx";
import AppointmentInfo from "./partials/AppointmentInfo.jsx";
import CancelAppointments from "./partials/CancelAppointments.jsx";
import AppointmentProfessionalsDetail from "./partials/AppointmentProfessionalsDetail.jsx";
import AppointmentNotification from "./partials/AppointmentNotification.jsx";
import AppointmentComplaints from "./partials/AppointmentComplaints.jsx";
import { CANCELLED, PRO_ASSIGNED, INVOICED } from "../constants/StatusConstants"
import moment from "moment-timezone";
import { renderTranslation } from "services";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from 'classnames';

class ShowAppointment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "info",
    }
  }

  changeTab = (tabName) => {
    this.setState({ activeTab: tabName })
  }

  handleClose = () => {
    this.setState({ activeTab: 'info' })
    this.props.handleClose();
  }

  render() {
    const { store } = this.props;
    return (
      <div>
        <Modal className="appointment-show-modal" isOpen={this.props.showModal} toggle={this.props.toggleModal}>
          <ModalBody>
            <div className="close-modal" onClick={this.handleClose}>
              <i className="tim-icons icon-simple-remove" />
            </div>
            <Row>
              <Col md="2" >
                <Nav
                  className="nav-pills-info nav-pills-icons flex-column"
                  pills
                >
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'info' })}
                      onClick={(e) =>
                        this.changeTab('info')
                      }
                    >

                      <i className="tim-icons icon-notes" />
                      {renderTranslation(store, "appointments.single_appointment.tabs.tab1")}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'notification' })}
                      onClick={(e) =>
                        this.changeTab('notification')
                      }
                    >
                      <i className="tim-icons icon-bell-55" />
                      {renderTranslation(store, "appointments.single_appointment.tabs.tab5")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      disabled={
                        this.props.appointment?.status === CANCELLED 
                          || this.props.appointment?.status === PRO_ASSIGNED
                          || this.props.appointment?.status === INVOICED
                        }
                      className={classnames({ active: this.state.activeTab === 'cancellations' })}
                      onClick={(e) =>
                        this.changeTab('cancellations')
                      }
                    >
                      <i className="tim-icons icon-simple-remove" />
                      {renderTranslation(store, "appointments.single_appointment.tabs.tab6")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="7" className="appointment-show-modal-blocks right">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="info">
                    <AppointmentInfo
                      {...this.props}
                      appointment={this.props.appointment}
                      refreshAppointment={this.props.refreshAppointment}
                    />
                  </TabPane>

                  {this.props.appointment?.status !== CANCELLED && (
                    <TabPane tabId="cancellations">
                      <CancelAppointments
                        {...this.props}
                        appointment={this.props.appointment}
                        refreshAppointment={this.props.refreshAppointment}
                        handleClose={this.props.handleClose}
                      />
                    </TabPane>
                  )}

                  <TabPane tabId="notification">
                    <AppointmentNotification
                      {...this.props}
                      appointment={this.props.appointment}
                    />
                  </TabPane>
                </TabContent>

              </Col>


              <Col md="3" className="appointment-show-modal-blocks left">
                <AppointmentProfessionalsDetail   {...this.props} appointment={this.props.appointment} />
                <hr />
                <AppointmentNotes appointment={this.props.appointment} />
              </Col>
            </Row>
          </ModalBody>

        </Modal>
      </div>
    );

  }


}

const mapStateToProps = (state) => {
  return {
    store: state
  }
};

const mapDispatchToProps = {
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ShowAppointment)
);
