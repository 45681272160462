import React, { Component } from "react";
import { connect } from "react-redux";
import { FetchCategoriesAction } from "../actions";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap";

import "../styles/categories.scss";

import Pagination from "../../shared/Pagination/Pagination.jsx";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { renderTranslation } from "services";
/**
 * - listing of all customers
 * - filter/searching of specific customer
 */
class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      data: [],
      meta: {
        page: 0,
      },
      loading: false
    };
  }

  /**
   * lifecycle method
   */
  componentWillMount() {
    
    //TODO: API IS NOT READY
    //this.fetchCategories();
  }

  /**
   * Fetch all customers
   * @param {array} params 
   * @param {int|null} page 
   */
  fetchCategories = (params = [], page = null) => {
    let payload = {
      params: params,
      page: page,
    };
    this.setState({ loading: true })
    this.props.fetchCategoriesAction(payload).then((categories) => {
      this.setState({ loading: false })
      if (categories.success) {
        const data = categories.data.data;
        this.setState({ data });
        this.normalizeMetaTag(categories.data);
      }
    });
  };

  /**
   * Format meta tags object
   * @param {object} categories 
   */
  normalizeMetaTag = (categories) => {
    const meta = { ...categories };
    delete meta.data;
    this.setState({ meta });
  };

  /**
   * handle filter change event
   * @param {*} e 
   */
  handleFilterChange = (e) => {
    let filters = this.state.filters;
    if (filters.findIndex(i => i.key === e.target.name) !== -1) {
      filters[filters.findIndex(i => i.key === e.target.name)].value = e.target.value
    } else {
      filters.push({ key: e.target.name, value: e.target.value })
    }
    this.setState({ filters })
  };

  /**
   * apply filters
   */
  applyFilter = () => {
    if (this.state.filters.length > 0) {
      this.fetchCategories(this.state.filters, null);

    }
  };

  /**
   * handle pagination to show next or previous page customer records 
   * @param {string} direction 
   */

  handlePaginate = (page) => {
    this.fetchCategories(this.state.filters, page)
  }

  /**
   * handle error message
   * @param {*} label 
   * @param {*} errors 
   */
  errorMassage = (label, errors) => {
    const { store } = this.props;
    if (errors && this.state.errorShow) {
      if (errors.required) {
        return renderTranslation(store, "errors.required", { fieldName: label });
      }
      if (errors.pattern) {
        return renderTranslation(store, "errors.format", { fieldName: label });
      }
    }
    return null;
  };

  render() {
    const { store } = this.props;
    return (
      <div className="content customers main-content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{renderTranslation(store, "categories.page_title")}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th> {renderTranslation(store, "categories.table_header.category")}</th>
                      <th> {renderTranslation(store, "categories.table_header.task")}</th>
                    </tr>
                  </thead>
                  {this.state.data.length > 0 && (
                    <tbody>

                      {this.state.data.map((prop, key) => {
                        return (
                          <tr>
                            <td>{prop.category.system_name}</td>
                            <td>{prop.task.system_name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </CardBody>
              <CardFooter>
                {this.state.meta && <Pagination meta={this.state.meta} onPaginate={this.handlePaginate} />}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store:state
  };
};

const mapDispatchToProps = {
  fetchCategoriesAction: FetchCategoriesAction,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Categories)
);
