import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../styles/appointments.scss";
import { FetchCancelReasonAction, CreateCommentAction, AppointmentCancellationAction } from "../../actions"
import i18n from "../../../../i18n";
import { renderTranslation } from "services/renderTranslation.service";
import ConfirmAlertBox from "../../../shared/Alert/ConfirmAlertBox"
import {
    Button,
    Row,
    Col,
    FormGroup
} from "reactstrap";
import {
    FormGroup as Group,
    FormControl as Control,
    Validators,
} from "react-reactive-form";
import Select from "react-select";
import { getCurrentUser } from "../../../../services"
import moment from "moment-timezone";

class CancelAppointments extends Component {

    cancelAppointmentForm = null;

    constructor(props) {
        super(props);
        this.state = {
            moveWidth: 12,
            activeTab: "info",
            cancelReasonList: [],
            comment: "",
            editAppointmentRateShow: false,
            editAppointmentServiceRateShow: false,
            selectedEditRate: "",
            selectedEditServiceRate: "",
            editAppointmentTaskShow: false,
            selectedTask: "",
            error: false,
            alert:null,
            showConfirmBox:false
        };
        this.createInput();
    }

    changeTab = (tabName) => {
        this.setState({ activeTab: tabName });
    };

    handleChange = (data) => {
        this.cancelAppointmentForm.controls['reason'].setValue(data.value)
    };

    componentDidMount() {
        this.fetchAllCancellation();
    }

    createInput() {
        this.cancelAppointmentForm = new Group({
            reason: new Control(null, [Validators.required]),
        });
    }



    fetchAllCancellation = () => {
        this.props.fetchCancelReasonAction().then((result) => {
            if (result.success) {
                let reasons = result.data;

                const arr = [];
                reasons.map((reason, index) => {
                    const obj = {};
                    obj.value = reason.text;
                    obj.label = reason.text.toUpperCase();

                    arr.push(obj);
                    return null;
                });

                this.setState({
                    cancelReasonList: arr,
                });
            }

        });
    };

    handleClick = () => {
        if (this.cancelAppointmentForm.valid) {
            this.setState({ error: false })
            this.setState({showConfirmBox: true});
        } else {
            this.setState({ error: true })
        }
    }

    cancelAppointment(reason) {
        this.props.appointmentCancellationAction(this.props.appointment.uuid).then((resp) => {
            if (resp.success) {
                this.saveComment(reason);
                this.setState({showConfirmBox: true});
                this.props.refreshAppointment();
                this.props.handleClose();
         }
    })
    }

    /**
     * @param {string} reason
     */
    saveComment = (reason) => {
        let payload = {
            text: `${getCurrentUser().name}: ${reason}`,
            type: "cancel",
            user: "partner",
            appointment_id: this.props.appointment.uuid,
        }
        this.props.createCommentAction(payload).then((_resp) => {})
    };

    getDropdownList = (data, label) => {
        if (data) {
            let result = [
                {
                    value: "",
                    label: label,
                    isDisabled: true,
                },
            ];

            data.map((item) => {
                result.push(item);
                return item;
            });
            return result;
        }
    };

    showSingleCustomer = (id) => {
        this.props.history.push(`/admin/consumer-profile/${id}`);
    }

    confirmAlert = () => {
        this.setState({ showConfirmBox: false });
    this.cancelAppointment(this.cancelAppointmentForm.controls.reason.value)
      this.props.refreshAppointment();
       this.props.handleClose();
    }

    render() {
        const { store } = this.props;
        const disablePastDt = (current) => {
            return current.isAfter(moment().subtract(1, "day"));
        };
        return (
            <div className="appointment-re-booking">
                 {this.state.alert}
                 <ConfirmAlertBox
                 open={this.state.showConfirmBox}
                 header={renderTranslation(store, 'alert.header_sure_text')}
                 body={renderTranslation(store, 'alert.body_cancel_appointment_text')}
                 accept={this.confirmAlert}
                 close={()=>this.setState({showConfirmBox: false})}
                 confirmBtnText={renderTranslation(store, 'buttons.continue_text')}
                 cancelBtnText={renderTranslation(store, 'buttons.cancel_text')}
                 color="warning"
                 icon="fa fa-info"
                 ></ConfirmAlertBox>
                <h4 className="text-center">{renderTranslation(store, 'appointments.note_required_to_cancel_text')}</h4>

                <Row>
                    <Col md={12} className="mt-5">
                        <FormGroup>
                            <span className="error">
                                {(this.state.error && this.cancelAppointmentForm.controls["reason"].errors &&
                                    this.cancelAppointmentForm.controls['reason'].errors.required) ?
                                    renderTranslation(store, "errors.required", { fieldName: "auth.email_text" })
                                    : ""}


                            </span>
                            <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                name="reason"
                                options={this.getDropdownList(
                                    this.state.cancelReasonList,
                                    renderTranslation(store, 'appointments.select_reason')
                                )}
                                placeholder="Select...."
                                onChange={this.handleChange}
                            />
                        </FormGroup>


                    </Col>
                </Row>
                <Button
                    block
                    className="mb-3"
                    color="danger"
                    onClick={this.handleClick}
                    size="md"
                >
                    {renderTranslation(store, 'buttons.continue_text')}
                </Button>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        store: state
    };
};

const mapDispatchToProps = {
    fetchCancelReasonAction: FetchCancelReasonAction,
    createCommentAction: CreateCommentAction,
    appointmentCancellationAction: AppointmentCancellationAction
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(CancelAppointments)
);
