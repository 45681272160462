import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { AppointmentComplaintsAction } from "../../actions"
import "../../styles/appointments.scss";
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Badge,
    Row,
    Col,
    CardFooter,
    Table,
    Button,
    UncontrolledTooltip
} from "reactstrap";
import moment from "moment-timezone";
import user from "../../../../assets/img/default.png"
import { renderTranslation } from "services";
class AppointmentComplaints extends Component {

    constructor(props) {
        super(props);
        this.state = {
            complaints: {
                data: [],
                meta: null
            }
        };
    }

    componentDidMount() {
        if(this.props.appointment) {
            this.fetchComplaints();
        }
    }

    fetchComplaints(status , page) {
        let payload =  {};
        payload.appointment_id = this.props.appointment.id
        this.props.appointmentComplaintsAction().then((resp) => {
                if(resp.success) {
                    this.setState({complaints: {
                        data: resp.data.data,
                        meta: resp.data.meta
                    }})
                    console.log(this.state.complaints);
                }
        })
    }   

    getNotificationHeader(type) {
        const { store } = this.props;
        let text = `appointments.notification.${type}`
        return {
            status: renderTranslation(store, text)
        }
    }
    render() {
        const { store } = this.props;
        return (
            <div className="appointment-notification" >

                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Complaints</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>Complaint</td>
                                    <td>Requested On</td>
                                    <td>Status</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.complaints.data.map((complaint)=> {
                                    return (
                                        <tr>
                                        <td>{complaint.id}</td>
                                        <td>{(complaint.complaint && complaint.complaint.length > 20) ? `${complaint.complaint.substring(0,30)}....`: complaint.complaint}</td>
                                        <td>{moment(complaint.created_at).format('DD MMM  YYYY . hh:mm')}</td>
                                        <td>{complaint.status}</td>
                                        <td className="text-right">
                                            <Button
                                                className="btn-link btn-icon"
                                                color="success"
                                                id="tooltip-view-complaints"
                                                size="sm"
                                                title={renderTranslation(store, "buttons.view")}
                                                type="button"
                                            >
                                                <i className="fa fa-eye" />
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="tooltip-view-complaints">
                                                {renderTranslation(store, "buttons.view")}
                                            </UncontrolledTooltip>
                                        </td>
                                    </tr>
                                    )
                                })}
                               
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        store: state
    };
};

const mapDispatchToProps = {
    appointmentComplaintsAction: AppointmentComplaintsAction
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(AppointmentComplaints)
);
