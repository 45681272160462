export const renderTranslation = (store, translationKey, placeholder) => {
  let activeLocale;
  if(store.activeLocale && store.activeLocale.activeLocale) {
    activeLocale = store.activeLocale.activeLocale;
  }

  let strings = {};
  let translationResult;
  let placeholderResult;
  let regex = /{{2}.*}{2}/gm;

  if(activeLocale && store.translations && store.translations.locales) {
    strings = store.translations.locales[activeLocale];

    //find and return translation without placeholder
    for (const [key, value] of Object.entries(strings)) {
      if (value.translation_key == translationKey) {
        translationResult = value.translation_value;
        if(!placeholder) {
          return translationResult;
        }
      }
    }

    //translate and return if translation has placeholder
    if(placeholder) {
      for (const [key, value] of Object.entries(strings)) {
        if (value.translation_key == placeholder.title) {
          placeholderResult = value.translation_value;
          if(translationResult && placeholderResult) {
            return translationResult.replace(regex, placeholderResult);
          }
        }
      }
      if(translationResult && !placeholderResult) {
        return translationResult.replace(regex, placeholder.title)
      }
    }
  }

  //translation not found
  return translationKey;
};