import {
  CREATED_APPOINTMENT_SUCCESSFULLY,
  SET_ADDRESS,
  FETCHED_TASK_DETAILS_SUCCESSFULLY,
  UPDATE_DYNAMIC_ANSWERS,
  UPDATE_DESCRIPTION,
  UPDATE_CONSUMER,
  FETCH_CONSUMERS_LIST,
  CLEAR_CONSUMER,
  SET_APPOINTMENT_DATES,
  SET_VALIDATION_ERRORS,
  SET_COUPON_CODE,
  SET_WARRANTY_PACKAGE,
  SET_AVAILABLE_RATES,
  SET_ASSIGNMENT,
  RESET_DEFAULT,
  SET_COUPON_ERROR,
  SET_COUPON_SUCSESS,
  SET_TASK_ID
} from '../constants';
import { cloneDeep } from 'lodash';

const initial_state = {
  createdAppointment: {},
  task: {},
  taskId: null,
  dynamicAnswers: [],
  consumersList: {},
  consumer: {},
  errors: {},
  warrantyPackage: true,
  coupon: null,
  couponError: null,
  couponSuccess: null,
  address: {},
  description: '',
  assignment: {
    selected: 'urgent',
    schedule: 'day',
    dayTime: [],
    date: null
  }
}

const CreateAppointmentReducer = (state = initial_state, action) => {
  switch (action.type) {
    case FETCH_CONSUMERS_LIST:
      return {
        ...state,
        consumersList: action.payload
      }
    case CREATED_APPOINTMENT_SUCCESSFULLY:
      return {...state, createdAppointment: {...action.payload}};
    case SET_ADDRESS:
      const errorsExludingAddress = cloneDeep(state.errors);
      delete errorsExludingAddress['address'];
      return {
        ...state,
        errors: errorsExludingAddress,
        address: action.payload
      };
    case UPDATE_DYNAMIC_ANSWERS:
      return {
        ...state,
        dynamicAnswers: action.payload
      }
    case UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.payload
      }
    case SET_TASK_ID: {
      return {
        ...state,
        taskId: action.payload
      }
    }
    case FETCHED_TASK_DETAILS_SUCCESSFULLY:
      const errorsExludingTask = cloneDeep(state.errors);
      delete errorsExludingTask['taskId'];
      const assignment = cloneDeep(state.assignment);
      const task = action.payload;
      if (task.prepaid.prepaid) {
        assignment.selected = 'schedule';
      }
      return {
        ...state,
        errors: errorsExludingTask,
        task: action.payload,
        dynamicAnswers: [],
        assignment
      }
    case UPDATE_CONSUMER:
      const errorsExludingConsumer = cloneDeep(state.errors);
      delete errorsExludingConsumer['consumer'];
      delete errorsExludingConsumer['first_name'];
      delete errorsExludingConsumer['last_name'];
      delete errorsExludingConsumer['email'];
      delete errorsExludingConsumer['telephone'];
      delete errorsExludingConsumer['address'];
      return {
        ...state,
        errors: errorsExludingConsumer,
        consumer: action.payload
      }
    case CLEAR_CONSUMER:
      return {
        ...state,
        consumer: {}
      }
    case SET_APPOINTMENT_DATES:
      return {
        ...state,
        assignment: action.payload
      }
    case SET_VALIDATION_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    case SET_COUPON_CODE:
      return {
        ...state,
        coupon: action.payload
      }
    case SET_WARRANTY_PACKAGE:
      return {
        ...state,
        warrantyPackage: action.payload
      }
    case SET_ASSIGNMENT:
      return {
        ...state,
        assignment: action.payload
      }
    case SET_COUPON_ERROR:
      return {
        ...state,
        couponError: action.payload
      };
    case SET_COUPON_SUCSESS:
      return {
        ...state,
        couponSuccess: action.payload
      }
    case RESET_DEFAULT:
      return initial_state;
    default:
      return state;
  }
}
export default CreateAppointmentReducer;