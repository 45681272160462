import UserPage from "views/Pages/UserPage.jsx";
import LoginPage from "../modules/authentication/views/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import ForgotPassword from "../modules/authentication/views/ForgotPassword.jsx";


const routes = [
  
  {
    path: "/forgot-password",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: ForgotPassword
  },
  {
    path: "/login",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  
  {
    path: "/user-page",
    layout: "/admin",
    name: "User Page",
    mini: "UP",
    component: UserPage
  },
  
  {
    path: "/register-page",
    layout: "/auth",
    name: "Register",
    mini: "RP",
    component: RegisterPage
  },

];
export default routes;
