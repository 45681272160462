import Dashboard from "../modules/Dashboard/views/Dashboard.jsx";
import PartnerProfile from "../modules/profile/views/PartnerProfile.jsx";
import CustomerProfile from "../modules/profile/views/CustomerProfile.jsx";
import Consumers from "../modules/Consumer/views/Consumers.jsx";
import SubUsers from "../modules/SubUsers/views/SubUsers.jsx";
import Categories from "../modules/Categories/views/Categories.jsx";
import AppointmentFeed from "../modules/Appointments/views/Feed.jsx";
import CreateAppointment from "../modules/Appointments/views/CreateAppointment.jsx";

const routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    translateKey: 'navbar.dashboard_text',
    icon: "pe-7s-graph",
    inNav: true,
    component: Dashboard
  },
  {
    path: "/appointments",
    layout: "/admin",
    name: "Appointments",
    translateKey: 'appointments.appointments',
    state: "openAppointments",
    icon: "pe-7s-date",
    inNav: true,
    component: AppointmentFeed
  },
  {
    path: "/appointment-detail/:id",
    layout: "/admin",
    name: "Appointments",
    translateKey: 'navbar.dashboard',
    state: "openAppointments",
    icon: "pe-7s-date",
    inNav: false,
    component: AppointmentFeed
  },
  {
    path: "/create-appointment",
    layout: "/admin",
    name: "Create Appointment",
    translateKey: 'navbar.create_appointment_text',
    state: "openAppointments",
    icon: "pe-7s-date",
    inNav: false,
    component: CreateAppointment
  },
  {
    path: "/consumers",
    layout: "/admin",
    name: "Consumers",
    translateKey: 'navbar.customers_text',
    icon: "pe-7s-users",
    inNav: true,
    component: Consumers
  },
  {
    path: "/profile",
    layout: "/admin",
    name: "Profile",
    translateKey: 'navbar.profile_text',
    icon: "pe-7s-users",
    inNav: false,
    component: PartnerProfile
  },
  {
    path: "/consumer-profile/:id",
    layout: "/admin",
    name: "Customer Profile",
    translateKey: 'navbar.customer_profile_text',
    icon: "pe-7s-users",
    inNav: false,
    component: CustomerProfile
  },
  {
    path: "/subusers",
    layout: "/admin",
    name: "Sub-Users",
    translateKey: 'navbar.sub_users',
    icon: "pe-7s-users",
    inNav: true,
    component: SubUsers
  }
];
export default routes;
