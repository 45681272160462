/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Navbar, Dropdown, DropdownButton, MenuItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { setActiveLocale, setTranslations, loadingTranslations } from "modules/Translations/actions";


class PagesHeader extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      width: window.innerWidth,
      languageOptions: []
    };
  }
  // function that sets the class to active of the active page
  activeRoute(routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // function that shows/hides sidebar on responsive
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }
  updateWidth() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWidth.bind(this));
    this.renderLocales();
  }
  
  renderLocales = () => {
    this.props.http.get(`/i18n/locales`)
    .then(result => {
      this.setState({languageOptions: result})
    })
    .catch(error => console.log('error', error));
  }

  setNewLocale = (locale, key) => {
    this.setState({key})
    const { dispatch, store } = this.props;
    if (locale === 'nl') {
      // if translations already in store then return translations
      if (
        Object.keys(store.translations.locales.nl).length === 0 &&
        store.translations.locales.nl.constructor === Object
      ) {
        this.getTranslations('nl');
      } else {
        dispatch(setActiveLocale('nl'));
      }
    }

    if (locale === 'en') {
      // if translations already in store then return translations
      if (
        Object.keys(store.translations.locales.en).length === 0 &&
        store.translations.locales.en.constructor === Object
      ) {
        this.getTranslations('en');
      } else {
        dispatch(setActiveLocale('en'));
      }
    }

    if (locale === 'de') {
      // if translations already in store then return translations
      if (
        Object.keys(store.translations.locales.de).length === 0 &&
        store.translations.locales.de.constructor === Object
      ) {
        this.getTranslations('de');
      } else {
        dispatch(setActiveLocale('de'));
      }
    }
  }

  getTranslations = (locale) => {
    const { dispatch } = this.props;
    dispatch(loadingTranslations(true));
    this.props.http.get(`/translations?_locale=${locale}&tags_contains=partnerdash`)
    .then(data => {
      const localeResult = { [locale]: { ...data } };
        dispatch(setTranslations(localeResult));
        dispatch(setActiveLocale(locale));
        dispatch(loadingTranslations(false));
    })
    .catch(error => console.log('error', error));
  }

  render() {
    const { languageOptions } = this.state
    return (
      <Navbar
        collapseOnSelect
        inverse
        className="navbar-primary navbar-transparent navbar-absolute"
      >
        <Navbar.Header style={{margin: "0 auto"}}>
          <Navbar.Brand>
            <NavLink to={"admin/dashboard"} className="nav-link">
            </NavLink>
              <div style={{display: 'flex', flexDirection: 'row', listStyle: 'none' }}>
              {languageOptions.length > 0 && languageOptions.map((item) => {
                return (
                    <MenuItem style={{margin: "1rem"}} className="nav-item" onClick={() => this.setNewLocale(item.code)}>{item.code.toUpperCase()}</MenuItem>
                  )
              })}
              </div>
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
      </Navbar>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    store: state
  }
}

export default (connect(mapStateToProps)(PagesHeader));
