import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

/**
 * API service for consumer listing and manage consumer filtering
 */
class CategoriesService {
  http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint)

    // this.http = httpHandler(
    //   baseEndpoint.consumerServiceBaseEndPoint,
    //   "SB5wyf0xMLrWZZPDkw0C21qkhqkM2Dhz9yZhjwwPLqneJQayjZ8xN6senzlm"
    // );
  }

  /**
   *
   * @param {array} params
   * @param {int} page
   *
   */
  fetchAllCategories(params = [], page = null) {
    let filterParam = "";
    if (params.length > 0) {
      [...params].forEach((param, key) => {
        filterParam += `${key === 0 ? "?" : "&"}filter[${param.key}]=${
          param.value
        }`;
      });
    }
    if (page) {
      filterParam += `${filterParam === "" ? "?" : "&"} page=${page}`;
    }
    let partnerId =  JSON.parse(localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY)).userDetail.data.id;
    return this.http.get(apiUrls.categories.fetchAllCategories(filterParam, partnerId));
  }
}

export const categoriesService = new CategoriesService();
