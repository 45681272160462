import React, { Component } from "react";
import "../styles/invoice.scss";
import { connect } from "react-redux";
import { FetchFeedAction } from "../actions";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { getCurrentUser, baseEndpoint } from "../../../services";

import Invoice from "./partials/Invoice.jsx"
class PrintInvoice extends Component {
    BTW = '21%';
    constructor(props) {
        super(props);
        this.state = {
            selectedAppointmentDetail: null,
            totalInvoice: 0
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            this.getSingleAppointment(this.props.match.params.id)
        }
    }

    /**
   * @param string appointmentId 
   */
    getSingleAppointment(appointmentId) {
        this.props.fetchFeedAction({ uuid: appointmentId }).then((result) => {
            if (result.success && result.data && result.data.data.length > 0) {

                const singleAppointment = result.data.data[0];
                this.setState({ selectedAppointmentDetail: singleAppointment });
                this.getInvoice(singleAppointment);
            }
        });
    }

    getInvoice(singleAppointment) {
        let invoiceItems = singleAppointment.invoice.invoice_items;
        invoiceItems.forEach((item) => {
            let total = this.state.totalInvoice + item.total
            this.setState({ totalInvoice: total })
        })
    }

    render() {
        return (

            <div className="invoice-view">
                {
                    this.state.selectedAppointmentDetail && (
                        <div>
                            <div className="print-invoice-btn">
                                <ReactToPrint content={() => this.componentRef}>
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (
                                            <button className="btn btn-warning" onClick={handlePrint}><i className="fa fa-print"></i>Print this out!</button>
                                        )}
                                    </PrintContextConsumer>
                                </ReactToPrint>
                            </div>
                            <Invoice
                                selectedAppointmentDetail={this.state.selectedAppointmentDetail}
                                totalInvoice={this.state.totalInvoice}
                                ref={el => (this.componentRef = el)}
                            />
                        </div>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appointments: state.feed.feed,
    };
};

const mapDispatchToProps = {
    fetchFeedAction: FetchFeedAction,
};
export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(PrintInvoice)
);

