import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
} from "reactstrap";

// import Card from "../../../../components/Card/Card.jsx";
import FormInputs from "../../../shared/FormInputs/FormInputs.jsx";
// import Button from "../../../../components/CustomButton/CustomButton.jsx";
import { renderTranslation } from "services/renderTranslation.service.js";
import { withTranslation } from 'react-i18next'
import i18n from "../../../../i18n";

class PartnerSettings extends Component {

  handleChange = (e) => {

  }

  render() {
    const { store } = this.props;
    return (
      <div className="content partner-profile-setting">

<Card>
              <CardHeader>
                <CardTitle tag="h4">{renderTranslation(store, "profile.profile_setting_tab_title")}</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#">
                <FormInputs
          ncols={["col-md-6", "col-md-6"]}
          proprieties={[
            {
              label: renderTranslation(store, "forms.new_password_text"),
              type: "password",
              name: "password",
              placeholder:  renderTranslation(store, "forms.new_password_placeholder_text"),
              bsClass:"form-control",
              error: null,
              onChange: this.handleChange,
            },
            {
              label: renderTranslation(store, "forms.confirm_new_password_text"),
              type: "password",
              name: renderTranslation(store, "forms.confirm_new_password_placeholder_text"),
              placeholder: "Enter password Again",
              bsClass:"form-control",
              error: null,
              onChange: this.handleChange,
            },
            {
              label: "Website",
              type: "text",
              name: "position",
              bsClass:"form-control",
              placeholder: "Enter website",
              defaultValue: "zoofy.nl",
              error: null,
              onChange: this.handleChange,
            },
            
          ]}
        />
                </Form>
              </CardBody>
              <CardFooter>
                <Button bsStyle="info" type="submit">
                  {renderTranslation(store, "buttons.update_password_text")}
                </Button>
              </CardFooter>
            </Card>




      <Card
      title={renderTranslation(store, "profile.profile_setting_tab_title")}
      content={
        <div >
          <Card 
          title="Change Password"
          content= {
            <div className="partner-profile-setting-password-box">
            <form>
          

            </form>
           </div>
          }
          />

        </div>


      }
    />
    </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    store: state
  };
};

export default withTranslation()(connect(mapStateToProps)(PartnerSettings));
