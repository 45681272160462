import { httpHandler, apiUrls, baseEndpoint } from "../../../services";
import { BehaviorSubject } from "rxjs";

class LoginService {
  
    http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint);
  }

  subject = new BehaviorSubject(false);
  /**
   * Reset password(Add new password)
   * @param {object} params
   */
  login(params) {
    const config = {
      headers: {
        source: 'cs-dashboard',
        country: params.country
      }
    };
    return this.http.post(apiUrls.auth.login, params, config);
  }

  userDetail() {
    return this.http.get(apiUrls.auth.profile);
  }

  userProfileDetail(partnerUUID) {
    return this.http.get(apiUrls.auth.profileDetail(partnerUUID))
  }

  setCurrentUser = (tokenDetail, userDetail = null) => {
    localStorage.setItem(
      process.env.REACT_APP_CURRENT_USER_KEY,
      JSON.stringify(this.normalizeTokenDetail(tokenDetail, userDetail))
    );
  };

  getCurrentUser = () => {
    const user = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY)
    );
    return user && user.userDetail ? user.userDetail : null;
  };

  normalizeTokenDetail = (tokenDetail, userDetail) => {
    return {
      token: `${tokenDetail.token_type} ${tokenDetail.access_token}`,
      refreshToken: tokenDetail.refresh_token,
      userDetail: userDetail,
    };
  };

  setRefreshTokenProcess = (isRunning) => {
    this.subject.next(isRunning);
  };

  getRefreshTokenProcess = async () => {
    let process = false;
    await this.subject.subscribe((resp) => {
      process = resp;
    });
    return process;
  };

  logout = () => {
    localStorage.removeItem(process.env.REACT_APP_CURRENT_USER_KEY);
    window.location.reload();
  };

  refreshToken = async () => {
    let userDetail = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY)
    );
    if (userDetail) {
      const params = { refresh_token: userDetail.refreshToken };
      const refreshToken = await this.http.post(
        apiUrls.auth.refreshToken,
        params
      );

      userDetail.token = `${refreshToken.token_type} ${refreshToken.access_token}`;
      userDetail.refreshToken = refreshToken.refresh_token;
      localStorage.setItem(
        process.env.REACT_APP_CURRENT_USER_KEY,
        JSON.stringify(userDetail)
      );
      return refreshToken;
    }
  };
}

export const loginService = new LoginService();
