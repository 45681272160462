import * as moment from "moment-timezone";
import * as newMoment from 'moment';

export const getScheduledRate = (assignment) => {
    const { rates, schedule, date } = assignment;
    if (rates && rates.dates) {
        if(schedule === 'week') {
            let min = 0;
            let max = 0;
            let service = 0;
            for (let i = 1; i < 8; i += 1) {
                const day = moment().add(i, 'days').format('YYYY-MM-DD ') + '08:00:00';
                const dayRate = rates.dates[day];
                if (dayRate.price < min || min === 0) {
                    min = dayRate.price;
                } else if(dayRate.price > max) {
                    max = dayRate.price;
                }
                const dayService = parseFloat(dayRate.service_fee);
                if(dayService > service) {
                    service = dayService;
                }
            }
            if(min === max) {
                return {
                    rate: `${min}`,
                    service: service
                }
            }
            return {
                rate: `${min} - €${max}`,
                service: service
            }
        } else if(schedule === 'day' && date) {
            const day = moment(date).format('YYYY-MM-DD ') + '08:00:00'
            const dayRate = rates.dates[day];
            if (dayRate) {
                return {
                    rate: `${dayRate.price}`,
                    service: dayRate.service_fee
                }
            }
        }
        const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD ') + '08:00:00'
        const tomorrowRate = rates.dates[tomorrow];
        if (tomorrowRate) {
            return {
                rate: `${rates.max}`,
                service: tomorrowRate.service_fee
            }
        }
    }
    return {
        rate: ' ...',
        service: ' ...'
    }
}

export const getUrgentRate = (assignment) => {
    const { rates } = assignment;
    if (rates && rates.dates) {
        const today = moment().format('YYYY-MM-DD HH') + ':59:59'
        const todayRate = rates.dates[today];
        if (todayRate) {
            return {
                rate: todayRate.price,
                service: todayRate.service_fee
            }
        }
    }
    return {
        rate: ' ...',
        service: ' ...'
    }
}



export const formatScheduleOptions = (assignment) => {
    const res = {};
    const timeFormatter = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    if (assignment.selected === 'urgent') {
        const today = moment().format(timeFormatter);
        res.day = moment().tz("UTC").format(timeFormatter);
        res.rate = setUrgentRate(today, assignment);
        res.options = [];
    } else {
        res.day = moment().tz("UTC").format(timeFormatter);
        res.options = [];



        //what the fuck is this setting the appointment.rate with tomorrows rate....??
        //overwriting/re-calculating this value in submitAppointment() in CreateAppointment.jsx
        //SHAME https://github.com/ZoofyBV/zoofy-partner-dashboard/commit/ee792b3250d6b29d266ba700813b47a3ddeaddd8#diff-c1ad5baeb7aa2574ac0497bceae4ca60aed8f20e396add0bfc38075c531e80e7
        res.rate = assignment.rates.dates[`${moment().add(1, 'days').format('YYYY-MM-DD')} 08:00:00`].price;



        const selectedDays = assignment.selectedDays;
        for (const selectedDayKey in selectedDays) {
            const selectedDay = selectedDays[selectedDayKey];
            if (selectedDay.slots.includes('morning')) {
                res.options.push({
                    startTime: newMoment(`${selectedDayKey} 07:00:00`).format(timeFormatter),
                    endTime: newMoment(`${selectedDayKey} 09:00:00`).format(timeFormatter),
                    rate: assignment.rates.dates[`${selectedDayKey} 08:00:00`].price
                });

                res.options.push({
                    startTime: newMoment(`${selectedDayKey} 09:00:00`).format(timeFormatter),
                    endTime: newMoment(`${selectedDayKey} 11:00:00`).format(timeFormatter),
                    rate: assignment.rates.dates[`${selectedDayKey} 08:00:00`].price
                });            }
            if (selectedDay.slots.includes('afternoon')) {
                res.options.push({
                    startTime: newMoment(`${selectedDayKey} 11:00:00`).format(timeFormatter),
                    endTime: newMoment(`${selectedDayKey} 13:00:00`).format(timeFormatter),
                    rate: assignment.rates.dates[`${selectedDayKey} 08:00:00`].price
                });
                res.options.push({
                    startTime: newMoment(`${selectedDayKey} 13:00:00`).format(timeFormatter),
                    endTime: newMoment(`${selectedDayKey} 15:00:00`).format(timeFormatter),
                    rate: assignment.rates.dates[`${selectedDayKey} 08:00:00`].price
                });
                res.options.push({
                    startTime: newMoment(`${selectedDayKey} 15:00:00`).format(timeFormatter),
                    endTime: newMoment(`${selectedDayKey} 17:00:00`).format(timeFormatter),
                    rate: assignment.rates.dates[`${selectedDayKey} 08:00:00`].price
                });
            }
            if (selectedDay.slots.includes('evening')) {
                res.options.push({
                    startTime: newMoment(`${selectedDayKey} 17:00:00`).format(timeFormatter),
                    endTime: newMoment(`${selectedDayKey} 20:00:00`).format(timeFormatter),
                    rate: assignment.rates.dates[`${selectedDayKey} 08:00:00`].price
                });
            }
        }
    }
    return res;
}

const setUrgentRate = (today, assignment) => {
    console.log(assignment, 'asdasdadsasdasd');
    if (assignment.hasOwnProperty('rates')){
        if (assignment.rates.dates[today]) {
            return assignment.rates.dates[today].price;
        } else {
            const index = Object.keys(assignment.rates.dates)[0];
            return assignment.rates.dates[index].price;
        }
    }
}
