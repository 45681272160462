import React, { Component } from "react";
import { connect } from 'react-redux';
import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form
} from "reactstrap";

// import Card from "../../../../components/Card/Card.jsx";
import FormInputs from "../../../shared/FormInputs/FormInputs.jsx";
// import Button from "../../../../components/CustomButton/CustomButton.jsx";

import { UpdateCustomerAddressAction } from "../../actions"
import { errorAlertBox , successAlertBox } from "../../../../services";
import i18n from "../../../../i18n";
import { renderTranslation } from "services/renderTranslation.service.js";

class CustomerContactDetail extends Component {
  customerAddressDetailForm = null;
  inputOpenFileRef = null;
  constructor(props) {

    super(props);
    this.state = {
      errorShow: null,
      loading: false,
      dataExist: false,
      customerUid: 0,
    };

  }

  componentWillMount() {
    this.createInputs();
  }

  componentDidMount() {
    if ((this.props.addressDetail) && (this.props.profileDetails &&
        Object.keys(this.props.profileDetails).length !== 0))
      this.fetchCustomerAddressDetail(this.props.addressDetail);
  }

  fetchCustomerAddressDetail = (addressDetail) => {
    this.setState({ customerUid: this.props.profileDetails.uuid })
    this.customerAddressDetailForm.controls["suffix"].setValue(addressDetail.suffix)
    this.customerAddressDetailForm.controls["street"].setValue(addressDetail.street)
    this.customerAddressDetailForm.controls["postal_code"].setValue(addressDetail.postal_code)
    this.customerAddressDetailForm.controls["city"].setValue(addressDetail.city)
    this.customerAddressDetailForm.controls["country"].setValue(addressDetail.country)
    this.customerAddressDetailForm.controls["lat"].setValue(addressDetail.lat)
    this.customerAddressDetailForm.controls["lon"].setValue(addressDetail.lon)
    this.setState({ dataExist: true });
  }

  createInputs = () => {
    this.customerAddressDetailForm = new Group({
      suffix: new Control(null, [Validators.required]),
      street: new Control(null, [Validators.required]),
      postal_code: new Control(null, [Validators.required]),
      city: new Control(null, [Validators.required]),
      country: new Control(null, [Validators.required]),
      lat: new Control(null),
      lon: new Control(null),

    });
  };

  handleChange = (e) => {

    this.customerAddressDetailForm.controls[e.target.name].setValue(e.target.value)
  };

  handleSubmit = () => {
    const { store } = this.props;
    if (this.customerAddressDetailForm.valid) {
      let payload = {
        suffix: this.customerAddressDetailForm.controls.suffix.value,
        street: this.customerAddressDetailForm.controls.street.value,
        postal_code: this.customerAddressDetailForm.controls.postal_code.value,
        city: this.customerAddressDetailForm.controls.city.value,
        country: this.customerAddressDetailForm.controls.country.value,
        lat: this.customerAddressDetailForm.controls.lat.value,
        lon: this.customerAddressDetailForm.controls.lon.value,
      }
      this.setState({ loading: true });
      this.setState({ errorShow: false });
      this.props.updateCustomerAddressAction(this.state.customerUid, payload).then(result => {
        this.setState({ loading: false });
        if (result.success && result.valid) {
          successAlertBox(renderTranslation(store, "message.customer_profile_address_update"))
        } else if(result.success && !result.valid) {
          errorAlertBox(renderTranslation(store, "message.customer_profile_address_verification_failed"))
        } else {
          errorAlertBox(renderTranslation(store, "message.default_message"))
        }

      })

    } else {
      this.setState({ errorShow: true });
    }

  };

  errorMassage = (label, errors) => {
    const { store } = this.props;
    if (errors && this.state.errorShow) {
      if (errors.required) {
        return renderTranslation(store, "errors.required", { fieldName: label })
      }
      if (errors.pattern) {
        return renderTranslation(store, "errors.format", { fieldName: label })
      }
    }
    return null;
  };

  render() {
    const { store } = this.props;
    if (this.state.dataExist) {
      return (
        <div>
          <Card>
              <CardHeader>
                <CardTitle tag="h4">{renderTranslation(store, "profile.customers_profile_address_tab_title")}</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#">
                <FormInputs
                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                proprieties={[
                  {
                    label: renderTranslation(store, "forms.house_no_text"),
                    type: "text",
                    name: "suffix",
                    placeholder: renderTranslation(store, "forms.house_no_placeholder_text"),
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.house_no_text"), this.customerAddressDetailForm.controls['suffix'].errors),
                    defaultValue: this.customerAddressDetailForm.controls.suffix.value,
                    onChange: this.handleChange,
                  },

                  {
                    label: renderTranslation(store, "forms.street_text"),
                    type: "text",
                    name: "street",
                    placeholder: renderTranslation(store, "forms.street_placeholder_text"),
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.street_text"), this.customerAddressDetailForm.controls['street'].errors),
                    defaultValue: this.customerAddressDetailForm.controls.street.value,
                    onChange: this.handleChange,
                  },
                  {
                    label: renderTranslation(store, "forms.postal_address_text"),
                    type: "text",
                    name: "postal_code",
                    bsClass: "form-control",
                    placeholder: renderTranslation(store, "forms.postal_address_placeholder_text"),
                    error: this.errorMassage(renderTranslation(store, "forms.postal_address_text"), this.customerAddressDetailForm.controls['postal_code'].errors),
                    defaultValue: this.customerAddressDetailForm.controls.postal_code.value,
                    onChange: this.handleChange,
                  },

                ]}
              />

              <FormInputs
                ncols={["col-md-6", "col-md-6"]}
                proprieties={[
                  {
                    label: renderTranslation(store, "forms.city_text"),
                    type: "text",
                    name: "city",
                    placeholder: renderTranslation(store, "forms.city_placeholder_text"),
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.city_text"), this.customerAddressDetailForm.controls['city'].errors),
                    defaultValue: this.customerAddressDetailForm.controls.city.value,
                    onChange: this.handleChange,
                  },
                  {
                    label: renderTranslation(store, "forms.country_text"),
                    type: "text",
                    name: "country",
                    bsClass: "form-control",
                    placeholder: renderTranslation(store, "forms.country_placeholder_text"),
                    error: this.errorMassage(renderTranslation(store, "forms.country_text"), this.customerAddressDetailForm.controls['country'].errors),
                    defaultValue: this.customerAddressDetailForm.controls.country.value,
                    onChange: this.handleChange,
                  },

                ]}
              />

              <FormInputs
                ncols={["col-md-6", "col-md-6"]}
                proprieties={[
                  {
                    label: renderTranslation(store, "forms.latitude_text"),
                    type: "text",
                    name: "lat",
                    placeholder: renderTranslation(store, "forms.latitude_placeholder_text"),
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.latitude_text"), this.customerAddressDetailForm.controls['lat'].errors),
                    defaultValue: this.customerAddressDetailForm.controls.lat.value,
                    onChange: this.handleChange,
                  },
                  {
                    label: renderTranslation(store, "forms.longitude_text"),
                    type: "text",
                    name: "lon",
                    bsClass: "form-control",
                    placeholder: renderTranslation(store, "forms.longitude_placeholder_text"),
                    error: this.errorMassage(renderTranslation(store, "forms.longitude_text"), this.customerAddressDetailForm.controls['lon'].errors),
                    defaultValue: this.customerAddressDetailForm.controls.lon.value,
                    onChange: this.handleChange,
                  },

                ]}
              />
                
                </Form>
              </CardBody>
              <CardFooter>
                <Button bsStyle="info" pullRight fill onClick={this.handleSubmit}>
                  {renderTranslation(store, "buttons.update_customer_address")}
                </Button>
              </CardFooter>
            </Card>
        </div>
       
      );
    } else {
      return (<div></div>)
    }
  }
}
const mapStateToProps = (state) => {
  return {
    addressDetail: state.customer.addressDetail,
    profileDetails: state.customer.profileOverview,
    store: state
  }
}

const mapDispatchToProps = {
  updateCustomerAddressAction: UpdateCustomerAddressAction,

}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContactDetail);
