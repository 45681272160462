import React from "react";
import i18n from "../../../i18n";
import { useStore } from "react-redux";
import { renderTranslation } from "services";

const CopyRightText = (props) => {
  const ReduxStore = useStore()
  let store = ReduxStore.getState() 
  return (
      <div>
      {renderTranslation(store, "copyright_text")}
      </div>
  );
}

export default CopyRightText;
