

// export default AdminNavbar;
/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { withTranslation } from 'react-i18next';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
import { useStore, useDispatch } from 'react-redux'
import { setActiveLocale, setTranslations, loadingTranslations } from "modules/Translations/actions";
import routes from "routes/admin-route.js";
import {httpHandler, getCurrentUser, baseEndpoint} from "../../../services"
import Logo from "../../../assets/img/favicon.ico";
import { renderTranslation } from "services";
const AdminNavbar = (props) => {
  const dispatch = useDispatch()
  const store = useStore()

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const [selectedLanguage, setSelectedLanguage] = React.useState(false);
  const [languageOptions, setLanguageOptions] = React.useState([]);
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  React.useEffect(() => {
    setSelectedLanguage(store.getState().activeLocale.activeLocale ? store.getState().activeLocale.activeLocale : process.env.REACT_APP_DEFAULT_LOCALE);
    renderLocales();
  }, []);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  const renderLocales = () => {
    const http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint);
    http.get(`/i18n/locales`)
    .then(result => {
      setLanguageOptions(result);
    })
    .catch(error => console.log('error', error));
  }

  const setNewLocale = (locale) => {
    setSelectedLanguage(locale);

    if (locale === 'nl') {
      // if translations already in store then return translations
      if (
        Object.keys(store.getState().translations.locales.nl).length === 0 &&
        store.getState().translations.locales.nl.constructor === Object
      ) {
        getTranslations('nl');
        dispatch(setActiveLocale('nl'));

      } else {
        dispatch(setActiveLocale('nl'));
      }
    }

    if (locale === 'en') {
      // if translations already in store then return translations
      if (
        Object.keys(store.getState().translations.locales.en).length === 0 &&
        store.getState().translations.locales.en.constructor === Object
      ) {
        getTranslations('en');
        dispatch(setActiveLocale('en'));
      } else {
        dispatch(setActiveLocale('en'));
      }
    }
    if (locale === 'de') {
      // if translations already in store then return translations
      if (
        Object.keys(store.getState().translations.locales.de).length === 0 &&
        store.getState().translations.locales.de.constructor === Object
      ) {
        getTranslations('de');
        dispatch(setActiveLocale('de'));
      } else {
        dispatch(setActiveLocale('de'));
      }
    }
  }

  const getTranslations = (locale) => {
    dispatch(loadingTranslations(true));

    const http = httpHandler(baseEndpoint.translationServiceBaseEndpoint);
    http.get(`/translations?_locale=${locale}&tags_contains=partnerdash`)
    .then(data => {
      const localeResult = { [locale]: { ...data } };
        dispatch(setTranslations(localeResult));
        dispatch(setActiveLocale(locale));
        dispatch(loadingTranslations(false));
    })
    .catch(error => console.log('error', error));
 }

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return renderTranslation(store.getState(), routes[i].translateKey);
         } 
          if(routes[i].path.indexOf('/:')) {
         const urlLocation =  window.location.href.split("/")
           if( String(urlLocation[5])  === String(routes[i].path.split("/")[1]) ||
            String(urlLocation[4])  === String(routes[i].path.split("/")[1])) {
            return renderTranslation(store.getState(), routes[i].translateKey);
           }
         }
      }
    }
    return activeRoute;
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };

  const logout = () =>  {
    localStorage.removeItem(process.env.REACT_APP_CURRENT_USER_KEY)
    props.history.push("/auth/login");
  };
  
  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
             {props.brandText}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {/* <InputGroup className="search-bar" tag="li">
                <Button
                  color="link"
                  data-target="#searchModal"
                  data-toggle="modal"
                  id="search-button"
                  onClick={toggleModalSearch}
                >
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup>
              */}
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div>
                    <p className="pt-1"> <span class={'flag-icon flag-icon-'+(selectedLanguage ==='en'? 'gb': selectedLanguage )}> </span> <span className="text-transform-uppercase"> {selectedLanguage} </span>  </p>
                    <b className="caret d-lg-block d-xl-block" />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  {languageOptions.length > 0 && languageOptions.map((item) => {
                    return (
                      <NavLink tag="li">
                        <DropdownItem className="nav-item" onClick={() => setNewLocale(item.code)}><span class={'flag-icon flag-icon-'+(item.code ==='en'? 'gb': item.code )}> </span> {item.code.toUpperCase()}</DropdownItem>
                      </NavLink>
                      )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo mr-1 navbar-photo">
                    <object className="partner-logo" data={getCurrentUser()?.data.logo ? baseEndpoint.mediaServiceBaseEndPoint + "/" + getCurrentUser().data.logo : ""}>
                      <img src={Logo} alt="logo" />
                    </object>
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => props.history.push("/admin/profile")}>{renderTranslation(store.getState(), 'navbar.profile_text')}</DropdownItem>
                  </NavLink>
                  {/* <NavLink tag="li">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                  </NavLink> */}
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item"onClick={logout}>{renderTranslation(store.getState(), 'navbar.logout_text')}</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default  withTranslation()(AdminNavbar);
