import { createAppointment } from '../services'
import {FETCHED_TASK_DETAILS_SUCCESSFULLY, FETCHED_TASKS_SUCCESSFULLY, SET_TASK_ID} from '../constants';

/**
 * 
 * @param object payload 
 */
export const FetchTasksAction = (payload) => (dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    createAppointment.fetchTask().then((response) => {
      dispatch({ type: FETCHED_TASKS_SUCCESSFULLY, payload: response });
      resolve({ success: true })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}

export const FetchTaskDetailsAction = (payload) => (dispatch, _getState) => {
  dispatch({ type: SET_TASK_ID, payload: payload })
  return new Promise((resolve, _reject) => {
    createAppointment.fetchTaskDetails(payload).then((response) => {
      const task = response.data.tasks[0];
      dispatch({ type: FETCHED_TASK_DETAILS_SUCCESSFULLY, payload: task });
      resolve({ success: true })
    })
    .catch((error) => {
      resolve({ success: false })
    })
  });
}
