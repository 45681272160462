import { partnerContactsService } from "../services";

/**
 * @param object payload
 */
export const CreatePartnersContactAction = (payload) => (
  _dispatch,
  _getState
) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    partnerContactsService
      .create(result)
      .then((resp) => {
        resolve({ success: true, data: resp });
      })
      .catch((error) => {
        resolve({ success: false });
      });
  });
};

/**
 * @param object payload
 * @param string id (uuid of contact)
 */
export const UpdatePartnersContactAction = (payload, id) => (
  _dispatch,
  _getState
) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    partnerContactsService
      .update(result, id)
      .then((resp) => {
        resolve({ success: true, data: resp });
      })
      .catch((error) => {
        resolve({ success: false });
      });
  });
};

/**
 * @param string id (uuid of contact)
 */
export const FetchSinglePartnersContactAction = (id) => (
  _dispatch,
  _getState
) => {
  return new Promise((resolve, _reject) => {
    partnerContactsService
      .fetchSignal(id)
      .then((resp) => {
        resolve({ success: true, data: resp });
      })
      .catch((error) => {
        resolve({ success: false });
      });
  });
};

/**
 * Fetch all partners contact
*/
export const FetchAllPartnersContactsAction = () => (
    _dispatch,
    _getState
  ) => {
    return new Promise((resolve, _reject) => {
      partnerContactsService
        .fetch()
        .then((resp) => {
          resolve({ success: true, data: resp.data });
        })
        .catch((error) => {
          resolve({ success: false });
        });
    });
  };

/**
 * Delete partners contact
*/
export const DeletePartnersContactAction = (id) => (
    _dispatch,
    _getState
  ) => {
    return new Promise((resolve, _reject) => {
      partnerContactsService
        .delete(id)
        .then((resp) => {
          resolve({ success: true, data: resp });
        })
        .catch((error) => {
          resolve({ success: false });
        });
    });
  };
