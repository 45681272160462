import React, { Component } from "react";
import { connect } from "react-redux";

import { Table, UncontrolledTooltip, Button } from "reactstrap"
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { mediaBaseUrl } from "../../../../services"
import AddEditPartnersContact from "./AddEditPartnersContact.jsx";
import avatar from "assets/img/default-avatar.png";
import {
  FetchAllPartnersContactsAction,
  DeletePartnersContactAction,
  RemoveProfileImageAction
} from "../../actions"
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import ConfirmAlertBox from "../../../shared/Alert/ConfirmAlertBox"
import { renderTranslation } from "services/renderTranslation.service";
class PartnerContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      type: null,
      id: null,
      contacts: [],
      selectedContact: null,
      confirmDeleteModalShow: false

    };
  }

  componentDidMount() {
    this.fetchContacts();
  }

  fetchContacts = () => {
    this.props.fetchAllContactsAction().then((resp) => {
      if (resp.success) {
        this.setState({ contacts: resp.data });
      }
    })
  }

  addNewContact = () => {
    this.setState({ show: true });
    this.setState({ type: "add" });
  };

  closeModel = () => {
    this.setState({ show: false });
    this.setState({ type: null });
  };

  confirmDeletePartnerContact = () => {
    this.setState({ confirmDeleteModalShow: false })
    this.props.deleteContactAction(this.state.selectedContact.uuid).then((_resp) => {
      this.removeProfileImage(this.state.selectedContact.profile_image)
      this.fetchContacts();
    })
  };

  /**
   * Delete Partner's Contact
   * @param {object} selectedContact 
   */
  handleDelete = (selectedContact) => {
    this.setState({ confirmDeleteModalShow: true, selectedContact: selectedContact })
  };

  /**
   * Edit Partner's Contact
   * @param {object} selectedContact 
   */
  handleEdit = (selectedContact) => {
    this.setState({ show: true, selectedContact: selectedContact, type: "edit" })
  };

  removeProfileImage = (path) => {
    if (path && path !== '') {
      this.props.removeProfileImageAction(path).then((_resp) => {})
    }
  };

  render() {
    const { store } = this.props;
    return (
      <div className="content partner-content">

        <Card>
          <CardHeader>
            <CardTitle tag="h4">{renderTranslation(store, "profile.profile_contact_person_detail_title")}</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={12}>
                <div className="text-right">
                  <Button
                    bsStyle="primary"
                    onClick={this.addNewContact}
                  >
                    {renderTranslation(store, "buttons.add_new_contact_person")}
                  </Button>
                </div>
              </Col>
              <div className="space-50"></div>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center">{renderTranslation(store, "profile.contacts.table.heading_1")}</th>
                    <th className="text-center">{renderTranslation(store, "profile.contacts.table.heading_2")}</th>
                    <th className="text-center">{renderTranslation(store, "profile.contacts.table.heading_3")}</th>
                    <th className="text-center">{renderTranslation(store, "profile.contacts.table.heading_4")}</th>
                    <th className="text-center">{renderTranslation(store, "profile.contacts.table.heading_5")}</th>
                    <th className="text-center"> {renderTranslation(store, "profile.contacts.table.heading_6")}</th>
                  </tr>
                </thead>
                <tbody>

                  {(this.state.contacts.length > 0 && this.state.contacts.map((contact) => {
                    return (
                      <tr key={contact.id}> 
                        <td className="text-center">
                          {" "}
                          <img
                            src={contact.profile_image && contact.profile_image !== "" ? mediaBaseUrl + contact.profile_image : avatar}
                            className="partner-content-table-avatar"
                            alt="avatar"
                          />
                        </td>
                        <td className="text-center">{contact.name}</td>
                        <td className="text-center"> {contact.position}</td>
                        <td className="text-center">{contact.email}</td>
                        <td className="text-center">{contact.phone}</td>
                        <td className="text-right">
                          <Button
                            className="btn-link btn-icon"
                            color="success"
                            id="tooltip-edit-contacts"
                            size="sm"
                            title='Edit'
                            type="button"
                            onClick={() => this.handleEdit(contact)}
                          >
                            <i className="fa fa-edit" />
                          </Button>
                          <UncontrolledTooltip delay={0} target="tooltip-edit-contacts">
                            {renderTranslation(store, "profile.contacts.edit_contact_text")}
                          </UncontrolledTooltip>

                          <Button
                            className="btn-link btn-icon"
                            color="danger"
                            id="tooltip-delete-contacts"
                            size="sm"
                            title='Delete'
                            type="button"
                            onClick={() => this.handleDelete(contact)}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                          <UncontrolledTooltip delay={0} target="tooltip-delete-contacts">
                            {renderTranslation(store, "profile.contacts.delete_contact_text")}
                          </UncontrolledTooltip>

                        </td>

                      </tr>
                    )
                  }))}
                </tbody>
              </Table>
            </Row>
            <AddEditPartnersContact
              show={this.state.show}
              type={this.state.type}
              close={this.closeModel}
              refresh={this.fetchContacts}
              selectedContact={this.state.type === 'edit'? this.state.selectedContact : null}
            />
          </CardBody>
          <CardFooter>

          </CardFooter>
        </Card>
        <ConfirmAlertBox
          open={this.state.confirmDeleteModalShow}
          header={renderTranslation(store, 'alert.header_sure_text')}
          body={renderTranslation(store, 'alert.body_cancel_appointment_text')}
          accept={this.confirmDeletePartnerContact}
          close={() => this.setState({ confirmDeleteModalShow: false })}
          confirmBtnText={renderTranslation(store, 'buttons.continue_text')}
          cancelBtnText={renderTranslation(store, 'buttons.cancel_text')}
          color="danger"
          icon="fa fa-trash"
        ></ConfirmAlertBox>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appointments: state.feed.feed,
    store: state
  };
};

const mapDispatchToProps = {
  fetchAllContactsAction: FetchAllPartnersContactsAction,
  deleteContactAction: DeletePartnersContactAction,
  removeProfileImageAction: RemoveProfileImageAction
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PartnerContacts));

