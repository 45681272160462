import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import queryString from 'query-string';
import {
  setTranslations,
  setActiveLocale,
  loadingTranslations,
} from "./modules/Translations/actions";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import UILayout from "layouts/Ui.jsx";
import {useDispatch, useSelector} from "react-redux";
import { httpHandler, baseEndpoint } from "services";
import PrintInvoice from "./modules/Appointments/views/PrintInvoice.jsx";

export default function App() {

  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.loadingTranslations);

  useEffect(() => {
    fetchDefaultTranslation();
    let params = queryString.parse(window.location.href.split('?')[1]);
    if(params && params.parkedJob) {
      localStorage.setItem('parkedJob', JSON.stringify(params))
    }
  }, []);

  const fetchDefaultTranslation = () => {
    const http = httpHandler(baseEndpoint.translationServiceBaseEndpoint);
    http
      .get(
        `${process.env.REACT_APP_GATEWAY}/translations?_locale=${process.env.REACT_APP_DEFAULT_LOCALE}&tags_contains=partnerdash`
      )
      .then((response) => {
        const locale = { nl: { ...response } };
        dispatch(setTranslations(locale));
        dispatch(setActiveLocale(process.env.REACT_APP_DEFAULT_LOCALE));
        dispatch(loadingTranslations(false));
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
        {loading ? (
          <div>Loading...</div>
        ) : (
            <Switch>
                <Route path="/auth" render={props => <AuthLayout {...props} />}/>
                <Route path="/admin" render={props => <AdminLayout {...props} />}/>
                <Route path="/ui" render={props => <UILayout {...props} />}/>
                <Route path="/invoice/:id" render={props => <PrintInvoice {...props} />}/>
                <Redirect from="/" to="/auth/login"/>
            </Switch>

        )}

    </>
  );
}
