import React, { Component } from "react";
import { connect } from "react-redux";
import "../../styles/invoice.scss";
import i18n from "../../../../i18n"
import moment from "moment-timezone";
import { renderTranslation } from "services";
class Invoice extends Component {
    BTW = '21%';
    constructor(props) {
        super(props);
      
    }

    render() {
        const { store } = this.props;
        return (

            <div className="single-appointment-invoice">
                {this.props.selectedAppointmentDetail && (
                    <div className="custom-container">
                        <div class="col-sm-9">
                            <div className="row justify-content-space-between">
                                <div>

                                    <img src="https://zoofy.nl/assets/zoofy/images/zoofy.svg" className="admin-image" />

                                </div>
                                <div>
                                    <div>
                                        <div><b>{renderTranslation(store, 'appointments.invoice.administration_text')}</b></div>
                                        <div>{process.env.REACT_APP_INVOICE_SENDER_NAME}</div>
                                        <div>{process.env.REACT_APP_INVOICE_SENDER_ADDRESS}</div>
                                        <div>{process.env.REACT_APP_INVOICE_SENDER_CITY}</div>
                                        <div>{process.env.REACT_APP_INVOICE_SENDER_EMAIL}</div>
                                        <div>KvK: {process.env.REACT_APP_INVOICE_SENDER_CHAMBER_OF_COMMERCE}</div>
                                    </div>
                                </div>
                            </div>
                            {/* Customer */}
                            <div>
                                <div>{this.props.selectedAppointmentDetail.customer.name}</div>
                                <div>{`${this.props.selectedAppointmentDetail.customer.address.street}  ${this.props.selectedAppointmentDetail.customer.address.suffix}`}</div>
                                <div>{`${this.props.selectedAppointmentDetail.customer.address.postal_code}  ${this.props.selectedAppointmentDetail.customer.address.city}`}</div>
                            </div>
                            {/* Customer */}


                            {/* Task Detail */}
                            <div className="single-appointment-invoice-task">
                                <h3>{renderTranslation(store, 'appointments.invoice.invoice_text')} #{this.props.selectedAppointmentDetail.invoice.id}</h3>
                                <div className="flex justify-content-space-between">
                                    <div>
                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td className="pr-3">{renderTranslation(store, 'appointments.invoice.job_number_text')}:</td>
                                                    <td>{this.props.selectedAppointmentDetail.task.id}</td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-3">{renderTranslation(store, 'appointments.invoice.job_description_text')}:</td>
                                                    <td>{this.props.selectedAppointmentDetail.task.singular}</td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-3">{renderTranslation(store, 'appointments.invoice.job_address_text')}:</td>
                                                    <td>{`${this.props.selectedAppointmentDetail.address.street} ${this.props.selectedAppointmentDetail.address.suffix}  ${this.props.selectedAppointmentDetail.address.postal_code}  ${this.props.selectedAppointmentDetail.address.city}`}</td>
                                                </tr>

                                                <tr>
                                                    <td className="pr-3">{renderTranslation(store, 'appointments.invoice.professional_text')}:</td>
                                                    <td>{this.props.selectedAppointmentDetail.professional.name}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{renderTranslation(store, 'appointments.invoice.invoice_date_text')}:</td>
                                                    <td>{moment(this.props.selectedAppointmentDetail.invoice.created_at).format('DD-MM-YYYY')}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* Task Detail */}
                            {/* Items Details */}


                            <div className="item-details table-responsive">
                                <table className="table">
                                    <thead>
                                        <th></th>
                                        <th>{renderTranslation(store, 'appointments.invoice.table.description_text')}</th>
                                        <th>{renderTranslation(store, 'appointments.invoice.table.amount_text')}</th>
                                        <th>{renderTranslation(store, 'appointments.invoice.table.total_text')}</th>
                                        <th>{renderTranslation(store, 'appointments.invoice.table.btw_text')}</th>
                                    </thead>
                                    <tbody>
                                        {this.props.selectedAppointmentDetail.invoice.invoice_items.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.description}</td>
                                                    <td>€ {item.price}</td>
                                                    <td>€ {item.total}</td>
                                                    <td>{this.BTW}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>


                            {/* Items Details */}

                            {/* Total detail */}
                            <div className="total-detail-block">
                                <div className="invoice-status-card">
                                    {this.props.selectedAppointmentDetail.invoice_status === 'paid' ? renderTranslation(store, 'appointments.invoice.paid_text') : renderTranslation(store, 'appointments.invoice.unpaid_text') }
                                </div>
                                <div className="total-detail-block-inner">
                                    <div className="item-block">
                                        <div> {renderTranslation(store, 'appointments.invoice.table.sub_total_text')}</div>
                                        <div>€ {this.props.totalInvoice}</div>
                                    </div>
                                    {/* <div className="item-block">
                                           <div>21% btw</div>
                                           <div>€ 68,59</div>
                                       </div> */}
                                    <hr />
                                    <div className="item-block">
                                        <div>{renderTranslation(store, 'appointments.invoice.table.total_text')}</div>
                                        <div>€ {this.props.totalInvoice}</div>
                                    </div>
                                </div>
                            </div>

                            {/* Total detail */}

                            {/* Footer */}
                            <div className="footer">
                                <hr />
                                <div>
                                    We verzoeken u vriendelijk het bovenstaande bedrag van € {this.props.totalInvoice} voor {moment(this.props.selectedAppointmentDetail.invoice.created_at).format('DD-MM-YYYY')} te voldoen op onze
                  bankrekening onder vermelding van het factuurnummer Concept #{this.props.selectedAppointmentDetail.invoice.id}. Voor vragen kunt u contact opnemen per e-
                  mail.
                                   </div>
                            </div>
                            {/* Footer */}
                        </div>
                    </div>


                )}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        store: state
    };
};

export default connect(mapStateToProps)(Invoice);
