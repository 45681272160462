import React from 'react';
import {Button, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, FormGroup, Row} from "reactstrap";
import {ControlLabel} from "react-bootstrap";
import SearchableSelect from "../../../shared/Select/SearchableSelect";
import avatar from "../../../../assets/img/default.png";
import UserCard from "../../../shared/Card/UserCard";
import ConsumerProfile from "./ConsumerProfile";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {UpdateConsumerAction} from "../../actions";
import { renderTranslation } from "services";

export default function ConsumerSelection(props) {
    const store = useSelector((state) => state);
    const { consumersList, consumer, errors } = useSelector(state => state.createAppointment);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleConsumerChange = (value) => {
        dispatch(UpdateConsumerAction(value));
    }

    const createNewConsumer = () => {
        dispatch(UpdateConsumerAction(-1));
    }

    const renderConsumerInfo = () => {
        if (consumer && consumer.address) {
            return (
                <ConsumerProfile key={consumer.id} store={store} />
            )
        }
        return (
            <div className="card-user-info padding-top-25">
                <p className="no-user-text">
                    {renderTranslation(store,"appointments.no_customer_text")}
                </p>
            </div>
        );
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h4">
                    {renderTranslation(store,"appointments.who_section_header")}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <CardSubtitle className="mb-2">
                    {renderTranslation(store,"appointments.consumer_create_update_message")}
                </CardSubtitle>
                <Row>
                    <Col md={6}>
                        <SearchableSelect
                            label={renderTranslation(store,"appointments.select_consumer")}
                            value={consumer ? consumer.id : null}
                            handleChange={handleConsumerChange}
                            placeholder={renderTranslation(store,"appointments.select_consumer")}
                            options={consumersList}
                            labelProp="name"
                            valueProp="id"
                        />
                        {errors.consumer ? (
                            <span className="text-danger">{errors.consumer}</span>
                        ) : ''}
                    </Col>
                    <Col md={2} className="flex align-items-center justify-content-center">
                        <div className="w-100 text-center mt-4">
                            OR
                        </div>
                    </Col>
                    <Col md={4} className="flex align-items-center justify-content-center">
                        <Button
                            block
                            onClick={createNewConsumer}
                            className="mt-3"
                        >
                            {renderTranslation(store,"buttons.create_or_update_customer")}
                        </Button>
                    </Col>
                </Row>

                {renderConsumerInfo()}
            </CardBody>
        </Card>
    )
}
