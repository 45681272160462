import { parkedJobService } from '../services'

/**
 * Submit parked appointment 
 * @param object payload 
 */
export const SubmitParkedJobAction = (payload) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload }
    parkedJobService.submitParkedJob(result).then((response) => {
      resolve({ success: true, data: response })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}