import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

/**
 * API service for partner
 */
class PartnerProfileService {
  http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint)
  }

  /**
   * @param {object} payload 
   * @param {string} id  //uuid of partner 
   */
  update(payload, id) {
    return this.http.put(apiUrls.profile.updatePartner(id), payload)
  }


}

export const partnerProfileService = new PartnerProfileService();
