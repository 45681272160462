
import { subUserService } from "../services";

/**
 * Fetch all consumers
 * @param object payload
 * @return Promise
 */
export const FetchSubUsersAction = (payloads) => (_dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
      subUserService
        .fetchAllSubUsers(payloads.params, payloads.page)
        .then(async (resp) => {
          resolve({ success: true, data: resp });
        })
        .catch((error) => {
          resolve({ success: false });
        });

    });
  };

  /**
 * Fetch create consumer
 * @param object payload
 * @return Promise
 */
export const CreateSubUsersAction = (payloads) => (_dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    subUserService
      .createSubUser(payloads)
      .then(async (resp) => {
        resolve({ success: true, data: resp });
      })
      .catch((error) => {
        resolve({ success: false, error });
      });

  });
};
