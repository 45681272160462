import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

export default class CreateAppointmentService {
  http = "";
  constructor() {
    /**
     * TODO: For the time being , we are using static token for API
     */
    // this.httptest =   httpHandler(
    //   baseEndpoint.consumerServiceBaseEndPoint,
    //   "SB5wyf0xMLrWZZPDkw0C21qkhqkM2Dhz9yZhjwwPLqneJQayjZ8xN6senzlm"
    // );

    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint)
  }

  /**
   * Reset password(Add new password)
   * @param {object} params
   **/
  fetchTask(params) {
    return this.http.get(apiUrls.createAppointment.tasks);
  }

  fetchTaskDetails(data) {
    return this.http.get(apiUrls.createAppointment.taskDetails(data));
  }

  fetchPartners(params) {
    return this.http.get(apiUrls.createAppointment.partner, params);
  }

  verifyAddress(params) {
    return this.http.post(apiUrls.verify.verifyAddress, params);
  }

  createAppointmentAction(params) {
    return this.http.post(apiUrls.createAppointment.create, JSON.parse(params));
  }

  createAppointmentSubuserAction(params) {
    return this.http.post(apiUrls.createAppointment.subuserCreate, JSON.parse(params));
  }

  findTaskRate(data) {
    return this.http.get(apiUrls.createAppointment.taskRates(data));
  }

  verifyCouponCode(payload) {
    return this.http.get(apiUrls.createAppointment.verifyCoupon(payload.code));
  }
}

export const createAppointment = new CreateAppointmentService();
