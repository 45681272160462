import { httpHandler, apiUrls, baseEndpoint } from "../../../services";
import { BehaviorSubject } from "rxjs";

class CountryService {
  
    http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.translationServiceBaseEndpoint);
  }

  subject = new BehaviorSubject(false);
  /**
   * Fetch Countries
   * 
   */
  fetch() {
    return this.http.get(apiUrls.auth.getCountries);
  }
}

export const countryService = new CountryService();
