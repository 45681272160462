import { createAppointment } from '../services'
import {
    CREATED_APPOINTMENT_SUCCESSFULLY,
    SET_ADDRESS,
    UPDATE_DESCRIPTION,
    UPDATE_DYNAMIC_ANSWERS,
    UPDATE_CONSUMER,
    FETCH_CONSUMERS_LIST,
    CLEAR_CONSUMER,
    SET_APPOINTMENT_DATES,
    SET_VALIDATION_ERRORS,
    SET_COUPON_CODE,
    SET_WARRANTY_PACKAGE,
    SET_AVAILABLE_RATES,
    SET_ASSIGNMENT,
    RESET_DEFAULT,
    SET_COUPON_ERROR,
    SET_COUPON_SUCSESS
} from '../constants';
import {consumerService} from "../../Consumer/services";
import {map} from "lodash";

/**
 * Create new Appointments of customer
 * @param object payload
 */

export const FetchConsumersListAction = (payloads) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    consumerService
        .fetchAllConsumers(payloads.params, payloads.page)
        .then(async (resp) => {
          const data = resp.data;
          const consumerListingToFind = {};
          const consumers = [];
          map(data, (consumer) => {
            consumerListingToFind[consumer.id] = consumer;
          });
          dispatch({ type: FETCH_CONSUMERS_LIST, payload: consumerListingToFind });
          resolve({success: true});
        })
        .catch((error) => {
          console.log('error ', error)
          resolve({ success: false })
        })

  });
};

export const FetchAllConsumersListAction = (payloads) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    consumerService
        .fetchAllConsumersList(payloads.params, payloads.page)
        .then(async (resp) => {
          const data = resp;
          const consumerListingToFind = {};
          map(data, (consumer) => {
            consumerListingToFind[consumer.id] = consumer;
          });
          dispatch({ type: FETCH_CONSUMERS_LIST, payload: consumerListingToFind });
          resolve({success: true});
        })
        .catch((error) => {
          console.log('error ', error)
          resolve({ success: false })
        })

  });
};

export const CreateAppointmentAction = (payload) => (dispatch, _getState) => {
  let role = localStorage.getItem('role');

  switch (role) {
    case 'partner':
      return new Promise((resolve, _reject) => {
        createAppointment.createAppointmentAction(payload).then((response) => {
    
          dispatch({ type: CREATED_APPOINTMENT_SUCCESSFULLY, payload: response });
          resolve({ success: true })
    
        })
        .catch((error) => {
          console.log('error ', error)
          resolve({ success: false })
        })
      });
      break;
    case 'subuser':
      return new Promise((resolve, _reject) => {
        createAppointment.createAppointmentSubuserAction(payload).then((response) => {
    
          dispatch({ type: CREATED_APPOINTMENT_SUCCESSFULLY, payload: response });
          resolve({ success: true })
    
        })
        .catch((error) => {
          console.log('error ', error)
          resolve({ success: false })
        })
      });
      break;
    default:
  }
}

export const setConsumerAddress = (payload) => (dispatch, _getState) => {
    dispatch({ type: SET_ADDRESS, payload });
}

export const UpdateDynamicAnswersAction = (payload) => (dispatch, _getState) => {
  dispatch({ type: UPDATE_DYNAMIC_ANSWERS, payload: payload });
}

export const UpdateDescriptionAction = (payload) => (dispatch, _getState) => {
  dispatch({ type: UPDATE_DESCRIPTION, payload: payload });
}

export const UpdateConsumerAction = (payload) => (dispatch, _getState) => {
  return new Promise((resolve, _reject) => {
    if (payload && payload !== -1) {
      const consumersList = _getState().createAppointment.consumersList;
      if (consumersList[payload]) {
          const consumer = consumersList[payload];
          const formattedConsumer = {
              id: consumer.id,
              first_name: consumer.first_name,
              last_name: consumer.last_name,
              email: consumer.email,
              telephone: consumer.telephone,
              address: {
                  postal_code: consumer.address.postal_code,
                  suffix: consumer.address.suffix
              }
          }
          dispatch({ type: UPDATE_CONSUMER, payload: formattedConsumer });
          dispatch(setConsumerAddress(consumer.address));
      }
    } else if(payload === -1) {
      const consumer = {
        id: -1,
        address: {}
      }
      dispatch({ type: UPDATE_CONSUMER, payload: consumer });
      dispatch(setConsumerAddress({}));
    } else {
        dispatch({ type: CLEAR_CONSUMER });
        dispatch(setConsumerAddress({}));
    }
  });
}

export const UpdateConsumerDataAction = (payload) => (dispatch, _getState) => {
    dispatch({ type: UPDATE_CONSUMER, payload: payload });
}

export const SetAppointmentDates = (payload) => (dispatch, _getState) => {
    dispatch({ type: SET_APPOINTMENT_DATES, payload: payload });
}

export const SetValidationErrors = (payload) => (dispatch, _getState) => {
    dispatch({ type: SET_VALIDATION_ERRORS, payload: payload });
}

export const ValidateCouponCodeAction = (payload) => (dispatch, _getState) => {
    return new Promise((resolve, _reject) => {
        createAppointment.verifyCouponCode(payload)
            .then((response) => {
                dispatch({ type: SET_COUPON_SUCSESS, payload: response.message });
                resolve(response);
            })
            .catch((error) => {
                dispatch({ type: SET_COUPON_ERROR, payload: error });
                _reject({success: false, error})
            })
    });
}

export const SetCouponCode = (payload) => (dispatch, _getState) => {
    if (payload && payload.length) {
        dispatch({ type: SET_COUPON_CODE, payload: payload });
    } else {
        dispatch({ type: SET_COUPON_CODE, payload: null });
    }
}

export const SetCouponError = (payload) => (dispatch, _getState) => {
    dispatch({ type: SET_COUPON_ERROR, payload: payload });
}

export const SetCouponSuccess = (payload) => (dispatch, _getState) => {
    dispatch({ type: SET_COUPON_SUCSESS, payload: payload });
}

export const SetWarrantyPacakge = (payload) => (dispatch, _getState) => {
    dispatch({ type: SET_WARRANTY_PACKAGE, payload: payload });
}

// export const SetAvailableRates = (payload) => (dispatch, _getState) => {
//     dispatch({ type: SET_AVAILABLE_RATES, payload: payload });
// }

export const SetAssignment = (payload) => (dispatch, _getState) => {
    dispatch({ type: SET_ASSIGNMENT, payload: payload });
}

export const ResetDefault = (payload) => (dispatch, _getState) => {
    dispatch({ type: RESET_DEFAULT });
}