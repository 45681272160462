import { httpHandler, apiUrls, baseEndpoint } from "../../../services";

class AppointmentDetailService {
  http = "";
  constructor() {
    this.http = httpHandler(baseEndpoint.partnersServiceBaseEndPoint);
  }

  /**
   * create appointment
   * @param {object} payload 
   */
  createComments(payload) {
    return this.http.post(apiUrls.appointments.comments.create, payload);
  }

  /**
   * Fetch appointment
   * @param string appointmentId 
   */
  fetchComments(appointmentId) {
    return this.http.get(apiUrls.appointments.comments.fetch(appointmentId));
  }

  fetchCancelReason() {
    return this.http.get(apiUrls.appointments.cancel_reason);
  }

  fetchComplaints(payload, page) {
    return this.http.post(`${apiUrls.appointments.complaints}?page=${page}&per_page=5`, payload);
  }

  /**
   * @param {string} appointmentUuid 
   */
  cancelAppointment(appointmentUuid) {
    let payload = {
      "appointment_uuid": appointmentUuid
    }
    return this.http.post(apiUrls.appointments.cancellation, payload);
  }
}




export const appointmentDetailService = new AppointmentDetailService();