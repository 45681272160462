import {FETCHED_FEED_SUCCESSFULLY} from '../constants';

const initial_state = {
  feed: {}
}
const FeedReducer = (state = initial_state, action) => {
  switch (action.type) {
    case FETCHED_FEED_SUCCESSFULLY:
      return {...state, feed: {...action.payload}};
    default:
      return state;
  }
}
export default FeedReducer;