import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Col, Label, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import { map } from 'lodash';
import {getScheduledRate, getUrgentRate} from "../helpers/Assignment";
import moment from "moment";
import { renderTranslation } from "services";

export default function AssignmentCompactView() {
    const store = useSelector((state) => state);
    const { createAppointment } = useSelector(state => state);

    const renderDynamicFields = () => {
        if(createAppointment.dynamicAnswers.length > 0) {
            return createAppointment.dynamicAnswers.map(singleField => {
                if (singleField?.values?.length > 0 && singleField?.values[0]){
                    return (
                        <Row className="mb-1" key={singleField.field}>
                        <Col md={6}>
                            <span className="font-weight-bold">{singleField.field}</span>
                        </Col>
                        <Col md={6} la>
                            <span>{singleField.values[0]}</span>
                        </Col>
                    </Row>
                    )
                }
            })
        }
    }

    const renderTask = () => {
            return (
                <div>
                    <h4>{renderTranslation(store,"appointments.what_section_header")}</h4>
                    {(createAppointment.task && createAppointment.task.heading) ? <h4>{renderTranslation(store,"appointments.table.job_text")}: {createAppointment.task.heading}</h4> : null }
                    {createAppointment.dynamicAnswers.length > 0 ? renderDynamicFields() : '' }
                    <hr className='mt-2'/>
                    {createAppointment.description && createAppointment.description.length ? (
                        <React.Fragment>
                            <div className="mt-2 mb-1">
                            <h4 className='mb-0 font-weight-bold' style={{color: "#525f7f"}}>{renderTranslation(store,"appointments.invoice.table.description_text")}</h4>
                            <p>{createAppointment.description}</p>
                        </div>
                        <hr/>
                        </React.Fragment>
                    ) : ''}
                </div>
            )
    }

    const renderConsumer = () => {
        if(createAppointment.consumer && createAppointment.consumer.first_name) {
            return (
                <div>
                    <h4>{renderTranslation(store,"appointments.who_section_header")}</h4>
                    <div className="d-flex">
                        <i className="fa fa-user mr-2 mt-1" />
                        <p>{createAppointment.consumer.first_name} {createAppointment.consumer.last_name}</p>
                    </div>
                    <div className="d-flex">
                        <i className="fa fa-envelope mr-2 mt-1" />
                        <p>{createAppointment.consumer.email}</p>
                    </div>
                    <div className="d-flex">
                        <i className="fa fa-phone mr-2 mt-1" />
                        <p>{createAppointment.consumer.telephone}</p>
                    </div>
                    <hr/>
                </div>
            )
        }
    }

    const renderAddress = () => {
        if(createAppointment.address && createAppointment.address.postal_code) {

            return (
                <div>
                    <h4>{renderTranslation(store,"appointments.where_section_header")}</h4>
                    <div className="d-flex">
                        <i className="fa fa-map-marker-alt mr-2 mt-1" />
                        <p>
                            {createAppointment.address.postal_code}
                            {createAppointment.address.suffix ? ` - ${createAppointment.address.suffix}` : ''}
                            {createAppointment.address.street ? ` | ${createAppointment.address.street}` : ''}
                            {createAppointment.address.city ? `, ${createAppointment.address.city}` : ''}
                        </p>
                    </div>
                    <hr/>
                </div>
            )
        }
    }

    const getAssignmentDetails = () => {
        const assignment = createAppointment.assignment;
        const rates = getRates();
        let dayTimeStr = '';
        let dateStr = '';
        if (assignment.selected === 'urgent') {
            dayTimeStr = 'As soon as possible';
            dateStr = moment().format('dddd MMMM Do');
        } else {
            if(assignment.schedule === 'week') {
                dayTimeStr = '08:00 - 18:00';
                dateStr = moment().add(1, 'days').format('dddd MMMM Do') + ' to ' + moment().add(7, 'days').format('dddd MMMM Do');
            } else {
                if(assignment.date) {
                    dateStr = moment(assignment.date).format('dddd MMMM Do');
                }
                if(assignment.dayTime.length) {
                    dayTimeStr = setDayTimeStr(assignment);
                }
            }
        }
        return {
            rates,
            dayTime: dayTimeStr,
            date: dateStr,
            selected: assignment.selected
        }
    }

    const setDayTimeStr = (assignment) => {
        if (assignment.dayTime.length === 2) {
            return '08:00 - 18:00';
        } else if (assignment.dayTime[0] === 'morning') {
            return '08:00 - 12:00';
        } else {
            return '12:00 - 18:00';
        }
    }

    const renderAssignment = () => {
        const assignmentDetails = getAssignmentDetails();
        let rate = assignmentDetails.rates.rate;
        if (Number.isInteger(rate) && createAppointment.warrantyPackage) {
            rate += 3.95
        }

        return (
            <div>
                <div className="d-flex justify-content-between">
                    <h4>{renderTranslation(store,"appointments.when_section_header")}</h4>
                    {assignmentDetails.selected === 'urgent' ? (
                        <h4 className="d-flex" title={renderTranslation(store,'appointments.urgent_assignment_title')}>
                            <i className="tim-icons icon-spaceship mr-2 mt-1" />
                        </h4>
                    ) : (
                        <h4 className="d-flex" title={renderTranslation(store,'appointments.available_days')}>
                            <i className="tim-icons icon-calendar-60 mr-2 mt-1" />
                        </h4>
                    )}
                </div>
                {assignmentDetails.date.length ? (
                    <div className="d-flex">
                        <i className="fa fa-calendar mr-2 mt-1" />
                        <p>{assignmentDetails.date}</p>
                    </div>
                ) : ''}
                {assignmentDetails.dayTime.length ? (
                    <div className="d-flex">
                        <i className="fa fa-clock mr-2 mt-1" />
                        <p>{assignmentDetails.dayTime}</p>
                    </div>
                ) : ''}
                <div className="d-flex">
                    <i className="fa fa-euro-sign mr-2 mt-1" />
                    <p>€{rate}</p>
                    <p className="text-muted"> + €{assignmentDetails.rates.service} {renderTranslation(store,'appointments.service_fee')}</p>
                </div>
                <hr/>
            </div>
        )
    }

    const renderCoupon = () => {
        if(createAppointment.coupon && createAppointment.coupon.length) {
            return (
                <p>Coupon applied: {createAppointment.coupon}</p>
            )
        }
    }

    const renderWarrantyPackage = () => {
        if(createAppointment.warrantyPackage) {
            return (
                <div className="d-flex">
                    <i className="fa fa-check text-success mr-2 mt-1" />
                    <p>{renderTranslation(store,'appointments.warranty_package_included')}</p>
                </div>
            )
        }
    }

    const renderHeader = () => {
        const rates = getRates();
        let rate = rates.rate;
        if (Number.isInteger(rate) && createAppointment.warrantyPackage) {
            rate += 3.95
        }
        return (
            <div className="d-flex justify-content-between w-100">
                <h4><i className="fa fa-file-alt mr-2 mt-1" style={{ fontSize: 20}} /> {renderTranslation(store,'appointments.appointment_details')}</h4>
                <div className="d-flex">
                    <h4>€{rate}</h4>
                    <p className="text-muted ml-1"> + €{rates.service}</p>
                </div>
            </div>
        )
    }

    const getRates = () => {
        const assignment = createAppointment.assignment;
        if (assignment.selected === 'urgent') {
            return getUrgentRate(assignment);
        }
        return getScheduledRate(assignment);
    }

    // console.log("CRREATE APPOINTMENT",createAppointment);

    return (
        <Card>
            <CardHeader>
                {renderHeader()}
            </CardHeader>
            <CardBody>
                {renderTask()}
                {renderConsumer()}
                {renderAddress()}
                {renderAssignment()}
                {createAppointment.coupon || createAppointment.warrantyPackage ? (
                    <div>
                        <h4>{renderTranslation(store,"appointments.additional_section_header")}</h4>
                        {renderCoupon()}
                        {renderWarrantyPackage()}
                    </div>
                ) : ''}

            </CardBody>
        </Card>
    )
}
