import { partnerProfileService } from "../services";

/**
 * @param object payload
 * @param string id (uuid of Partner)
 */
 export const UpdatePartnersAction = (payload, id) => (
  _dispatch,
  _getState
) => {
  return new Promise((resolve, _reject) => {
    let result = { ...payload };
    partnerProfileService
      .update(result, id)
      .then((resp) => {
        let currentUserDetail = JSON.parse(localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY));
        currentUserDetail.userDetail.data = resp.data;

        /** Set user detail along with token detail in Local storage */
        localStorage.setItem(process.env.REACT_APP_CURRENT_USER_KEY, JSON.stringify(currentUserDetail));

        resolve({ success: true, data: resp.data });
      })
      .catch((error) => {
        resolve({ success: false });
      });
  });
};