import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import SearchableSelect from "../../../shared/Select/SearchableSelect";
import TextInput from "../../../shared/Input/TextInput";
import { UpdateDynamicAnswersAction } from "../../actions";

function DynamicFields() {
    const { task, dynamicAnswers } = useSelector(state => state.createAppointment);
    const dispatch = useDispatch();

    const updateDynamicAnswers = (val, field) => {
        const existingField = dynamicAnswers.find((answer) => answer.field === field.title);
        let updatedDynamicAnswers = [];
        if (existingField){
            dynamicAnswers.map(singleItem => {
				if (singleItem.field === existingField.field) {
					singleItem.values = [val];
				}
				updatedDynamicAnswers.push(singleItem);
			});
            dispatch(UpdateDynamicAnswersAction(updatedDynamicAnswers));
        }
        else{
            const newField = {
                field: field.title,
                values: [val],
                meta: field
			};
            dispatch(UpdateDynamicAnswersAction([
                ...dynamicAnswers,
                newField
            ]));
        }
    }

    const renderFieldItem = (field) => {
        if(field.type === 'select') {
            let dropdownValues = [];
            if (field.values.length){
                field.values.map((item) => {
                    const obj = {
                        value: item.value,
                        label: item.value
                    }
                    dropdownValues.push(obj);
                })
            }
            const singleField = dynamicAnswers.find((singleField) => singleField.field === field.title);
            return (
                <SearchableSelect
                    label={field.title}
                    value={(singleField && singleField?.values?.length > 0) ? singleField.values[0] : null}
                    options={dropdownValues}
                    handleChange={val => updateDynamicAnswers(val, field)}
                />
            )
        } else if(field.type === 'text' || field?.type.toLowerCase() == "number") {
            const singleField = dynamicAnswers.find((singleField) => singleField.field === field.title);
            return (
                <TextInput
                    value={(singleField && singleField?.values?.length > 0) ? singleField.values[0] : ''}
                    label={field.title}
                    onBlur={e => updateDynamicAnswers(e.target.value, field)}
                />
            )
        }
    }

    const renderFields = () => {
        if(task && task.dynamicFields && task.dynamicFields.length) {
            return task.dynamicFields.map(field => (
                <div className="my-2" key={field.title}>
                    {renderFieldItem(field)}
                </div>
            ));
        }
    }

    // console.log("DYNAMIC ANSWERS",dynamicAnswers);

    return (
        <div>
            {renderFields()}
        </div>
    )
}

export default DynamicFields;