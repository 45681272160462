import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import avatar from "assets/img/default.png";
import TextInput from "../../../shared/Input/TextInput";
import { FetchConsumersAction } from "../../../Consumer/actions";
import { FetchConsumerAddressAction } from "../../../profile/actions";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UpdateConsumerDataAction } from "../../actions";
import { renderTranslation } from "services";
import AddressIcon from "../../../../assets/img/arrow-right.png";

import "../../styles/addressDropdown.scss";
import AddressSelection from "./AddressSelection";
import { generateSessionId } from "../../../../services/generateSessionId";

function ConsumerProfile(props) {
  const { consumer, errors, address: globalAddress } = useSelector((state) => state.createAppointment);
  const [alreadyValidated, setAlreadyValidated] = useState(false);
  const [isOperatingCountry, setIsOperatingCountry] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const dispatch = useDispatch();

  const store = useSelector((state) => state);

  useEffect(() => {
    setSessionId(generateSessionId());
  }, [] )
  const updateData = (consumer) => {
    dispatch(UpdateConsumerDataAction(consumer));
  };

  const handleChange = (val, name) => {
    const updatedConsumer = {
      ...consumer,
      [name]: val,
    };
    updateData(updatedConsumer);
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <TextInput
            label={renderTranslation(store,"forms.first_name_text")}
            required
            onBlur={(e) => handleChange(e.target.value, "first_name")}
            value={consumer.first_name}
            error={errors.first_name}
          />
        </Col>
        <Col md={6}>
          <TextInput
            label={renderTranslation(store,"forms.last_name_text")}
            required
            onBlur={(e) => handleChange(e.target.value, "last_name")}
            value={consumer.last_name}
            error={errors.last_name}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TextInput
            type="email"
            required
            label={renderTranslation(store,"forms.email_text")}
            onBlur={(e) => handleChange(e.target.value, "email")}
            value={consumer.email}
            error={errors.email}
          />
        </Col>
        <Col md={6}>
          <TextInput
            label={renderTranslation(store,"forms.customer_phone_text")}
            required
            onBlur={(e) => handleChange(e.target.value, "telephone")}
            value={consumer.telephone}
            error={errors.telephone}
          />
        </Col>
      </Row>
      <hr />
      <CardHeader className="pl-0 pt-0">
        <CardTitle className="mb-0" tag="h4">{renderTranslation(store,"appointments.where_section_header")}</CardTitle>
        {alreadyValidated &&
         <CardTitle style={{
            fontSize: "14px",
            color: "#1d8cf8",
            cursor: "pointer"
         }}
         onClick={() => {
            setIsOperatingCountry(false);
            setAlreadyValidated(false);
        }} tag="h4">{renderTranslation(store, "tvChange")}</CardTitle>
        }
      </CardHeader>
      <AddressSelection sessionId={sessionId} />
    </div>
  );
}

export default ConsumerProfile;
