const initialState = {
  locales: {
    nl: {},
    en: {},
    de: {}
  }
};

const translations = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TRANSLATIONS':
      return { ...state, locales: { ...state.locales, ...action.payload } };
    default:
      return state;
  }
};

export default translations;
