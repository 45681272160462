import React from 'react';
import styles from './resultsDropdownStyle';

const ResultsDropdown = ({ data, opened, handleClick }) => {
	return (
		<div style={opened ? styles.resultsDropdownOpened : styles.resultsDropdown}>
			{data.map((singleResult, i) => {
				const resultName = singleResult.AddressLine.replace(' -', '');

				return (
					<div
						style={styles.singleAddress}
						key={i}
						onClick={() => handleClick(singleResult)}
					>
						<span>{resultName}</span>
					</div>
				);
			})}
		</div>
	);
};

export default ResultsDropdown;
