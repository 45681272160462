import { httpHandler, apiUrls, baseEndpoint } from "./index";
class MediaService {

    http = "";
    constructor() {
        this.http = httpHandler(
          baseEndpoint.mediaServiceBaseEndPoint,
          null, false,true
        );
    }
    /**
     *
     * @param {object} data
     */
    upload = (data) => {
        let formData = new FormData();
        formData.append("ref_id", data.ref_id);
        formData.append("file", data.file);
        formData.append("type", data.type ? data.type : "Profile");
        return this.http.post(apiUrls.media.upload, formData);
    };

    filter(payload) {
        return this.http.post(apiUrls.media.filter, payload);
    }
    /**
     *
     * @param {Integer} mediaId
     */
    delete = (mediaPath) => {
        return this.http.delete(apiUrls.media.delete(mediaPath));
    };

	/**
	 *
	 * @param {object} body
	 */
    fetch = (body) => {
        return this.http.post(apiUrls.media.get, body);
    };
}

export const mediaService = new MediaService();
