const initialState = {
  activeLocale: process.env.REACT_APP_DEFAULT_LOCALE
};

const activeLocale = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_LOCALE':
      return { ...state, activeLocale: action.payload };
    default:
      return state;
  }
};

export default activeLocale;
