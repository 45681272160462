import React, {useState} from 'react';
import { FormGroup, Label, Input } from "reactstrap";

export default function TextInput(props) {
    const { type, placeholder, label, error, required } = props;
    const [value, setValue] = useState(props.value);

    const handleChange = (e) => {
        setValue(e.target.value);
        if (props.handleChange) {
            props.handleChange(e);
        }
    }

    const onBlur = (e) => {
        if (props.onBlur) {
            props.onBlur(e);
        }
    }

    return (
        <FormGroup>
            <Label>{label} {required ? '*' : ''}</Label>
            <Input
                value={value ?? ''}
                type={type ? type : 'text'}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={onBlur}
            />
            {error ? (
                <span className="text-danger">{error}</span>
            ) : ''}
        </FormGroup>
    );
}