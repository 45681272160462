import { createAppointment } from '../services'

/**
 * 
 * @param object payload 
 */
export const TaskRateAction = (payload) => (_dispatch, _getState) => {

  return new Promise((resolve, _reject) => {
    let result = { ...payload }
    createAppointment.findTaskRate(result).then((response) => {
      resolve({ success: true, data: response })
    })
    .catch((error) => {
      console.log('error ', error)
      resolve({ success: false })
    })
  });
}