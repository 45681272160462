import React, { useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardSubtitle,
	CardTitle,
	Col,
	FormGroup,
	Input,
	Label,
	Row
} from "reactstrap";
import { ControlLabel, MenuItem } from "react-bootstrap";
import SearchableSelect from "../../../shared/Select/SearchableSelect";
import avatar from "../../../../assets/img/default.png";
import UserCard from "../../../shared/Card/UserCard";
import ConsumerProfile from "./ConsumerProfile";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setConsumerAddress } from "../../actions";
import axios from "axios";
import AsyncSelect from 'react-select/async';
import ResultsDropdown from "./ResultsDropdown/ResultsDropdown";

export default function AddressSelection({ handleSetAddress, handleSetAddressList, handleSetAddressValue, sessionId }) {

	const [showAddressDropdown, setShowAddressDropdown] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [addressList, setAddressList] = useState([]);
	const [address, setAddress] = useState({});
	const dispatch = useDispatch();
	const { errors } = useSelector(state => state.createAppointment);
	const { t } = useTranslation();

	const searchAddress = (value) => {
		setInputValue(value.target.value);
		handleSetAddressValue&& handleSetAddressValue(value.target.value);

		if (!value.target.value) {
			setAddress({});
			dispatch(setConsumerAddress(null));
			handleSetAddress && handleSetAddress(null);
		}

		if (value.target.value.length >= 2 && value.target.value.charAt(0) !== ' ' && value.target.value.charAt(1) !== ' ') {

			const myHeaders = new Headers();
			myHeaders.append("APP-ID", process.env.REACT_APP_ID);
			myHeaders.append('X-Autocomplete-Session', sessionId);
			myHeaders.append("locale", localStorage.getItem("locale") || "nl");

			var requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};

			fetch(`${process.env.REACT_APP_GATEWAY}/api/v1/address-international?search=${value.target.value}`, requestOptions)
			.then(
				(response) => response.json(),
				(error) => console.log('An error occured.', error)
			)
			.then((data) => {
				dispatch(setConsumerAddress(data.data));
				setAddressList(() => data.data);
				handleSetAddressList && handleSetAddressList(data.data);
				setShowAddressDropdown(true);

			});
		}
	};

	const handleAddressClick = (address) => {
		const trimmedAddressLine = address.AddressLine.replace(' -', '');
		setShowAddressDropdown(true);

		setInputValue(trimmedAddressLine);
		handleSetAddressValue && handleSetAddressValue(trimmedAddressLine);

		if (address.Type.toLowerCase() === 'address') {
			getAddress(address, trimmedAddressLine);
		} else {
			const myHeaders = new Headers();
			myHeaders.append("APP-ID", process.env.REACT_APP_ID);
			myHeaders.append('X-Autocomplete-Session', sessionId);
			myHeaders.append("locale", localStorage.getItem("locale") || "nl");

			var requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};

			const drillDownResponse = fetch(`${process.env.REACT_APP_GATEWAY}/api/v1/address-international?search=${inputValue}&container=${address.id}`, requestOptions);
			drillDownResponse
			.then(
				(response) => response.json(),
				(error) => console.log('An error occured.', error)
			)
			.then((data) => {
				dispatch(setConsumerAddress(data.data));
				setAddressList(() => data.data);
				handleSetAddressList && handleSetAddressList(data.data);
			});
		}
	};

	const getAddress = (address) => {
		const myHeaders = new Headers();
		myHeaders.append("APP-ID", process.env.REACT_APP_ID);
		myHeaders.append("locale", localStorage.getItem("locale") || "nl");

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(
			`${process.env.REACT_APP_API}/api/v1/address-international/${address.id}`, requestOptions)
		.then(
			(response) => response.json(),
			(error) => console.log('An error occured.', error)
		)
		.then((data) => {
			let addressObject;
			if (data.data) {
				addressObject = data.data;
			} else {
				addressObject = data;
			}
			const { street, suffix, postal_code, city, country } = addressObject;
			if (city && country) {
				//TODO update in feature for country_code
				if (
					country === 'Netherlands' ||
					country === 'Germany' ||
					country === 'Belgium'
				) {
					setInputValue(street + ' ' + suffix + ' ' + postal_code + ' ' + city);
					handleSetAddressValue && handleSetAddressValue(street + ' ' + suffix + ' ' + postal_code + ' ' + city)

					setShowAddressDropdown(false);

					dispatch(setConsumerAddress(addressObject));
					setAddress(addressObject);
					handleSetAddress && handleSetAddress(addressObject)
				}
			}
		});
	};

	return (
		<Card className='mb-0'>
			<CardBody className='pb-0 pt-2'>
				<Row>
					<Col style={{ padding: 0 }}>
						<FormGroup>
							<Input
								required
								margin="dense"
								id="address"
								name="address"
								value={inputValue}
								type="text"
								onChange={(e) => searchAddress(e)}
							/>
							<ResultsDropdown
								data={addressList || []}
								opened={
									showAddressDropdown &&
									inputValue !== '' &&
									addressList &&
									addressList.length > 0
								}
								handleClick={(address) => handleAddressClick(address)}
							/>
						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	)
}
