import React, { Component } from "react";
import { Switch, Route , Redirect} from "react-router-dom";
import AuthNavbar from "../modules/shared/Navbars/AuthNavbar.jsx";
import routes from "routes/auth-route.js";
import bgImage from "assets/img/handyman-services.jpg";
import { withTranslation } from 'react-i18next';
import CopyRightText from "../modules/shared/Footer/CopyRightText.jsx"
import {httpHandler, baseEndpoint, renderTranslation} from "../services";
import { connect } from 'react-redux';

class Pages extends Component {

  httpService = null;

  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
    this.httpService = httpHandler(baseEndpoint.partnersServiceBaseEndPoint);
  }
  getPageClass() {
    return ` ${this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]}`
  }
  
  checkAuthentication = () => {
    let hasPermission = false;
    if (localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY) &&
      JSON.parse(localStorage.getItem(process.env.REACT_APP_CURRENT_USER_KEY)).token) {
      return true;

    }
    return hasPermission
  }; 
  getRoutes = routes => {
    const isAuth = this.checkAuthentication();
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return !isAuth ? (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          /> ): (<Redirect to="/admin/dashboard" />);
      } else {
        return null;
      }
    });
  };

  
  render() {
    const { store } = this.props;
    return (
      <div>
        <AuthNavbar http={this.httpService} />
        <div className="wrapper wrapper-full-page position-relative">
        <div
            className={"full-page" + this.getPageClass()}
          >
        <Switch>{this.getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
              </Switch>

        <div style={{marginTop: "-90px", textAlign: 'center'}}>
            <p style={{color: '#AAAAAA'}}>{renderTranslation(store, 'app_version')} {process.env.REACT_APP_VERSION}</p>
        </div>

        {/* Footer Start */}
      <div className="footer-auth flex position-absolute bottom-0">
        <div>
        <CopyRightText/>
        </div>
      <div>

      </div>
      </div>
              {/* Footer End */}

        </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state
  }
}

export default withTranslation()(connect(mapStateToProps, null)(Pages));




{/* <div className="wrapper wrapper-full-page">
  <div className={"full-page " + getFullPageName(routes)}>
    <Switch>
      {getRoutes(routes)}
      <Redirect from="*" to="/auth/login" />
    </Switch>
    <Footer fluid />
  </div>
</div> */}