import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
import i18n from "../../../../i18n"
import "../../styles/dashboard.scss";
import { renderTranslation } from "services";
import { CREATED, PRO_ASSIGNED, CANCELLED, INVOICED, INVOICE_PENDING, POSTPONED, ON_THE_WAY, INVOICE_PAID, INVOICE_COMPLETED, INVOICE_CREATED, INVOICE_EXPIRED } from "../../../Appointments/constants/StatusConstants"
class LatestAppointments extends Component {

    constructor(props) {
        super(props);
    }

    /**
      *  convert date into special string.
      * @param {string|Date} requestedDate
      */
    appointmentDeadline = (requestedDate) => {
        let now = moment(new Date()); //todays date
        const givenDate = moment(requestedDate); // another date
        const day = now.diff(givenDate, "days") % 30;
        const month = now.diff(givenDate, "month") % 12;
        const year = now.diff(givenDate, "year");
        let status = "";


        if (day < 0) {
            return "Expired";
        }
        
        status = this.setAppointmentStatus(month, day);

        if (year !== 0) {
            status = `${year} ${year === 1 ? "year" : "years"} ${status}`;
        }
        return status;
    };
    
    setAppointmentStatus = (month, day) => {
        if (month !== 0 && day !== 0) {
            return `${month} ${month === 1 ? "month" : "months"} and ${day}  ${day === 1 ? "day" : "days"
                } ago`;
        } else if (month === 0 && day !== 0) {
            return `${day}  ${day === 1 ? "day" : "days"} ago`;
        } else if (month !== 0 && day === 0) {
            return `${month} ${month === 1 ? "month" : "months"}`;
        }
    }

    /**
   * GET Translation
   */
  getStatusTranslation(status) {
    const { store } = this.props;
    if (status)
    status = status.toLowerCase().replace(' ', "_");

    if (status == CREATED) {
      return renderTranslation(store, 'appointments.posted')
    }

    if (status == PRO_ASSIGNED) {
      return renderTranslation(store, 'appointments.accepted');
    }

   else if (status == CANCELLED) {
      return renderTranslation(store, 'appointments.cancelled');
    }

    else if (status == INVOICE_PENDING) {
        return renderTranslation(store, 'appointments.invoicePending');
    }

    else if (status == INVOICE_EXPIRED) {
        return renderTranslation(store, 'appointments.invoiceCancelled');
    }

    else if (status == POSTPONED) {
        return renderTranslation(store, 'appointments.postponed');
    }

    else if (status == ON_THE_WAY) {
        return renderTranslation(store, 'appointments.onTheWay');
    }

    else if (status == INVOICE_PAID) {
        return renderTranslation(store, 'appointments.invoicePaid');
    }

    else if (status == INVOICE_COMPLETED) {
        return renderTranslation(store, 'appointments.invoiceCompleted');
    }

    else if (status == INVOICE_CREATED) {
        return renderTranslation(store, 'appointments.invoiceCreated');
    }

    else if (status == INVOICED) { 
      return renderTranslation(store, 'appointments.completed');
    }
  }
    render() {
        const { store } = this.props;
        return (
            <Card>
                <CardHeader>
                    <div className="tools float-right">
                        <UncontrolledDropdown>
                            <DropdownToggle
                                caret
                                className="btn-icon"
                                color="link"
                                data-toggle="dropdown"
                                type="button"
                            >
                                <i className="tim-icons icon-settings-gear-63" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem
                                    onClick={()=> this.props.viewAppointments()}
                                >
                                    {renderTranslation(store, "appointments.view_all_text")}
                                
                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <CardTitle tag="h5">{renderTranslation(store, "dashboard.latest_appointments")}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead className="text-primary">
                            <tr>
                                <th> {renderTranslation(store, "appointments.table.id_text")}</th>
                                <th> {renderTranslation(store, "appointments.table.job_text")}</th>
                                <th>{renderTranslation(store, "appointments.table.place_text")}</th>
                                <th> {renderTranslation(store, "appointments.table.customer_text")}</th>
                                <th>
                                    {renderTranslation(store, "appointments.table.requested_on_text")}
                                </th>
                                <th> {renderTranslation(store, "appointments.table.status_text")}</th>
                                <th className="text-center"> {renderTranslation(store, "customers.table_header.action")}</th>
                            </tr>
                        </thead>
                        { (this.props.appointments && this.props.appointments.length === 0) && (
                            <div className="no-found-table-text pt-2">
                                {renderTranslation(store, "message.no_appointment_found")}
                            </div>
                        )}
                        {(this.props.appointments && this.props.appointments.length > 0) && (
                            <tbody>
                                {this.props.appointments.map((prop) => {
                                    return (
                                        <tr>
                                            <td>
                                      
                                      {prop.id}
                                       
                                      
                                    
                                            </td>
                                            <td>{prop.task.singular}</td>
                                            <td>
                                                {prop.customer_address.city}
                                            </td>
                                            <td>
                                                {prop.customer.name}
                                            </td>
                                            <td>
                                                {`${moment(
                                                    prop.deadline_at
                                                        ? prop.deadline_at
                                                        : prop.created_at
                                                ).format("DD-MM-YYYY HH:MM A")}`}
                                                <br />{" "}
                                                <span className="appointments-deadline-text">{`(${this.appointmentDeadline(
                                                    prop.deadline_at
                                                        ? prop.deadline_at
                                                        : prop.created_at
                                                )})`}</span>
                                            </td>
                                            <td>
                                            <div>
                                      <span className={`badge ${prop.status ? prop.status.toLowerCase().replace(' ', "_") : ''}`}>
                                      {this.getStatusTranslation(prop.status)} 
                                      </span> 
                                </div>
                                </td>
                                            <td className="text-center">
                                                <Button
                                                    className="btn-link btn-icon"
                                                    color="success"
                                                    id="tooltip30547133"
                                                    size="sm"
                                                    title={renderTranslation(store, "buttons.view")}
                                                    type="button"
                                                    onClick={() => this.props.showSingleAppointment(prop)}
                                                >
                                                    <i className="fa fa-eye" />
                                                </Button>
                                                <UncontrolledTooltip delay={0} target="tooltip30547133">
                                                    {renderTranslation(store, "buttons.view")}
                                                </UncontrolledTooltip>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </Table>
                </CardBody>
            </Card>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        store: state
    };
}

export default (
connect(mapStateToProps)(LatestAppointments)
);
