import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";
import { Button, UncontrolledTooltip } from "reactstrap"
import {
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FormInputs from "../../../shared/FormInputs/FormInputs.jsx";
import {
  CreatePartnersContactAction,
  UpdatePartnersContactAction,
  UploadProfileImageAction,
  RemoveProfileImageAction,
  FetchSinglePartnersContactAction
} from "../../actions"

import { withTranslation } from "react-i18next";
import avatar from "assets/img/default.png";
import { mediaBaseUrl, getCurrentUser, errorAlertBox } from "../../../../services"
import i18n from "../../../../i18n";
import { renderTranslation } from "services/renderTranslation.service.js";
import { ALLOWED_FILE_TYPES } from "../../constants/UploadFIleContstats";

class AddEditPartnersContact extends Component {
  partnerContactForm = null;
  inputOpenFileRef = null;
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      file: null,
      loading: false,
      errorShow: false,
      loadImage: false,
      selectedContact: null,
    };
    this.createInput();
  }

  createInput() {
    this.partnerContactForm = new Group({
      name: new Control(null, [Validators.required]),
      position: new Control(null, [Validators.required]),
      email: new Control(null, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
      ]),
      phone: new Control(null, [Validators.required]),
      profile_image: new Control(""),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.type && this.props.type === 'edit' && this.props.type !== prevProps.type) {
      this.fetchSingleContact();
    }
    if (this.props.type && this.props.type === 'add' && this.props.type !== prevProps.type) {
      this.setState({selectedContact: null, file: null})

    }
    if (this.props.show && this.props.show !== prevProps.show) {
      this.handleShow();
    }
  }

  /**
   * Fetch Single Contact
   * - set forms value
   */
  fetchSingleContact = () => {
    if(this.props.selectedContact.uuid) {
    this.props.fetchSingleContactAction(this.props.selectedContact.uuid).then((resp)=> {
      const { data } = resp
      this.partnerContactForm.controls['name'].setValue(data.name)
      this.partnerContactForm.controls['email'].setValue(data.email)
      this.partnerContactForm.controls['position'].setValue(data.position)
      this.partnerContactForm.controls['phone'].setValue(data.phone)
      if(data.profile_image !== '') {
      this.partnerContactForm.controls['profile_image'].setValue(data.profile_image)
      this.setState({ file: `${mediaBaseUrl}${data.profile_image}`});
      this.setState({selectedContact: data})
      }
    })
    }
  };

  /**
   * Partner contacts MODAL CLOSE
   */
  handleClose() {
    this.setState({ show: false });
    this.removeProfileImage();
    this.props.close();
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleFIleUpload = () => {
    this.inputOpenFileRef.click();
  };

  handleChange = (e) => {
    const { store } = this.props;
    const { name, value, files } = e.target;

    switch (name) {
      case "profile_image":

        if (!ALLOWED_FILE_TYPES.includes(files[0].type)) {
          errorAlertBox(
            renderTranslation(
              store,
              'upload-error.file-not-allowed'
            )
          );
        }

        if (files[0].size > process.env.REACT_APP_MAX_UPLOAD_SIZE) {
          errorAlertBox(
            renderTranslation(
              store,
              'step_3.upload-error.file-too-large'
            )
          );
          return;
        }

        let payload = {
          ref_id: getCurrentUser().data.uuid,
          file: files[0],
          type: "Profile"
        };
        this.setState({ loadImage: true })
        this.props.uploadProfileImageAction(payload)
          .then((resp) => {
            if (resp.success) {
              this.setState({ mediaId: resp.data.media_id })
              this.partnerContactForm.controls["profile_image"].setValue(resp.data.path)
              this.setState({ file: resp.data.url });
            }
          })

        break;
      default:
        this.partnerContactForm.controls[e.target.name].setValue(value)
    }
  };

  handleSubmit = () => {
    if (this.partnerContactForm.valid) {
      let payload = {
        name: this.partnerContactForm.controls.name.value,
        email: this.partnerContactForm.controls.email.value,
        phone: this.partnerContactForm.controls.phone.value,
        position: this.partnerContactForm.controls.position.value,
        profile_image: this.partnerContactForm.controls.profile_image.value ?? ""
      }
      if(this.props.type === 'add') {
        this.createNewContact(payload)
      } else if(this.props.type === "edit") {
        this.updateContact(payload)
      }

    } else {
      this.setState({ errorShow: true })
    }
  };

  /**
   *
   * @param {object} payload
   */
  createNewContact = (payload) => {
    this.setState({ loading: true });
    this.props.createContactAction(payload).then((resp) => {
      this.setState({ loading: false });
      if (resp.success) {
        this.setState({ show: false })
        this.props.refresh();
        this.props.close();
      }
    })
  }

  /**
   *
   * @param {object} payload
   */
   updateContact = (payload) => {
    this.setState({ loading: true });
    this.props.updateContactAction(payload, this.state.selectedContact.uuid).then((resp) => {
      this.setState({ loading: false });
      if (resp.success) {
        this.setState({ show: false })
        this.props.refresh();
        this.props.close();
      }
    })
  }

  toggleModal = () => {
    let modal = !this.state.show;
    this.setState({ show: modal });
    if (!modal) {
      this.props.close();
    }

  }

  errorMassage = (label, errors) => {
    const { store } = this.props;
    if (errors && this.state.errorShow) {
      if (errors.required) {
        return renderTranslation(store, "errors.required", { fieldName: label })
      }
      if (errors.pattern) {
        return renderTranslation(store, "errors.format", { fieldName: label })
      }
    }
    return null;
  };

  removeProfileImage = () => {
    if (this.partnerContactForm.controls.profile_image.value !== '') {
      this.props.removeProfileImageAction(this.partnerContactForm.controls.profile_image.value).then((response) => {
        this.partnerContactForm.controls["profile_image"].setValue("");
        this.setState({ file: null });
      })

    }
  };

  render() {
    const { store } = this.props;
    return (
      <div id="add-contact-person-block">
        <Modal isOpen={this.state.show} toggle={this.toggleModal} >
          <ModalHeader toggle={this.toggleModal} >
            {renderTranslation(store, "profile.profile_add_contact_person_title")}
          </ModalHeader>
          <ModalBody>
            <form>

              <Row>
                <Col md={12}>
                  <div className="contact-profile-image-text"><div>Upload Profile Image
                {this.state.loadImage && (
                      <i className="fas fa-spinner fa-pulse"></i>
                    )}

                  </div>
                  </div>
                  <FormGroup>


                    <FormControl
                      type="File"
                      name="profile_image"
                      placeholder={renderTranslation(store, "auth.email_placeholder_text")}
                      onChange={this.handleChange}
                      inputRef={(ref) => { this.inputOpenFileRef = ref }}
                    />
                    <span className="error"></span>
                  </FormGroup>
                  <div
                    id="upload-profile-contact-image"
                    className="preview-image-box">
                    <span>
                      {(this.partnerContactForm.controls.profile_image.value) && (<UncontrolledTooltip delay={0} target="remove-contact-image-tooltip">
                        {renderTranslation(store, "buttons.remove_profile_image_text")}
                      </UncontrolledTooltip>)}
                      {(this.partnerContactForm.controls.profile_image.value) && (
                        <small id="remove-contact-image-tooltip" className="partner-contacts-remove-image" onClick={this.removeProfileImage} >
                          <i className="tim-icons icon-simple-remove"></i></small>
                      )}
                      <img
                        onClick={this.handleFIleUpload}
                        src={this.state.file ? this.state.file : avatar}
                        className="preview-image-box-img"
                        alt="logo"
                      />

                    </span>
                  </div>

                </Col>
              </Row>
              <FormInputs
                ncols={["col-md-6", "col-md-6", "col-md-6", "col-md-6"]}
                proprieties={[
                  {
                    label: renderTranslation(store, "forms.contact_person_name_text"),
                    defaultValue: this.state.selectedContact ? this.state.selectedContact.name : "",
                    type: "text",
                    name: "name",
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.contact_person_name_text"), this.partnerContactForm.controls['name'].errors),
                    placeholder: renderTranslation(store, "forms.contact_person_name_placeholder_text"),
                    onChange: this.handleChange,
                  },
                  {
                    label: renderTranslation(store, "forms.contact_person_position_text"),
                    type: "text",
                    defaultValue: this.state.selectedContact ? this.state.selectedContact.position : "",
                    name: "position",
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.contact_person_position_text"), this.partnerContactForm.controls['position'].errors),
                    placeholder: renderTranslation(store, "forms.contact_person_position_placeholder_text"),
                    onChange: this.handleChange,
                  },
                  {
                    label: renderTranslation(store, "forms.email_text"),
                    type: "email",
                    defaultValue: this.state.selectedContact ? this.state.selectedContact.email : "",
                    name: "email",
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.email_text"), this.partnerContactForm.controls['email'].errors),
                    placeholder: renderTranslation(store, "forms.email_placeholder_text"),
                    onChange: this.handleChange,
                  },
                  {
                    label: renderTranslation(store, "forms.phone_no_text"),
                    type: "text",
                    defaultValue: this.state.selectedContact ? this.state.selectedContact.phone : "",
                    name: "phone",
                    bsClass: "form-control",
                    error: this.errorMassage(renderTranslation(store, "forms.phone_no_text"), this.partnerContactForm.controls['phone'].errors),
                    placeholder: renderTranslation(store, "forms.phone_no_placeholder_text"),
                    onChange: this.handleChange,
                  },
                ]}
              />



              <div className="clearfix" />
            </form>
          </ModalBody>
          <ModalFooter className="justify-content-center">
                {this.props.type === 'add' && (
                              <Button bsStyle="info" pullRight onClick={this.handleSubmit} disabled={this.state.loading} >
                              {!this.state.loading ? "Submit" : 'Loading...'}
                            </Button>
                )}
            {this.props.type === 'edit' && (
                              <Button bsStyle="info" pullRight onClick={this.handleSubmit}  disabled={this.state.loading} >
                              {!this.state.loading ? "Update" : 'Loading...'}
                            </Button>
                )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointments: state.feed.feed,
    store: state
  };
};

const mapDispatchToProps = {
  createContactAction: CreatePartnersContactAction,
  updateContactAction: UpdatePartnersContactAction,
  uploadProfileImageAction: UploadProfileImageAction,
  removeProfileImageAction: RemoveProfileImageAction,
  fetchSingleContactAction: FetchSinglePartnersContactAction
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddEditPartnersContact));

