import React, { Component } from "react";
import { RequestPasswordResetAction } from "../../actions";
import { connect } from "react-redux";
import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";

import {
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';
import i18n from "../../../../i18n";
import { renderTranslation } from "services";

class RequestPasswordReset extends Component {
  requestPasswordResetForm = null;
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      loading: false,
      errorShow: false,
      emailFocus: false
    };
    this.createInput();
  }

  createInput() {
    this.requestPasswordResetForm = new Group({
      email: new Control(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]),
    });
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
 
  handleChange = (e) => {
    return e.target.name === "email"
      ? this.requestPasswordResetForm.controls["email"].setValue(e.target.value)
      : () => {};
  };

  handleClick = (e) => {
  
    e.preventDefault();
     if (this.requestPasswordResetForm.valid) {
      this.setState({loading: true});
      this.setState({errorShow: false});
      let payload = { 
        email: this.requestPasswordResetForm.controls.email.value, 
      };
     this.props.requestPasswordResetAction(payload).then(result => {
        this.setState({loading: false});
        if (result.success) {
          this.props.submit(payload.email);
         
        }
      })
    } else {
      this.setState({errorShow: true});
    }
  };


  render() {
    const { store } = this.props;
    return (
     
     <div className="request-password-reset">
        <Container>
          <Col className="ml-auto mr-auto" lg="6" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  
                  <CardTitle tag="h1">{renderTranslation(store, "auth.request_password_reset_title")}</CardTitle>
                  <CardSubtitle>{renderTranslation(store, "auth.request_password_reset_sub_title")}</CardSubtitle>
                </CardHeader>
                <CardBody>
                <span className="error">
                       {(this.state.errorShow && this.requestPasswordResetForm.controls["email"].errors &&
                          this.requestPasswordResetForm.controls['email'].errors.required) ?
                          renderTranslation(store, "errors.required", { fieldName: "forms.email_text" })
                          : ""}

                          {this.state.errorShow && this.requestPasswordResetForm.controls["email"].errors &&
                          this.requestPasswordResetForm.controls["email"].errors.pattern ?
                          renderTranslation(store, "errors.format", { fieldName: "forms.email_text" })
                          : ""}
                       </span>

                       <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    
                    <Input
                      placeholder={renderTranslation(store, "forms.email_placeholder_text")}
                      type="email"
                      name="email"  
                      onFocus={(e) => this.setState({ emailFocus: true })}
                      onBlur={(e) => this.setState({ emailFocus: false })}
                      onChange={this.handleChange}
                      required
                    />
                  </InputGroup>
              

                  </CardBody>
                  <CardFooter>
                 <div  className="footer-buttons">
                 <Button
                    className="mb-3"
                    color="default"
                    onClick={this.props.cancel}
                    size="sm"
                  > 
                        {"Cancel"}
                </Button>

                  <Button
                    className="mb-3"
                    color="info"
                    onClick={this.handleClick}
                    size="md"
                  > 
                        {!this.state.loading ? "Find Account" : "Loading..."}
                </Button>
                 </div>

  </CardFooter>
                  </Card>
                  </Form>
                  </Col>
                  </Container>
                  </div>
    
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgotDetails: state.forgotPassword,
    store: state
  };
};
const mapDispatchToProps = {
  requestPasswordResetAction: RequestPasswordResetAction,
};
export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPasswordReset));
