const styles = {
	resultsDropdown: {
		border: '1px solid rgba(0, 0, 0, 0.42)',
		borderTop: 'none',
		position: 'absolute',
		top: '100%',
		cursor: 'pointer',
		visibility: 'hidden',
		maxHeight: '0',
		zIndex: 3,
		backgroundColor: '#fff',
		transition: 'all .15s',
		opacity: 0,
	},
	resultsDropdownOpened: {
		border: '1px solid rgba(0, 0, 0, 0.42)',
		borderTop: 'none',
		position: 'absolute',
		top: '100%',
		backgroundColor: '#fff',
		visibility: 'visible',
		cursor: 'pointer',
		zIndex: 3,
		width: '100%',
		overflow: 'auto',
		maxHeight: '200px',
		opacity: 1,
		fontSize: '.9rem',
		transition: 'all .15s',
	},
	singleAddress: {
		padding: '7px',
	},
};

export default styles;
