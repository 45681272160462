import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n"
import { FetchFeedAction } from "../../Appointments/actions";
import { SubmitParkedJobAction } from "../actions";
import { Line, Bar } from "react-chartjs-2";
import LatestAppointments from "./partials/LatestAppointments.jsx"
import JobsStatsOverview from "./partials/JobsStatsOverview.jsx";
import {FetchStatsAction} from "../actions"
import moment from "moment-timezone";
import ShowAppointment from "../../Appointments/views/ShowAppointment.jsx"
import { renderTranslation } from "services";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

// core components
import {
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";
const mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

class Dashboard extends Component {

  latestAppointment = 6;


  constructor(props) {
    super(props);
    this.state = {
      bigChartData: 'data1',
      showAppointment: false,
      selectedAppointmentDetail: null,
      appointments: [],
      year: moment().year(),
      stats: {
        posted_jobs: null,
        planned_jobs:null,
        completed_jobs:null,
      },
      latestStats: {
        posted_latest_jobs: null,
        completed_latest_jobs: null,
        latest_revenue:null
      },
      total: {
        total_jobs_count: 0,
        total_completed_jobs_count: 0,
        total_revenue_sum: 0,
        total_planned_count: 0,
      },
      parkedJobModal: false,
      parkedJobModalMessage: ''

    }
    document.title = 'Partner Dashboard | Zoofy'
    this.getPartnerStats();
  }

  componentWillMount() {
    this.getAppointments({per_page: this.latestAppointment });

    if(localStorage.getItem('parkedJob')) {
      let parkedJob = JSON.parse(localStorage.getItem('parkedJob'));
      
      this.props.submitParkedJobAction(parkedJob).then((result) => {
        console.log("result", result)
        let message = '';
        if (result && result.data && result.data.data && result.data.data.message) {
          message = result.data.data.message
        }else{
          message = "Something went wrong"
        }

        this.setState({parkedJobModal: true, parkedJobModalMessage: message})

        localStorage.removeItem('parkedJob')
      })
      .catch((error) => {
        console.log("ERRRR", error)
      });
    }
  }

  handleParkedJobModalClose = () => {
    this.setState({parkedJobModal:false})
  }
  togglehandleParkedJobModalCloseModal = () => {
    let modal = !this.state.parkedJobModal;
    this.setState({ parkedJobModal: modal });
  }

  setBigChartData($name) {
    this.setState({ bigChartData: $name });
  }

   filterStartsCharts(year) {
    this.setState({year: year})
    this.getPartnerStats(year);
  }


  getAppointments = (params = {}, page = 1) => {    
    this.props.fetchFeedAction(params, page).then((result) => {
      if(result.data && result.data.data) {
      this.setState({ appointments: result.data.data });
    }
    })
    .catch((error) => {
      console.log("ERRRR", error)
    });
  };


  /**
   * toggle Appointment detail (open or close)
   */
  toggleAppointmentDetailModal = () => {
    let modal = !this.state.showAppointment;
    this.setState({ showAppointment: modal });
  }

   /**
   * Close modal of selected Appointment detail
   */
  handleAppointmentDetailClose = () => {
    this.setState({ showAppointment: false });
  };

   getPartnerStats(year = null) {
    let params = {
      year: year? year: this.state.year 
    }
   this.props.fetchStatsAction(params).then((result) => {
      if(result.data) {
      const stats = result.data.data;
      this.setState({stats: { 
        posted_jobs: stats.posted_jobs,
        completed_jobs:stats.completed_jobs,
        planned_jobs: stats.planned_jobs
      }});
      this.setState({latestStats: { 
        posted_latest_jobs: stats.posted_latest_jobs,
        completed_latest_jobs:stats.completed_latest_jobs,
        latest_revenue: stats.latest_revenue
      }});

      this.setState({total: { 
        total_jobs_count: stats.total_jobs_count,
        total_completed_jobs_count:stats.total_completed_jobs_count,
        total_revenue_sum: stats.total_revenue_sum,
        total_planned_count: stats.total_planned_count
      }});
    }
  });
  }
  
  /**
   * @param {object} appointment 
   */
  showSingleAppointment = (appointment) => {
    this.setState({ selectedAppointmentDetail: appointment});
    this.setState({ showAppointment: true})
  };

  viewAppointments = () => {
    this.props.history.push("/admin/appointments")
  };
 

  showConsumers = () => {
    this.props.history.push("/admin/consumers")
  };

  refreshSingleAppointment = () => {
    let payload = {
      uuid : this.state.selectedAppointmentDetail.uuid
    }
    this.props.fetchFeedAction(payload, 1).then((result) => {
      this.setState({selectedAppointmentDetail: result.data[0]});
    });
  };


  render() {
    const { store } = this.props;

    return (

      <div className="content">
        <Row>
          <Col xs="12">

      {(this.state.stats.posted_jobs || this.state.stats.planned_jobs || this.state.stats.completed_jobs) && (
        <JobsStatsOverview 
        stats={this.state.stats}
        year={this.state.year}
        changeStats= {(e)=>this.filterStartsCharts(e)}
        ></JobsStatsOverview>
      )}
      
                   </Col>
        
        
            <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-warning">
                      <i className="tim-icons icon-calendar-60" />
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">
                        {renderTranslation(store, "dashboard.total_appointments_text")}
                      </p>
                      <CardTitle tag="h3">{this.state.total.total_jobs_count}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={this.viewAppointments}>
                <i className="tim-icons icon-tap-02" /> {renderTranslation(store, "dashboard.view_stats_text", { title: 'appointments.appointments'})}
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col lg="3" md="6">
            <Card className="card-stats dashboard-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-primary">
                      <i className="tim-icons icon-book-bookmark" />
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">{renderTranslation(store, "dashboard.total_planned_appointments_text")}</p>
                      <CardTitle tag="h3">{this.state.total.total_planned_count}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={this.viewAppointments}>
                <i className="tim-icons icon-tap-02" /> {renderTranslation(store, "dashboard.view_stats_text", { title: "appointments.appointments" })}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="3">
                    <div className="info-icon text-center icon-success">
                      <i className="tim-icons icon-send" />
                    </div>
                  </Col>
                  <Col xs="9">
                    <div className="numbers">
                      <p className="card-category">{renderTranslation(store, "dashboard.total_completed_appointments_text")}</p>
                      <CardTitle tag="h3">{this.state.total.total_completed_jobs_count}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={this.viewAppointments}>
                  <i className="tim-icons icon-tap-02" /> {renderTranslation(store, "dashboard.view_stats_text", { title: "appointments.appointments" })}
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col lg="3" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="3">
                    <div className="info-icon text-center icon-danger">
                      <i className="tim-icons icon-wallet-43" />
                    </div>
                  </Col>
                  <Col xs="9">
                    <div className="numbers">
                      <p className="card-category">{renderTranslation(store, "dashboard.total_revenue_text")}</p>
                      <CardTitle tag="h3">{this.state.total.total_revenue_sum}€</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={this.viewAppointments}>
                <i className="tim-icons icon-tap-02" /> {renderTranslation(store, "dashboard.view_stats_text", { title: "appointments.appointments" })}
                </div>
              </CardFooter>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col lg="12">
            <LatestAppointments viewAppointments={()=>this.viewAppointments()} appointments={this.state.appointments} showSingleAppointment={(appointment)=>this.showSingleAppointment(appointment)} ></LatestAppointments>
            
            
          </Col>
        </Row>
                {/* Show Single Appointment detail start */}
                <ShowAppointment {...this.props}
             showModal={this.state.showAppointment}
             toggleModal={this.toggleAppointmentDetailModal}
             handleClose={this.handleAppointmentDetailClose}
             appointment={this.state.selectedAppointmentDetail} 
             refreshAppointment = {this.refreshSingleAppointment}

             />
        {/* Show Single Appointment detail end */}
        <div  className={"bg-overlay " + (this.state.showModal?"show": (this.state.showAppointment?"show":"hide") )}></div>

        <Modal isOpen={this.state.parkedJobModal} toggle={this.togglehandleParkedJobModalCloseModal} onHide={this.handleParkedJobModalClose}>
          <ModalHeader toggle={this.togglehandleParkedJobModalCloseModal} >
          </ModalHeader>
          <ModalBody>
              {this.state.parkedJobModalMessage}
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>


      </div>


    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointments: state.feed.feed,
    store: state
  };
};

const mapDispatchToProps = {
  fetchFeedAction: FetchFeedAction,
  fetchStatsAction: FetchStatsAction,
  submitParkedJobAction: SubmitParkedJobAction
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
