import React, { Component } from "react";
import { ResendEmailForTokenAction, VerificationOfEmailAction } from "../../actions"
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from "../../../../i18n";
import { renderTranslation } from "services";
import {
  FormGroup as Group,
  FormControl as Control,
  Validators,
} from "react-reactive-form";

import {
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import classnames from "classnames";

class EmailConfirmation extends Component {
  emailConfirmationForm = null;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      token: '',
      errors: { email: null, token: null },
      errorShow: false,
      isResendEmail: false,
      intervalTime: null,
      emailFocus: false,
      tokenFocus: false,
      resentEmailTime: { minute: 0, second: 0 }
    };
    this.createInput();
  }

  createInput() {
    this.emailConfirmationForm = new Group({
      email: new Control(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]),
      token: new Control(null, [Validators.required]),
    });
  }


  componentDidMount() {
    if (this.props.email && this.props.email !== '') {
      this.emailConfirmationForm.controls["email"].setValue(this.props.email)
    }

    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  /**
   * Trigger on change of inputs
   * @param {object} e 
   */
  handleChange = (e) => {

    return (
      e.target.name === 'email' ? this.emailConfirmationForm.controls["email"].setValue(e.target.value) :
        e.target.name === 'token' ? this.emailConfirmationForm.controls["token"].setValue(e.target.value) :
          () => {
          }
    )
  };

  handleClick = () => {
    if (this.emailConfirmationForm.valid) {
      this.setState({ loading: true });
      this.setState({ errorShow: false });
      let payload = {
        email: this.emailConfirmationForm.controls.email.value,
        token: this.emailConfirmationForm.controls.token.value
      };
      this.props.verificationOfEmailAction(payload).then(result => {
        this.setState({ loading: false });
        if (result.success) {
          this.props.submit(payload);
        }
      })
    } else {
      this.setState({ errorShow: true });
    }
  };

  handleResendEmail = () => {
    let payload = {
      email: this.emailConfirmationForm.controls.email.value,
    };
    this.props.resendEmailForTokenAction(payload).then(result => {
      if (result.success) {

        const intervalTime = setInterval(() => { this.updateTime() }, 1000);
        this.setState({ intervalTime: intervalTime })
      }
    })

  };


  updateTime = () => {
    if (this.state.resentEmailTime.minute === 0 && this.state.resentEmailTime.second === 0) {
      if (this.state.isResendEmail) {
        clearInterval(this.state.intervalTime)
        this.setState({ isResendEmail: false })
      } else {
        const startTime = { minute: 0, second: 59 }
        this.setState({ resentEmailTime: startTime, isResendEmail: true })
      }

    }
    else {
      if (this.state.resentEmailTime.second === 0) {
        const time = { minute: this.state.resentEmailTime.minute - 1, second: 59 }
        this.setState({ resentEmailTime: time })

      } else {
        const time = { minute: this.state.resentEmailTime.minute, second: this.state.resentEmailTime.second - 1 }
        this.setState({ resentEmailTime: time })
      }
    }
  };

  render() {
    const { store } = this.props;

    // if(this.props.email && this.props.email !== '') 
    // this.setState({email: this.props.email})

    return (
      <div className="request-password-reset">
        <Container>
          <Col className="ml-auto mr-auto" lg="6" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>

                  <CardTitle tag="h1">{renderTranslation(store, "auth.email_confirmation_title")}</CardTitle>
                  <CardSubtitle>{renderTranslation(store, "auth.email_confirmation_sub_title")}</CardSubtitle>
                </CardHeader>
                <CardBody>
                  <span className="error">
                    {(this.state.errorShow && this.emailConfirmationForm.controls["email"].errors &&
                      this.emailConfirmationForm.controls['email'].errors.required) ?
                      renderTranslation(store, "errors.required", { fieldName: "forms.email_text" })
                      : ""}

                    {this.state.errorShow && this.emailConfirmationForm.controls["email"].errors &&
                      this.emailConfirmationForm.controls["email"].errors.pattern ?
                      renderTranslation(store, "errors.format", { fieldName: "forms.email_text" })
                      : ""}
                  </span>

                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.emailFocus,
                    })  }
                  >
                    <InputGroupAddon addonType="prepend" className="disabled">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder={renderTranslation(store, "forms.email_placeholder_text")}
                      type="email"
                      value={this.emailConfirmationForm.controls.email.value}
                      name="email"
                      onChange={this.handleChange}
                      disabled={this.props.email && this.props.email !== ''}
                      onFocus={(e) => this.setState({ emailFocus: true })}
                      onBlur={(e) => this.setState({ emailFocus: false })}
                      required
                    />
                  </InputGroup>

                  <span className="error">
                    {(this.state.errorShow && this.emailConfirmationForm.controls["token"].errors &&
                      this.emailConfirmationForm.controls['token'].errors.required) ?
                      renderTranslation(store, "errors.required", { fieldName: "forms.received_code" })
                      : ""}

                  </span>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.tokenFocus,
                    }) + "  " + (this.props.email && this.props.email !== '' ? "disabled" : "")}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      type="number"
                      name="token"
                      placeholder={renderTranslation(store, "forms.receive_token_password")}
                      onChange={this.handleChange} required
                      onFocus={(e) => this.setState({ tokenFocus: true })}
                      onBlur={(e) => this.setState({ tokenFocus: false })}
                      required
                    />
                  </InputGroup>


                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="info"
                    onClick={this.handleClick}
                    size="lg"
                  >
                    {!this.state.loading ? "Submit" : 'Loading...'}
                  </Button>

                  <div className="pull-left">
                    {this.state.isResendEmail ? <div>Please wait <strong>{this.state.resentEmailTime.minute + ' minute and ' + this.state.resentEmailTime.second + " seconds"} </strong>  to resend Email.</div> :
                      this.emailConfirmationForm.controls.email.value && this.emailConfirmationForm.controls.email.value !== '' ?
                        <div>{renderTranslation(store, "auth.did_not_receive_code_text")} <span className="resend-email-text" onClick={this.handleResendEmail} > Resend </span></div> : ''}
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.forgotPassword.email,
    store: state
  }
}
const mapDispatchToProps = {
  resendEmailForTokenAction: ResendEmailForTokenAction,
  verificationOfEmailAction: VerificationOfEmailAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation));
